import { Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectAppUser,
  setDialogOpen,
  setSearchText,
} from "store/slice/appUserSlice";
import SearchBar from "ui-component/SearchBar";
import AppUserTable from "./AppUserTable";
import { StatusFilterDialog } from "./StatusFilterDialog";

const AppUserList = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(selectAppUser);
  const { searchText } = useAppSelector(selectAppUser);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#fff",
            padding: "30px",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item md={8}>
              <Typography variant="h3" gutterBottom>
                App Users
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  onClick={() => dispatch(setDialogOpen())}
                  className="fixed-width"
                >
                  Filter
                </Button>
                <SearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StatusFilterDialog />
          <AppUserTable />
        </Grid>
      </Grid>
    </>
  );
};

export default AppUserList;
