function Icon({ width = "24", height = "24", fill = "#3d4853" }) {
  return (
    <svg
      width="6"
      height="16"
      viewBox="0 0 6 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1C1.89543 1 1 1.89543 1 3C1 5.0652 1.74619 7.9149 2.18415 9.40308C2.28797 9.75588 2.61657 10 3.00125 10C3.38523 10 3.71299 9.75691 3.81698 9.4054C4.25483 7.92542 5 5.08892 5 3C5 1.89543 4.10457 1 3 1ZM0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3C6 5.25038 5.21237 8.21375 4.7759 9.6891C4.54132 10.482 3.81072 11 3.00125 11C2.19005 11 1.45862 10.4798 1.22483 9.6854C0.788776 8.20369 0 5.22661 0 3ZM3 13C2.44772 13 2 13.4477 2 14C2 14.5523 2.44772 15 3 15C3.55228 15 4 14.5523 4 14C4 13.4477 3.55228 13 3 13ZM1 14C1 12.8954 1.89543 12 3 12C4.10457 12 5 12.8954 5 14C5 15.1046 4.10457 16 3 16C1.89543 16 1 15.1046 1 14Z"
        fill="#001F8D"
      />
    </svg>
  );
}

export default Icon;
