export const SmallTitleIcon: any = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49206 6.91012C8.44972 6.67687 8.24557 6.49999 8.00011 6.49999C7.72397 6.49999 7.50011 6.72385 7.50011 6.99999V11.5021L7.50817 11.592C7.55051 11.8253 7.75465 12.0021 8.00011 12.0021C8.27626 12.0021 8.50011 11.7783 8.50011 11.5021V6.99999L8.49206 6.91012ZM8.79883 4.74999C8.79883 4.33578 8.46304 3.99999 8.04883 3.99999C7.63461 3.99999 7.29883 4.33578 7.29883 4.74999C7.29883 5.16421 7.63461 5.49999 8.04883 5.49999C8.46304 5.49999 8.79883 5.16421 8.79883 4.74999ZM16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
        fill="#001F8D"
      />
    </svg>
  );
};
