import client from "ApiClientProvider/helper";
import storage from "redux-persist/lib/storage";
import { AnyAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// reducer import

import adReducer from "./slice/adSlice";
import addressReducer from "./slice/addressSlice";
import adminUserReducer from "./slice/adminUserSlice";
import appUserReducer from "./slice/appUserSlice";
import customizationReducer from "./customizationReducer";
import dashboardStatFilterReducer from "./slice/dashboard.stat.slice";
import notificationReducer from "./slice/notificationSlice";
import permissionReducer from "./slice/permissionSlice";
import reportAdReducer from "./slice/reportsAdsSclice";
import reportReducer from "./slice/reportSlice";
import savedSearchReducer from "./slice/savedSearchSlice";
import similarSavedSearchReducer from "./slice/similarSavedSearchSlice";
import snackbarReducer from "./slice/snackbarSlice";
import toastReducer from "./slice/toastSlice";
import userReducer from "./slice/userSlice";
import subscriptionReducer from "./slice/subscriptionSlice";
import appUserAdReducer from "./slice/appUserAdSlice";
import appUserSavedAdsReducer from "./slice/appUserSavedAdSlice";
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  ad: adReducer,
  address: addressReducer,
  adminUser: adminUserReducer,
  appUser: appUserReducer,
  customization: customizationReducer,
  dashboardStat: dashboardStatFilterReducer,
  notification: notificationReducer,
  permission: permissionReducer,
  report: reportReducer,
  reportAd: reportAdReducer,
  saveSearch: savedSearchReducer,
  similarSaveSearch: similarSavedSearchReducer,
  snackbar: snackbarReducer,
  toast: toastReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  appUserAds: appUserAdReducer,
  appUserSavedAds: appUserSavedAdsReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/logoutUser") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");
    client.resetStore();

    state = {} as RootState;
  }

  return reducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof reducer>;
