import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import AnimateButton from "ui-component/extended/AnimateButton";

import {
  selectSavedSearch,
  setAdTypes,
  setTruckSize,
  setTruckType,
} from "store/slice/savedSearchSlice";

import { useQuery } from "@apollo/client";
import { GET_MANAGE_CATEGORIES } from "schemas/query";
import { useAppDispatch, useAppSelector } from "store/hooks";
interface Props {
  open: boolean;
  onClose: (filter?: "active" | "disabled") => void;
}

type truckType = {
  label: string;
  isDeleted: boolean;
  id: string;
};

export const StatusFilterDialog = (props: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  // const { filter } = useAppSelector(selectSavedSearch);
  const {
    page,
    limit,
    searchText,
    truckType,
    truckSize,
    adType,
    filter,
  } = useAppSelector(selectSavedSearch);

  const [reason, setReason] = useState<string[]>(filter);

  // const [fromDateLocal, setFromDateLocal] = useState<string | null>(null);

  // const [toDateLocal, setToDateLocal] = useState<string | null>(null);

  const [truckTypes, setTruckTypes] = useState([]);
  const [truckSizes, setTruckSizes] = useState([]);

  const { data, loading: contentLoading, refetch } = useQuery(
    GET_MANAGE_CATEGORIES
  );

  useEffect(() => {
    if (data) {
      setTruckTypes(data.getTruckType.value);
      setTruckSizes(data.getTruckSize.value);
    }
  }, [data]);

  const [filterTruckType, setFilterTruckType] = useState<string[]>([]);
  const [filterTruckSize, setFilterTruckSize] = useState<string[]>([]);
  const [adTypes, setAdType] = useState<string[]>([]);

  useEffect(() => {
    setFilterTruckType(truckType);
    setFilterTruckSize(truckSize);
    setAdType(adType);
  }, [truckType, truckSize, adType]);

  let newArray;

  const handleSearch = () => {
    props.onClose(filter);
    dispatch(setTruckSize(filterTruckSize));
    dispatch(setTruckType(filterTruckType));
    dispatch(setAdTypes(adTypes));
  };

  const handleCancel = () => {
    props.onClose();
  };

  // const handleChange = (event: any) => {
  //   if (filter.length) {
  //     if (reason.includes(event.target.value)) {
  //       newArray = reason.filter(function (item: string) {
  //         return item !== event.target.value;
  //       });
  //       setReason(newArray);
  //     } else {
  //       newArray = [...reason, event.target.value];
  //       setReason(newArray);
  //     }
  //   } else if (reason.includes(event.target.value)) {
  //     newArray = reason.filter(function (item: string) {
  //       return item !== event.target.value;
  //     });
  //     setReason(newArray);
  //   } else {
  //     setReason((oldArray) => [...oldArray, event.target.value]);
  //   }
  // };

  // const handleStartDateChange = (event: any) => {
  //   setFromDateLocal(event.target.value);
  // };

  // const handleEndDateChange = (event: any) => {
  //   setToDateLocal(event.target.value);
  // };

  const handleAdType = (event: any) => {
    if (adTypes.includes(event.target.value)) {
      newArray = adTypes.filter(function (item: string) {
        return item !== event.target.value;
      });
      setAdType(newArray);
    } else {
      setAdType((oldArray) => [...oldArray, event.target.value]);
    }
  };

  const handleTruckSize = (event: any) => {
    // if (subscriptionStatus.length) {
    //   if (subscriptionStatusData.includes(event.target.value)) {
    //     newArray = subscriptionStatusData.filter(function (item: string) {
    //       return item !== event.target.value;
    //     });
    //     setSubscriptionStatusData(newArray);
    //   } else {
    //     newArray = [...subscriptionPlanData, event.target.value];
    //     setSubscriptionStatusData(newArray);
    //   }
    // } else

    if (filterTruckSize.includes(event.target.value)) {
      newArray = filterTruckSize.filter(function (item: string) {
        return item !== event.target.value;
      });
      setFilterTruckSize(newArray);
    } else {
      setFilterTruckSize((oldArray) => [...oldArray, event.target.value]);
    }
  };

  const handleTruckType = (event: any) => {
    if (filterTruckType.includes(event.target.value)) {
      newArray = filterTruckType.filter(function (item: string) {
        return item !== event.target.value;
      });
      setFilterTruckType(newArray);
    } else {
      setFilterTruckType((oldArray) => [...oldArray, event.target.value]);
    }
  };

  return (
    <Dialog maxWidth='lg' fullWidth open={props.open} onClose={handleCancel}>
      <DialogTitle>
        <Typography
          variant='h3'
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#111936",
          }}
        >
          Filter
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleCancel}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Typography variant='h4' gutterBottom>
          Ad Type
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup value={filter}>
            <FormControlLabel
              value='freight'
              sx={{ marginBottom: "20px" }}
              control={
                <Checkbox
                  onChange={handleAdType}
                  checked={adTypes.includes("freight")}
                />
              }
              label='Freight'
            />
            <FormControlLabel
              value='carrier'
              control={
                <Checkbox
                  onChange={handleAdType}
                  checked={adTypes.includes("carrier")}
                />
              }
              label='Carrier'
            />
          </RadioGroup>
        </FormControl>

        <Typography variant='h4' gutterBottom sx={{ marginTop: "20px" }}>
          Truck Type
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup value={filter} row={true} sx={{ display: "flex" }}>
            {truckTypes.map((item: truckType) => (
              <>
                {item.isDeleted === false && (
                  <FormControlLabel
                    key={item.id}
                    sx={{ width: "18%", marginBottom: "15px" }}
                    value={item.id}
                    control={
                      <Checkbox
                        onChange={handleTruckType}
                        checked={filterTruckType.includes(item.id)}
                      />
                    }
                    label={item.label}
                  />
                )}
              </>
            ))}
          </RadioGroup>
        </FormControl>

        <Typography variant='h4' gutterBottom sx={{ marginTop: "20px" }}>
          Truck Size
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup value={filter} row={true} sx={{ display: "flex" }}>
            {truckSizes.map((item: truckType) => (
              <>
                {item.isDeleted === false && (
                  <FormControlLabel
                    key={item.id}
                    sx={{ width: "18%", marginBottom: "15px" }}
                    value={item.id}
                    control={
                      <Checkbox
                        onChange={handleTruckSize}
                        checked={filterTruckSize.includes(item.id)}
                      />
                    }
                    label={item.label}
                  />
                )}
              </>
            ))}
          </RadioGroup>
        </FormControl>

        <Divider />
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant='contained' onClick={handleSearch}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
