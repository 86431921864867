// third-party
import { FormattedMessage } from "react-intl";

//project imports
import {
  EMAILS_ROUTE,
  GRACE_AND_EXPIRY_ROUTE,
  MANAGE_CATEGORIES_ROUTE,
  PAGE_MANAGEMENT_ROUTE,
  REPORTS_ROUTE,
  SAVED_SEARCH_ROUTE,
  REPORTS_ADS_ROUTE,
} from "constants/routes";
import EmailTemplateIcon from "ui-component/Icons/EmailTemplateIcon";
import ManagePagesIcon from "ui-component/Icons/ManagePagesIcon";
import ReportsIcon from "ui-component/Icons/ReportsIcon";
import SavedSearchIcon from "ui-component/Icons/SavedSearchIcon";

const others = {
  id: "others",
  type: "group",
  children: [
    {
      id: "saved-searches",
      title: <FormattedMessage id="saved search" />,
      type: "item",
      url: SAVED_SEARCH_ROUTE,
      icon: SavedSearchIcon,
      breadcrumbs: false,
    },
    {
      id: "manage-categories",
      title: <FormattedMessage id="manage categories" />,
      type: "item",
      url: MANAGE_CATEGORIES_ROUTE,
      icon: ManagePagesIcon,
      breadcrumbs: false,
    },
    {
      id: "manage-pages",
      title: <FormattedMessage id="manage pages" />,
      type: "item",
      url: PAGE_MANAGEMENT_ROUTE,
      icon: ManagePagesIcon,
      breadcrumbs: false,
    },
    {
      id: "grace-and-expiry",
      title: <FormattedMessage id="grace and expiry" />,
      type: "item",
      url: GRACE_AND_EXPIRY_ROUTE,
      icon: ManagePagesIcon,
      breadcrumbs: false,
    },
    {
      id: "email-template",
      title: <FormattedMessage id="email templates" />,
      type: "item",
      url: EMAILS_ROUTE,
      icon: EmailTemplateIcon,
      breadcrumbs: false,
    },
    {
      id: "reports-users",
      title: <FormattedMessage id="reports users" />,
      type: "item",
      url: REPORTS_ROUTE,
      icon: ReportsIcon,
      breadcrumbs: false,
    },
    {
      id: "reports-ads",
      title: <FormattedMessage id="reports ads" />,
      type: "item",
      url: REPORTS_ADS_ROUTE,
      icon: ReportsIcon,
      breadcrumbs: false,
    },
  ],
};

export default others;
