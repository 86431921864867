import { useQuery } from "@apollo/client";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { logoutUser, setProfileUrl } from "store/slice/userSlice";

import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DefaultRootStateProps } from "types";
import GearIcon from "ui-component/Icons/GearIcon";
import LogoutIcon from "ui-component/Icons/LogoutIcon";
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

import client from "ApiClientProvider/helper";
import { SOMETHING_WENT_WRONG } from "constants/messages";
import { GET_ADMIN_PROFILE_URL } from "schemas/query";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { removeCookies, removeTokens } from "./helper";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { username, _id, profilePicture } = useAppSelector(
    (state) => state.user
  );

  const firstName = username?.split(" ")[0];

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = React.useRef<any>(null);

  // logout function
  const handleLogout = async () => {
    try {
      if (window.confirm("Are you sure you want to logout?")) {
        removeTokens();
        removeCookies();
        dispatch(logoutUser());
        client.resetStore();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route: string = ""
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { data, loading, error } = useQuery(GET_ADMIN_PROFILE_URL);

  useEffect(() => {
    if (data) {
      dispatch(setProfileUrl(data.getAdminProfileUrl?.profilePicture));
    }
    if (error) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        })
      );
    }
  }, [data, dispatch, error]);

  return (
    <>
      <Button
        variant='outlined'
        size='small'
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        className='profile-btn'
      >
        {loading && <CircularProgress color='inherit' size='small' />}
        {!loading && (
          <Avatar
            src={profilePicture}
            sx={{
              width: 32,
              height: 32,
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />
        )}

        <GearIcon />
      </Button>

      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 0],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  content={false}
                  sx={{
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                    border: "1px solid #29375B !important",
                    marginTop: "5px",
                  }}
                >
                  {/* <PerfectScrollbar
                    style={{
                      height: '100%',
                      maxHeight: 'calc(100vh - 250px)',
                      overflowX: 'hidden',
                    }}
                  > */}
                  <Box sx={{ p: 1 }}>
                    <List
                      component='nav'
                      sx={{
                        width: "100%",
                        maxWidth: 250,
                        minWidth: 200,
                        //backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                        selected={selectedIndex === 0}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                          handleListItemClick(event, 0, "admin/profile")
                        }
                      >
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='body2'>
                              Account Settings
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                        }}
                        selected={selectedIndex === 4}
                        onClick={(event) => {
                          handleLogout();
                          handleClose(event);
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon className='icon-stroke' />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='body2'>Log out</Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
