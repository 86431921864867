// project imports

import { red } from "@mui/material/colors";
import { CustomTypography } from "themes/types";

export default function componentStyleOverrides(theme: CustomTypography) {
  const bgColor =
    theme?.customization?.navType === "dark"
      ? theme.colors?.darkBackground
      : theme.colors?.grey50;
  return {
    MuiGrid: {
      styleOverrides: {
        root: {
          ".inner-content": {
            //zIndex: "9",
            marginTop: "93px",
          },
          ".table-pager": {
            padding: "20px",
            paddingBottom: "30px",
            justifyContent: "space-between",
            background: "#fff",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",

            ".MuiGrid-item": {
              display: "flex",
              "@media (max-width: 600px)": {
                justifyContent: "center!important",
              },
              "&:last-child": {
                justifyContent: "flex-end",
              },
            },
            ".MuiPagination-root": {
              display: "inline-flex",
              "@media (max-width: 600px)": {
                marginBottom: "20px",
              },
            },
            "& .MuiButton-root": {
              background: theme.colors?.grey50,
              color: theme.colors?.grey900,
              border: `1px solid ${theme.colors?.warningLight}`,
              "& .MuiButton-endIcon": {
                marginLeft: "7px",
                marginRight: "-1px",
                "& .MuiSvgIcon-root": {
                  fontSize: "26px!important",
                },
              },
            },
          },
          ".custom-select": {
            padding: "0",
            ".MuiInputAdornment-positionStart": {
              position: "absolute",
              left: "16px",
              zIndex: "1",
            },
            "& .MuiSelect-outlined": {
              padding: "10px 43px 10px 43px!important",
              zIndex: "9",
            },
            ".MuiSvgIcon-root": {
              fill: theme.colors?.darkPrimaryDark,
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: theme.colors?.secondary50,
              borderRadius: "20px",
            },
            "&.Mui-focused,&:hover": {
              "& .MuiSvgIcon-root": {
                fill: theme.colors?.primaryDark,
              },
            },
          },
          ".sticky-sidebar": {
            //minHeight: "100vh",
            position: "sticky",
            overflowY: "auto",
            top: 0,
            paddingTop: "62px",
            "@media (max-width: 1200px)": {
              paddingTop: "69px",
            },
            "@media (max-width: 600px)": {
              paddingTop: "24px",
            },
          },
          ".ellipsis": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "block",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 10,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          //margin: '0!important',
          "& .MuiTextField-root": {
            margin: "0",
          },
          "& .MuiInputLabel-root": {
            color: theme.colors?.greyA200,
            fontWeight: "500!important",
            "&.Mui-focused": {
              color: theme.colors?.grey500,
            },
          },
          "& .MuiFilledInput-root": {
            border: `1px solid #EDE7F6`,
            borderRadius: "12px",
            background: theme?.customization?.outlinedFilled
              ? theme.colors?.grey100
              : "transparent",
            "&.input-border-dark": {
              borderColor: "#EDE7F6",
            },
            "&.input-border-light": {
              borderColor: theme.colors?.warningLight,
            },
            "& .MuiFilledInput-input": {
              color: theme.colors?.greyA300,
              fontWeight: "500",
              borderRadius: "12px",
              //boxShadow: `0 0 0 40px ${theme.colors?.grey100} inset!important`,
            },
            "&:after, &:before": {
              border: "none!important",
            },
            "&:hover, &.Mui-focused": {
              background: theme?.customization?.outlinedFilled
                ? theme.colors?.grey50
                : "transparent",
            },
            "& .MuiSelect-select": {
              padding: "31px 16px 12px",
              fontWeight: 500,
            },
            "&.statusInput": {
              "& .MuiFilledInput-input": {
                fontSize: "10px",
                fontWeight: 500,
                color: "#9e9e9e",
                paddingTop: "19px",
                paddingBottom: "21px",
              },
            },
          },
          "& .MuiOutlinedInput-root": {
            border: "1px solid #EDE7F6 !important",
            "&.MuiInputBase-colorSecondary": {
              backgroundColor: theme?.colors?.grey50,
              "& .MuiOutlinedInput-input": {
                color: theme.colors?.greyA200,
                fontWeight: "700",
                "&::placeholder": {
                  color: theme.colors?.greyA200,
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme?.colors?.grey400,
              },
              "& .MuiSvgIcon-root": {
                fill: theme.colors?.greyA200,
              },
            },
            "& .MuiSelect-select": {
              //background: theme.colors?.grey50,
            },
          },
          "&.input-filled": {
            "& .MuiOutlinedInput-root": {
              background: theme?.customization?.outlinedFilled
                ? theme.colors?.grey50
                : "transparent",
              "& .MuiOutlinedInput-input": {
                boxShadow: `0 0 0 40px ${theme.colors?.grey50} inset!important`,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor:
                  theme?.customization?.navType === "dark"
                    ? theme.colors?.darkTextPrimary! + 28
                    : theme.colors?.paper,
              },
              "& .MuiSelect-select": {
                padding: "31px 16px 12px",
              },
            },
          },
          "& > label": {
            left: 2,
            color: theme.grey600!,
            '&[data-shrink="false"]': {
              top: 7,
            },
            "&#simple-select-label": {
              left: "3px",
              top: "-2px",
              "&.MuiInputLabel-shrink": {
                top: "14px",
              },
            },
            '&[data-shrink="true"]': {
              "&.MuiInputLabel-shrink": {
                top: 8,
              },
            },
            "&.MuiInputLabel-outlined": {
              "&.MuiInputLabel-shrink": {
                top: 24,
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          },
          "& label[for=outline-adornment-add-location]": {
            "& img": {
              display: "inline",
              verticalAlign: "sub",
            },
            "& + .MuiAutocomplete-root .MuiFormControl-root": {
              marginBottom: "0!important",
            },
          },
          "&.datepicker-field": {
            "& .MuiOutlinedInput-input": {
              padding: "33px 16px 12px!important",
            },
            "& .MuiOutlinedInput-notchedOutline legend span": {
              display: "none",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "0!important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          //background: theme.secondaryDark,
          fontFamily: "Outfit, sans-serif",
          borderRadius: "4px",
          boxShadow: "none",
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontWeight: "500",
          borderColor: theme.colors?.primaryMain,
          "& .MuiButton-startIcon": {
            margin: "0",
            //marginRight: "13px !important",
          },
          "&:hover": {
            boxShadow: "none",
          },
          "&.MuiButton-sizeSmall": {
            padding: "5px 18px",
            fontSize: "12px",
          },
          "&.MuiButton-sizeMedium": {
            padding: "7px 3px",
            fontSize: "14px",
            minWidth: "40px",
            //minHeight: "48px",
          },
          "&.MuiButton-sizeLarge": {
            padding: "12px 15px",
            fontSize: "14px",
            fontFamily: "'Outfit', sans-serif",
          },
          "&.MuiButton-containedInfo": {
            background: theme.colors?.secondary200,
          },
          "&.MuiButton-outlinedSecondary": {
            color: theme.colors?.darkPrimaryMain,
            borderColor: theme.colors?.darkPrimaryMain,
            "& svg path": {
              fill: theme.colors?.darkPrimaryMain,
            },
          },
          "&.fixed-width": {
            minWidth: "116px",
            paddingLeft: "9px!important",
            paddingRight: "9px!important",
          },
          "&.profile-btn": {
            padding: "8px",
            borderRadius: "27px",
            borderColor: theme.colors?.primaryLight,
            "& .MuiAvatar-root": {
              marginRight: "18px",
            },
          },
          "&.MuiButton-outlinedPrimary": {
            //borderColor: theme.colors?.primaryMain
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        //background: 'transparent',
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
          backgroundColor: theme.paper!,
          color: theme.secondaryMain,
          "&.MuiAppBar-positionFixed": {
            backgroundColor: "transparent",
            "@media (max-width: 1200px)": {
              backgroundColor: theme.colors?.paper,
            },
          },
          "&.inner-header": {
            zIndex: "9",
            backgroundColor: theme.colors?.paper!,
          },
          "& .tox-tinymce": {
            border: `1px solid ${theme.colors?.primaryDark}`,
            borderRadius: "12px",
          },
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "none!important",
          //marginBottom: "14px!important"
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "0",
          "& .MuiCardHeader-content": {
            background: theme.colors?.paper,
            position: "relative",
            padding: "20px 20px 12px",
            "& .table-filters": {
              alignItems: "start",
              flexDirection: "row",
              justifyContent: "flex-end",
              position: "relative",
              //flex: "1 1 auto",
              //flexWrap: "nowrap",
              "& .MuiFormControl-root": {
                marginBottom: "0!important",
                "&.MuiTextField-root": {
                  marginBottom: "0!important",
                },
              },
              "& .MuiButtonBase-root, & .MuiOutlinedInput-notchedOutline": {
                //border: `1px solid ${theme.colors?.primaryDark}!important`,
                border: "none",
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%230F1C2AFF' stroke-width='1.5' stroke-dasharray='0' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                borderRadius: "12px",
              },
              "& .MuiButton-sizeMedium": {
                paddingLeft: "11px",
                paddingRight: "11px",
                minHeight: "48px",
              },
              "& .MuiButton-sizeLarge": {
                paddingLeft: "26px",
                paddingRight: "26px",
              },
              "& .MuiInputLabel-root": {
                color: theme.colors?.primaryDark,
                "&.Mui-focused": {
                  color: theme.colors?.primaryDark,
                },
              },
            },
            "& .MuiTypography-h3": {
              margin: "3px 0 16px",
            },
            "+ .MuiDivider-root": {
              //display: "none",
            },
          },
        },
        title: {
          fontSize: "1.25rem",
          fontWeight: 700,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "20px 20px 36px 20px!important",
          ".box-administrators": {
            background: theme.colors?.primaryLight,
            borderRadius: "12px",
            padding: "23px 20px 20px",
            marginTop: "4px",
            minHeight: "50vh",
          },
          "& .icon-box": {
            width: "24px",
            marginRight: "2px",
            textAlign: "center",
            paddingTop: "3px",
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px dashed",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0",
          "&[role=listbox],&[role=menu]": {
            "& .MuiMenuItem-root": {
              borderRadius: "0!important",
              padding: "10px 16px",
              margin: "0",
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkPaper,
          paddingTop: "10px",
          paddingBottom: "10px",
          borderRadius: "12px",
          minHeight: "auto",
          "&.active": {
            color: theme.darkPaper,
            backgroundColor: theme.colors?.primaryLight,
            // backgroundColor: theme.secondary50,
            "&:hover": {
              backgroundColor: theme.colors?.primaryLight,
              opacity: "0.8",
              // background: "transparent",
              "& .MuiListItemIcon-root": {
                color: theme.menuSelected,
                svg: {
                  path: {
                    fill: theme.menuSelected,
                  },
                  "&.icon-stroke": {
                    path: {
                      fill: "none",
                      stroke: theme.menuSelected,
                    },
                  },
                },
              },
            },
          },
          "& .MuiListItemIcon-root": {
            color: theme.textDark,
            svg: {
              path: {
                fill: theme.menuSelected,
              },
              "&.icon-stroke": {
                path: {
                  fill: "none",
                  stroke: theme.menuSelected,
                },
              },
            },
          },
          "& .MuiListItemText-root": {
            "& .MuiTypography-root": {
              color: theme.colors?.darkPaper,
              fontSize: "0.875rem",
              lineHeight: "24px",
              fontWeight: 400,
              fontFamily: "'Outfit', sans-serif",
            },
          },
          "&:hover": {
            backgroundColor: theme.colors?.primaryLight,
            color: theme.textDark,
            "& .MuiListItemIcon-root": {
              //color: theme.menuSelected,
              svg: {
                path: {
                  fill: theme.menuSelected,
                },
                "&.icon-stroke": {
                  path: {
                    fill: "none",
                    stroke: theme.menuSelected,
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
          svg: {
            path: {
              fill: theme.colors?.grey700,
            },
            "&.icon-stroke": {
              path: {
                fill: "none",
                stroke: theme.colors?.primaryMain,
              },
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: "0 0 12px",
          borderRadius: "12px",
          background: "transparent",
          minHeight: "auto",
          "&:hover": {
            background: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          //"&::placeholder": {
          //color: theme.textDark,
          //fontSize: "0.875rem",
          //},
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.error-dark-blue": {
            color: theme.colors?.primaryDark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor:
              theme?.customization?.navType === "dark"
                ? theme.colors?.darkTextPrimary! + 28
                : theme.colors?.grey600,
          },

          borderRadius: `${theme?.customization?.borderRadius}px`,

          "&:hover $notchedOutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
          "& .MuiSvgIcon-root": {
            fill: theme.colors?.primaryDark,
          },
          ".MuiIconButton-edgeEnd .MuiSvgIcon-root": {
            fill: theme.colors?.grey600!,
          },
          "&.error-dark-blue.Mui-error .MuiOutlinedInput-notchedOutline ": {
            borderColor: theme.colors?.primaryDark,
          },
          "&.MuiInputBase-colorSecondary .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors?.primaryMain,
          },
        },
        input: {
          fontWeight: 500,
          color: theme.colors?.primaryDark,
          background: theme?.customization?.outlinedFilled
            ? "transparent"
            : theme.paper,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
          "&.MuiInputBase-inputSizeMedium": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
          "&::placeholder": {
            color: theme.colors?.primaryDark,
            opacity: 1,
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color:
              theme?.customization?.navType === "dark"
                ? theme.colors?.darkTextPrimary! + 50
                : theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color:
            theme?.customization?.navType === "dark"
              ? theme?.colors?.primaryMain
              : theme?.colors?.primaryLight,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          clearIndicator: {
            color: "red",
          },
          width: "100%!important",
          "& .MuiAutocomplete-tag": {
            background:
              theme?.customization?.navType === "dark"
                ? theme.colors?.darkTextPrimary! + 20
                : theme.colors?.secondaryLight,
            borderRadius: 4,
            color: theme.textDark,
            ".MuiChip-deleteIcon": {
              color:
                theme?.customization?.navType === "dark"
                  ? theme.colors?.darkTextPrimary! + 80
                  : theme.colors?.secondary200,
            },
          },
          " .MuiOutlinedInput-root": {
            padding: "33px 16px 14px!important",
            border: "1px solid #EDE7F6",
            // boxShadow: "0 0 0 40px #fafafa inset!important",
            "& .MuiAutocomplete-input": {
              padding: 0,
              borderRadius: "0!important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#EDE7F6!important",
              borderWidth: "1px",
            },
          },

          // border: `1px solid ${theme.colors?.primaryLight}`,
          // borderRadius: "12px",
          // background: theme?.customization?.outlinedFilled
          //   ? theme.colors?.grey50
          //   : "transparent",
          // "&.input-border-dark": {
          //   borderColor: "#EDE7F6",
          // },
          // "&.input-border-light": {
          //   borderColor: theme.colors?.warningLight,
          // },
          // "& .MuiFilledInput-input": {
          //   borderRadius: "12px",
          //   boxShadow: `0 0 0 40px ${theme.colors?.grey50} inset!important`,
          // },
          // "&:after, &:before": {
          //   border: "none!important",
          // },
          // "&:hover, &.Mui-focused": {
          //   background: theme?.customization?.outlinedFilled
          //     ? theme.colors?.grey50
          //     : "transparent",
          // },
        },
        popper: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
          boxShadow:
            "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: theme?.customization?.navType === "dark" ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
        select: {
          padding: "20px 17px 8px",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0",
          margin: "0 12px 0 0",
          "& .MuiSvgIcon-root": {
            background: theme.colors?.grey100,
            borderRadius: "5px",
            border: "1px solid #EDE7F6",
            "& path": {
              fill: theme.colors?.grey100,
              background: theme.colors?.grey100,
            },
          },
          // checked not prop
          "&.Mui-checked": {
            "& .MuiSvgIcon-root": {
              padding: "3px",
              "& path": {
                fill: theme.colors?.primaryMain,
                background: theme.colors?.grey100,
                stroke: theme.colors?.primaryMain,
                strokeWidth: 2,
              },
            },
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          //flexDirection: "row!important",
          ".MuiAvatar-root": {
            color: theme.colors?.paper,
            borderColor: theme.colors?.secondaryMain,
            background: theme.colors?.secondary200,
            marginLeft: 0,
            marginRight: "-14px",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          // color:
          //   theme?.customization?.navType === "dark"
          //     ? theme.colors?.darkLevel1
          //     : theme.colors?.paper,
          // background:
          //   theme?.customization?.navType === "dark"
          //     ? theme.darkTextPrimary
          //     : theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          height: "24px",
          padding: "0 5px",
          fontWeight: 500,
          background: theme.paper!,
          textTransform: "Capitalize",
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
          "&.fixed-width": {
            minWidth: "110px",
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.textDark,
          fontSize: "16px",
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            theme?.customization?.navType === "dark"
              ? theme.colors?.darkPaper
              : theme.colors?.primaryLight,
          "& .MuiTabs-flexContainer": {
            borderColor:
              theme?.customization?.navType === "dark"
                ? theme?.colors?.darkTextPrimary! + 20
                : theme.colors?.primary200,
          },
          "& .MuiTab-root": {
            color:
              theme?.customization?.navType === "dark"
                ? theme.colors?.darkTextSecondary
                : theme.colors?.grey900,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.colors?.primaryDark,
          },
          "& .Mui-selected": {
            color: theme.colors?.primaryDark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTab-root": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 12,
            color: theme.colors?.grey800,
            "&.Mui-selected": {
              "& svg path": {
                fill: theme.colors?.primaryMain,
              },
            },
          },
          flexContainer: {
            borderBottom: "1px solid",
            borderColor:
              theme?.customization?.navType === "dark"
                ? theme.colors?.darkTextPrimary! + 20
                : theme.colors?.grey200,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "0",
          // padding: "24px 24px 32px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: "0 20px",
          margin: "-9px 0 0",
          borderRadius: "0",
          background: theme.colors?.paper,
          "& .MuiTableRow-root .MuiTableCell-root:last-child": {
            textAlign: "right",
            " > div": {
              justifyContent: "flex-end",
              padding: "0 13px",
            },
          },
          "&.table-investors,&.table-companies": {
            marginBottom: "72px",
            " .MuiTableCell-head": {
              paddingLeft: "0",
            },
            "& .MuiTableBody-root .MuiTableRow-root": {
              "&:first-of-type": {
                ".MuiTableCell-root": {
                  paddingTop: "16px",
                },
              },
              ".MuiTableCell-root": {
                padding: "8px 16px 4px 0",
                whiteSpace: "nowrap",
                maxWidth: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                height: "60px",
              },
            },
            ".MuiTableCell-root": {
              "&:last-child": {
                width: "120px",
                textAlign: "center",
                paddingLeft: "0",
                paddingRight: "0!important",
                "&>div": {
                  justifyContent: "center",
                },
              },
            },
          },
          "&.table-pages,&.table-emails": {
            marginBottom: "72",
            " .MuiTableCell-head": {
              paddingLeft: "0",
            },
            "& .MuiTableBody-root .MuiTableRow-root": {
              "&:first-of-type": {
                ".MuiTableCell-root": {
                  paddingTop: "20px",
                },
              },
              ".MuiTableCell-root": {
                padding: "14px 16px 14px 0",
                whiteSpace: "nowrap",
                maxWidth: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              },
            },
            ".MuiTableCell-root": {
              "&:last-child": {
                width: "120px",
                textAlign: "center",
                paddingLeft: "0",
                paddingRight: "0!important",
                "&>div": {
                  justifyContent: "center",
                },
              },
            },
          },
          "& .ads-table td:nth-last-of-type(2), .ads-table th:nth-last-of-type(2)": {
            textAlign: "center",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "31px 16px 28px 20px",
          borderColor:
            theme?.customization?.navType === "dark"
              ? theme.colors?.darkTextPrimary! + 15
              : theme.colors?.grey200,
          fontSize: "0.875rem",
          color: theme.colors?.grey900,
          fontWeight: 500,
          minHeight: "70px",
          "&.MuiTableCell-head": {
            height: "38px",
            padding: "0 33px 0 20px",
            fontSize: "0.75rem",
            color: theme.colors?.grey500,
            fontWeight: 500,
            borderColor: theme.colors?.warningLight,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          background: theme.colors?.grey50,
          padding: "13px 9px",

          //height: '48px',
          "& .MuiButtonBase-root": {
            height: "22px",
            minWidth: "22px",
            padding: "2px 0",
            margin: "0 6px",
            fontSize: "12px",
            color: "#2B2929",
            "&.Mui-selected": {
              //background: "#ff0000",
              color: "#fff",
              borderRadius: "4px",
              height: "31px",
              width: "45px",
              "&:hover": {
                background: "#2B2929",
                opacity: "0.8",
              },
            },
            "&.MuiPaginationItem-previousNext": {
              borderRadius: "50%",
              width: "24px",
              height: "24px",
              background: theme.colors?.paper,
              "&:hover": {
                background: theme.colors?.grey100,
              },
              "& svg": {
                fontSize: "16px",
              },
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color:
            theme?.customization?.navType === "dark"
              ? theme.colors?.darkLevel1
              : theme.paper,
          background:
            theme?.customization?.navType === "dark"
              ? theme.colors?.grey50
              : theme.colors?.grey700,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // padding: "0",
          "& .MuiDivider-root": {
            margin: "24px 0",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
          // padding: "0",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          fontWeight: 500,
          margin: ".625rem 0 0",
          "&.text-dark-blue": {
            color: theme.colors?.primaryDark,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: "0",
          // padding: "0",
          "& .MuiButton-root": {
            borderRadius: "4px",
            minWidth: "65px",

            // minHeight: 'auto',
            // padding: '10px 20px',
            // fontSize: '14px'
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge": {
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            border: `4px solid ${theme.colors?.paper}`,
            padding: 0,
            top: "11%",
            right: "11%",
            "&.MuiBadge-colorSuccess": {
              background: `${theme.colors?.successLight}`,
            },
          },
        },
      },
    },
  };
}
