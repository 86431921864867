import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CardActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { ClearIcon } from "ui-component/Icons/ClearIcon";
import { EditIcon } from "ui-component/Icons/EditIcon";
import AnimateButton from "ui-component/extended/AnimateButton";

//project imports
import { useEffect, useRef, useState } from "react";
import { EDIT_MANAGE_CATEGORIES } from "schemas/mutation";
import { GET_MANAGE_CATEGORIES } from "schemas/query";
import { StyledError } from "styles/globalStyles";
import Loader from "ui-component/Loader";
import MainCard from "ui-component/cards/MainCard";
import useEditorPermission from "hooks/useEditorPermission";

type truckType = {
  label: string;
  isDeleted: boolean;
  id: string;
};

const TruckSchema = Yup.object().shape({
  id: Yup.string().required(),
  isDeleted: Yup.boolean().required(),
  label: Yup.string().required("label is required"),
});

const validationSchema = Yup.object().shape({
  minDistance: Yup.number()
    .typeError("Min Distance must be a number")
    .min(0, "Must be greater than zero")
    .required("This field is required"),
  maxDistance: Yup.number()
    .typeError("Max Distance must be a number")
    .moreThan(Yup.ref("minDistance"), "Must be greater than min distance")
    .required("This field is required"),
  truckType: Yup.array(TruckSchema).min(1, "Must have at least 1 item"),

  truckSize: Yup.array(TruckSchema).min(1, "Must have at least 1 item"),
});

const EditCategories = () => {
  const [truckTypes, setTruckTypes] = useState<truckType[]>([]);
  const [truckSizes, setTruckSizes] = useState<truckType[]>([]);
  const [minDistance, setMinDistance] = useState({});
  const [maxDistance, setMaxDistance] = useState({});
  const [editTruckTypeId, setEditTruckTypeId] = useState<Number | null>(null);
  const [editTruckSizeId, setEditTruckSizeId] = useState<Number | null>(null);

  const dispatch = useAppDispatch();
  const [content, setContent] = useState("");
  const theme = useTheme();

  const editorRef = useRef<any>(null);
  const log = () => {
    if (editorRef.current) {
      setContent(editorRef.current?.getContent());
    }
  };

  const isEditor = useEditorPermission();

  const { loading: contentLoading, data, refetch } = useQuery(
    GET_MANAGE_CATEGORIES
  );
  useEffect(() => {
    if (data) {
      setTruckTypes(data.getTruckType.value);
      setTruckSizes(data.getTruckSize.value);
      setMinDistance(data.getMinMaxDistance.minDistance);
      setMaxDistance(data.getMinMaxDistance.maxDistance);
    }
  }, [data]);

  const [EditCategories, { loading: editLoading }] = useMutation(
    EDIT_MANAGE_CATEGORIES
  );

  const handleEditCategories = async (values: any) => {
    const truckSizeData = values.truckSize.map(
      ({ id, isDeleted, label }: any) => ({
        id,
        isDeleted,
        label,
      })
    );
    const truckTypeData = values.truckType.map(
      ({ id, isDeleted, label }: any) => ({
        id,
        isDeleted,
        label,
      })
    );

    try {
      const { data: updatedData } = await EditCategories({
        variables: {
          body: {
            value: truckSizeData,
          },
          typeBody: {
            value: truckTypeData,
          },
          updateMinMaxDistanceBody2: {
            minDistance: parseFloat(values?.minDistance),
            maxDistance: parseFloat(values?.maxDistance),
          },
        },
      });
      refetch();
      updatedData &&
        dispatch(
          snackbarOpen({
            open: true,
            severity: "success",
            message: " Categories updated successfully!",
          })
        );
    } catch (error: any) {
      dispatch(
        snackbarOpen({ open: true, severity: "error", message: error.message })
      );
    }
  };

  if (contentLoading || editLoading) {
    return <Loader />;
  }

  return (
    <>
      <MainCard content={false}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            truckType: truckTypes,
            truckSize: truckSizes,
            minDistance: minDistance,
            maxDistance: maxDistance,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await handleEditCategories(values);
          }}
        >
          {({ errors, touched, values, getFieldProps }) => (
            <Form style={{ padding: "35px" }}>
              <Typography
                variant='h4'
                sx={{
                  fontSize: "16px",
                  lineHeight: "20.16px",
                  color: "#2A2A2A",
                }}
              >
                Truck/Trailer Type
              </Typography>
              <Box
                sx={{
                  border: 1,
                  padding: "10px 10px",
                  borderRadius: "7px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <FieldArray
                  name='truckType'
                  render={(arrayHelpers) => (
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(330px, 1fr))",
                        "@media (max-width: 400px)": {
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(130px, 1fr))",
                        },
                      }}
                    >
                      {values.truckType.map(
                        (truckTypeItem: any, index: number) => (
                          <>
                            {truckTypeItem.isDeleted === false && (
                              <Box key={index}>
                                <Stack
                                  direction='row'
                                  sx={{
                                    marginBottom: "3px",
                                  }}
                                >
                                  <Stack direction='column'>
                                    <Stack
                                      direction={{ xs: "column", sm: "row" }}
                                    >
                                      <TextField
                                        className='category'
                                        {...getFieldProps(
                                          `truckType.${index}.label`
                                        )}
                                        disabled={
                                          index === editTruckTypeId
                                            ? false
                                            : true
                                        }
                                      />

                                      {isEditor && (
                                        <Stack direction='row'>
                                          <IconButton
                                            onClick={() => {
                                              setEditTruckTypeId(index);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                          <IconButton
                                            onClick={() => {
                                              if (truckTypes[index]) {
                                                arrayHelpers.replace(index, {
                                                  ...getFieldProps(
                                                    `truckType.${index}`
                                                  ).value,
                                                  isDeleted: true,
                                                });
                                              } else {
                                                arrayHelpers.remove(index);
                                              }
                                            }}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </Stack>
                                      )}
                                    </Stack>
                                    <Box
                                      sx={{
                                        padding: "3px 0px",
                                      }}
                                    >
                                      <StyledError
                                        msgColor={theme.palette.error.dark}
                                      >
                                        <ErrorMessage
                                          name={`truckType[${index}].label`}
                                        />
                                      </StyledError>
                                    </Box>
                                  </Stack>
                                </Stack>
                              </Box>
                            )}
                          </>
                        )
                      )}
                      <Box>
                        {isEditor && (
                          <Button
                            variant='contained'
                            type='button'
                            color='primary'
                            onClick={() => {
                              arrayHelpers.push({
                                id: `${
                                  Number(
                                    values.truckType[
                                      values.truckType.length - 1
                                    ].id
                                  ) + 1
                                }`,
                                isDeleted: false,
                                label: "",
                              });
                              setEditTruckTypeId(
                                Number(
                                  values.truckType[values.truckType.length - 1]
                                    .id
                                )
                              );
                            }}
                            sx={{
                              borderRadius: "32px",
                              maxWidth: "240px",
                              width: "100%",
                            }}
                          >
                            Add New Truck Type +
                          </Button>
                        )}

                        <Box>
                          {errors?.truckType == "Must have at least 1 item" && (
                            <StyledError msgColor={theme.palette.error.dark}>
                              {errors.truckType}
                            </StyledError>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                />
              </Box>

              <Typography
                variant='h4'
                sx={{
                  fontSize: "16px",
                  lineHeight: "20.16px",
                  color: "#2A2A2A",
                }}
              >
                Truck/Trailer Size
              </Typography>
              <Box
                sx={{
                  border: 1,
                  padding: "10px 10px",
                  borderRadius: "7px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <FieldArray
                  name='truckSize'
                  render={(arrayHelpers) => (
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(330px, 1fr))",
                        "@media (max-width: 400px)": {
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(130px, 1fr))",
                        },
                      }}
                    >
                      {values.truckSize.map(
                        (truckSizeItem: any, index: number) => (
                          <>
                            {truckSizeItem.isDeleted === false && (
                              <Box key={index}>
                                <Stack
                                  direction='row'
                                  sx={{
                                    marginBottom: "3px",
                                  }}
                                >
                                  <Stack direction='column'>
                                    <Stack
                                      direction={{ xs: "column", sm: "row" }}
                                    >
                                      <TextField
                                        className='category'
                                        {...getFieldProps(
                                          `truckSize.${index}.label`
                                        )}
                                        disabled={
                                          index === editTruckSizeId
                                            ? false
                                            : true
                                        }
                                      />

                                      {isEditor && (
                                        <Stack direction='row'>
                                          <IconButton
                                            onClick={() => {
                                              setEditTruckSizeId(index);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                          <IconButton
                                            onClick={() => {
                                              if (truckSizes[index]) {
                                                arrayHelpers.replace(index, {
                                                  ...getFieldProps(
                                                    `truckSize.${index}`
                                                  ).value,
                                                  isDeleted: true,
                                                });
                                              } else {
                                                arrayHelpers.remove(index);
                                              }
                                            }}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </Stack>
                                      )}
                                    </Stack>
                                    <Box
                                      sx={{
                                        padding: "3px 0px",
                                      }}
                                    >
                                      <StyledError
                                        msgColor={theme.palette.error.dark}
                                      >
                                        <ErrorMessage
                                          name={`truckSize[${index}].label`}
                                        />
                                      </StyledError>
                                    </Box>
                                  </Stack>
                                </Stack>
                              </Box>
                            )}
                          </>
                        )
                      )}
                      <Box>
                        {isEditor && (
                          <Button
                            variant='contained'
                            type='button'
                            color='primary'
                            onClick={() => {
                              arrayHelpers.push({
                                id: `${
                                  Number(
                                    values.truckSize[
                                      values.truckSize.length - 1
                                    ].id
                                  ) + 1
                                }`,
                                isDeleted: false,
                                label: "",
                              });
                              setEditTruckSizeId(
                                Number(
                                  values.truckSize[values.truckSize.length - 1]
                                    .id
                                )
                              );
                            }}
                            sx={{
                              borderRadius: "32px",
                              maxWidth: "240px",
                              width: "100%",
                            }}
                          >
                            Add New Truck Size +
                          </Button>
                        )}

                        <Box>
                          {errors?.truckSize == "Must have at least 1 item" && (
                            <StyledError msgColor={theme.palette.error.dark}>
                              {errors.truckSize}
                            </StyledError>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                />
              </Box>

              <Typography
                variant='h4'
                sx={{
                  fontSize: "16px",
                  lineHeight: "20.16px",
                  color: "#2A2A2A",
                }}
              >
                Distance scale
              </Typography>
              <Stack
                component='form'
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                noValidate
                autoComplete='off'
              >
                <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                  <TextField
                    id='outlined-minimum-distance'
                    label='Minimum Distance(kilometers)'
                    variant='filled'
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("minDistance")}
                  />
                  {touched.minDistance &&
                  typeof errors.minDistance === "string" ? (
                    <StyledError msgColor={theme.palette.error.dark}>
                      {errors.minDistance}
                    </StyledError>
                  ) : null}
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    ...theme.typography.customInput,
                    marginTop: "8px !important",
                  }}
                >
                  <TextField
                    id='outlined-maximum-distance'
                    label='Maximum Distance(kilometers)'
                    variant='filled'
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("maxDistance")}
                  />
                  {touched.maxDistance &&
                  typeof errors.maxDistance === "string" ? (
                    <StyledError msgColor={theme.palette.error.dark}>
                      {errors.maxDistance}
                    </StyledError>
                  ) : null}
                </FormControl>
              </Stack>
              <Divider sx={{ my: 0 }} />
              <Grid container justifyContent='flex-end'>
                <CardActions>
                  {
                    <Grid container>
                      <Grid item>
                        <Stack
                          spacing={2}
                          direction='row'
                          sx={{
                            "@media (max-width: 380px)": {
                              position: "relative",
                              left: "40px",
                            },
                          }}
                        >
                          <AnimateButton>
                            <Button
                              variant='outlined'
                              sx={{ borderRadius: "4px" }}
                              type='button'
                              size='medium'
                              color='primary'
                              className='fixed-width'
                              // onClick={() => navigate(-1)}
                            >
                              <Typography variant='caption'>Cancel</Typography>
                            </Button>
                          </AnimateButton>
                          <AnimateButton>
                            {isEditor && (
                              <Button
                                variant='contained'
                                sx={{ borderRadius: "4px" }}
                                type='submit'
                                size='medium'
                                color='primary'
                                className='fixed-width'
                                onClick={log}
                              >
                                <Typography variant='caption' color='white'>
                                  Save Changes
                                </Typography>
                              </Button>
                            )}
                          </AnimateButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  }
                </CardActions>
              </Grid>
            </Form>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default EditCategories;
