import { useNavigate } from "react-router-dom";

// project imports
import { GuardProps } from "types";
import { useEffect } from "react";

import { useAppSelector } from "store/hooks";
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "constants/routes";
import CONST from "constants/generalConstants";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, status } = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(LOGIN_ROUTE, { replace: true });
    }
    if (status === CONST.DISABLED) {
      navigate(DASHBOARD_ROUTE, { replace: true });
    }
  }, [isLoggedIn, navigate, status]);

  return children;
};

export default AuthGuard;
