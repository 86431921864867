import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// material-ui
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import { MENU_OPEN, SET_MENU } from "store/actions";
import { DefaultRootStateProps, LinkTarget, NavItemType } from "types";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useAppDispatch } from "store/hooks";
import { ADS_LIST_ROUTE } from "constants/routes";
import { resetAd } from "store/slice/adSlice";
import { resetAppUser } from "store/slice/appUserSlice";
import { resetAdminUser } from "store/slice/adminUserSlice";
import { resetSavedSearchSlice } from "store/slice/savedSearchSlice";
import { resetReportsAdSlice } from "store/slice/reportsAdsSclice";
import { resetReportsSlice } from "store/slice/reportSlice";
import { resetSubscriptionSlice } from "store/slice/subscriptionSlice";
import { resetAppUserSavedAds } from "store/slice/appUserSavedAdSlice";
import { resetAppUserAds } from "store/slice/appUserAdSlice";

export interface NavItemProps {
  item: NavItemType;
  level: number;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const Icon = item?.icon!;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component:
      | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
      | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url!} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    dispatch({ type: MENU_OPEN, id });

    // reset slices into initial state
    if (location.pathname.includes(ADS_LIST_ROUTE)) {
      appDispatch(resetAd());
      return;
    }

    appDispatch(resetAppUser());
    appDispatch(resetAdminUser());
    appDispatch(resetSavedSearchSlice());
    appDispatch(resetReportsAdSlice());
    appDispatch(resetReportsSlice());
    appDispatch(resetSubscriptionSlice());
    appDispatch(resetAppUserSavedAds());
    appDispatch(resetAppUserAds());

    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);

  const activePage = (item: NavItemType) => {
    if (item.url && location.pathname.includes(item.url)) {
      return "active";
    }
  };

  return (
    <MenuItem>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        sx={
          {
            //borderRadius: `${customization.borderRadius}px`,
            //mb: 1,
            //alignItems: "flex-start",
            //backgroundColor: level > 1 ? "transparent !important" : "inherit",
            //py: level > 1 ? 1 : 1.25,
            //pl: `${level * 11}px`,
          }
        }
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={() => itemHandler(item.id!)}
        className={activePage(item)}
      >
        <ListItemIcon sx={{ minWidth: !item?.icon ? 18 : 36 }}>
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={<Typography>{item.title}</Typography>}
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </MenuItem>
  );
};

export default NavItem;
