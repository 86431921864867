import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//ui
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "ui-component/cards/MainCard";

//project imports
import { GET_REPORT_ADS_DETAILS } from "schemas/query";

//redux

import { REPORTS_ADS_ROUTE } from "constants/routes";
import { gridSpacing } from "store/constant";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { SmallTitleIcon } from "ui-component/Icons/SmallTitleIcon";
import DescriptionIcon from "ui-component/Icons/DescriptionIcon";
import ReportsIcon from "ui-component/Icons/ReportsIcon";
import ReportIcon from "ui-component/Icons/ReportIcon";

interface ReportAdsDetail {
  createdAt: string;
  firstName: string;
  lastName: string;
  reason: string;
  reporterLastName: string;
  reporterFirstName: string;
  userId: string;
  description: string;
}

const ReportAdsDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [reportAdsDetails, setReportAdsDetails] = useState<ReportAdsDetail>();

  const { loading: contentLoading, data, refetch } = useQuery(
    GET_REPORT_ADS_DETAILS,
    {
      variables: {
        body: {
          reportAdsId: id,
        },
      },
      onError: (error) => {
        dispatch(
          snackbarOpen({
            open: true,
            severity: "error",
            message: error.message,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      setReportAdsDetails(data.getReportAdsDetails);
    }
  }, [data]);

  const handleBack = () => {
    navigate(`${REPORTS_ADS_ROUTE}`);
  };

  return (
    <>
      <MainCard>
        <Grid container spacing={gridSpacing} justifyContent="space-between">
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ my: "15px" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="baseline"
                spacing={1}
                sx={{alignItems:"center"}}
              >
                <Grid item sx={{width: "2%",display:"flex",justifyContent:"center"}}>
                  <SmallTitleIcon />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Reported by:</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="body2">
                    {reportAdsDetails?.reporterFirstName}
                    {reportAdsDetails?.reporterLastName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ my: "15px" }}>
              <Divider />
            </Grid>
            <Grid item xs={12} sx={{ my: "15px" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="baseline"
                spacing={1}
                sx={{alignItems:"center"}}
              >
                <Grid item sx={{width: "2%",display:"flex",justifyContent:"center"}}>
                  <SmallTitleIcon />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    User being reported:
                  </Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="body2">
                    {reportAdsDetails?.firstName} {reportAdsDetails?.lastName}
                  </Typography>
                </Grid>

                {/* <Grid item xs zeroMinWidth>
                  <Typography
                    variant="button"
                    sx={{
                      border: 1,
                      padding: "5px 25px",
                      borderRadius: 1,
                      cursor: "pointer",
                    }}
                  >
                    Disable this user
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ my: "15px" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="baseline"
                spacing={1}
                sx={{alignItems:"center"}}
              >
                <Grid item sx={{width: "2%",display:"flex",justifyContent:"center"}}>
                  <ReportIcon />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Reason:</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="body2">
                    {reportAdsDetails?.reason}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ my: "15px" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="baseline"
                spacing={1}
                sx={{alignItems:"center"}}
              >
                <Grid item sx={{width: "2%",display:"flex",justifyContent:"center"}}>
                  <DescriptionIcon />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Description:</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="body2">
                    {reportAdsDetails?.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ my: "15px" }}>
              <Divider />
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="fixed-width"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default ReportAdsDetail;
