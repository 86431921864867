// project imports
import { lazy } from "react";

import { CODE_VERIFICATION_ROUTE } from "constants/routes";
import MinimalLayout from "layout/MinimalLayout";
import Loadable from "ui-component/Loadable";
// login option 3 routing

const AuthCodeVerification3 = Loadable(
  lazy(
    () => import("views/pages/authentication/authentication3/CodeVerification")
  )
);
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: CODE_VERIFICATION_ROUTE,
      element: <AuthCodeVerification3 />,
    },
  ],
};

export default AuthenticationRoutes;
