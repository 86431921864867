export const getTruckSizes = (
  allTruckSizes: { id: string; isDeleted: string; label: string }[],
  myTruckSizes: string[]
) => {
  const nonDeletedTruckSizes = allTruckSizes
    .filter((item) => !item.isDeleted)
    .map((item) => item.label);

  return Array.from(new Set([...nonDeletedTruckSizes, ...myTruckSizes]));
};

export const getTruckTypes = (
  allTruckTypes: { id: string; isDeleted: string; label: string }[],
  myTruckTypes: string[]
) => {
  const nonDeletedTruckSizes = allTruckTypes
    .filter((item) => !item.isDeleted)
    .map((item) => item.label);

  return Array.from(new Set([...nonDeletedTruckSizes, ...myTruckTypes]));
};
