import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
  page: number;
  limit: number;
  searchText: string | null;
  truckSize: string[];
  truckType: string[];
  adType: string[];
  fromDate: string | null;
  toDate: string | null;
}

const initialState: InitialStateProps = {
  page: 1,
  limit: 10,
  searchText: null,
  truckSize: [],
  truckType: [],
  adType: [],
  fromDate: null,
  toDate: null,
};

export const savedSearchSlice = createSlice({
  name: "saveSearch",
  initialState,

  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },
    setTruckType: (state, action: PayloadAction<string[] | null>) => {
      state.page = 1;
      if (action.payload?.length) {
        state.truckType = action.payload;
      } else {
        state.truckType = [];
      }
    },
    setTruckSize: (state, action: PayloadAction<string[] | null>) => {
      state.page = 1;
      if (action.payload?.length) {
        state.truckSize = action.payload;
      } else {
        state.truckSize = [];
      }
    },
    setAdTypes: (state, action: PayloadAction<string[] | null>) => {
      state.page = 1;
      if (action.payload?.length) {
        state.adType = action.payload;
      } else {
        state.adType = [];
      }
    },

    resetSavedSearchSlice: () => initialState,
  },
});

export const {
  setLimit,
  setPage,
  setSearchText,
  setTruckSize,
  setTruckType,
  setAdTypes,
  resetSavedSearchSlice,
} = savedSearchSlice.actions;

export const selectSavedSearch = (state: any) => state.saveSearch;

export default savedSearchSlice.reducer;
