import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//ui
import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import MainCard from "ui-component/cards/MainCard";

//project imports
import { GET_USER_SUBSCRIPTION_DETAIL } from "schemas/query";

//redux

import { SUBSCRIPTION_ROUTE } from "constants/routes";
import { gridSpacing } from "store/constant";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";
import Loader from "ui-component/Loader";

const typeHash: any = {
  freight_search_monthly_subscription: "Monthly",
  freight_search_half_yearly_subscription: "Half Yearly",
  freight_search_weekly_subscription: "Weekly",
};

interface Subscription {
  cost: number;
  dateExpired: string;
  datePurchased: string;
  product_id: String;
}

interface ReportAdsDetail {
  _id: string;
  firstName: string;
  lastName: string;
  status: string;
  subscription: Subscription[];
  adCount: number;
}

const SubscriptionDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [
    userSubscriptionDetails,
    setUserSubscriptionDetails,
  ] = useState<ReportAdsDetail>();

  const { loading: contentLoading, data, refetch } = useQuery(
    GET_USER_SUBSCRIPTION_DETAIL,
    {
      variables: {
        body: {
          userSubscriptionId: id,
        },
      },
      onError: (error) => {
        dispatch(
          snackbarOpen({
            open: true,
            severity: "error",
            message: error.message,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      setUserSubscriptionDetails(data.getUserSubscriptionDetails);
    }
  }, [data]);

  const handleBack = () => {
    navigate(`${SUBSCRIPTION_ROUTE}`);
  };

  if (contentLoading) {
    return <Loader />;
  }
  return (
    <>
      <MainCard>
        <Grid container spacing={gridSpacing} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Subscription details
            </Typography>
            <Grid item xs={12} sx={{ my: "15px" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="baseline"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    my: "15px",
                    backgroundColor: "#e5e9f4",
                    borderRadius: "10px",
                  }}
                  style={{ padding: "25px" }}
                >
                  <Typography
                    variant="h3"
                    gutterBottom
                    style={{ marginBottom: "0px" }}
                  >
                    {userSubscriptionDetails?.firstName}{" "}
                    {userSubscriptionDetails?.lastName}
                  </Typography>

                  <Grid style={{ display: "flex" }}>
                    <Grid item>
                      <Typography variant="body2">
                        Current subscription: &nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {
                          typeHash[
                            `${
                              userSubscriptionDetails?.subscription[
                                userSubscriptionDetails.subscription.length - 1
                              ].product_id
                            }`
                          ]
                        }
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid style={{ padding: "8px 15px" }}>
                    <Grid style={{ display: "flex" }}>
                      <Grid item>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#2a2a2a",
                          }}
                        >
                          Date purchased: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {
                            userSubscriptionDetails?.subscription[0]
                              .datePurchased
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid style={{ display: "flex" }}>
                      <Grid item>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#2a2a2a",
                          }}
                        >
                          Last Renewed: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {
                            userSubscriptionDetails?.subscription[
                              userSubscriptionDetails.subscription.length - 1
                            ].datePurchased
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ display: "flex" }}>
                      <Grid item>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#2a2a2a",
                          }}
                        >
                          Account status: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {userSubscriptionDetails?.status}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ display: "flex" }}>
                      <Grid item>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#2a2a2a",
                          }}
                        >
                          Ads listed: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {userSubscriptionDetails?.adCount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ my: "15px" }}>
                    <Divider color="black" sx={{ height: 2, width: "95%" }} />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <Grid item>
                      <Typography variant="h4">
                        $
                        {
                          userSubscriptionDetails?.subscription[
                            userSubscriptionDetails.subscription.length - 1
                          ].cost
                        }
                      </Typography>
                      <Typography variant="body2">
                        per &nbsp;
                        {
                          typeHash[
                            `${
                              userSubscriptionDetails?.subscription[
                                userSubscriptionDetails.subscription.length - 1
                              ].product_id
                            }`
                          ]
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: "rgb(66,87,130)" }}
                      >
                        Last Renewed on &nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: "rgb(66,87,130)" }}
                      >
                        {
                          userSubscriptionDetails?.subscription[
                            userSubscriptionDetails.subscription.length - 1
                          ].datePurchased
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: "rgb(87,99,150)" }}
                      >
                        Valid until &nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: "rgb(87,99,150)" }}
                      >
                        {
                          userSubscriptionDetails?.subscription[
                            userSubscriptionDetails.subscription.length - 1
                          ].dateExpired
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8} sx={{ my: "15px" }}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 400 }}
                      aria-label="simple table"
                      className="customDataTable"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              borderBottom: "none",
                              fontSize: "1rem",
                              color: "black",
                            },
                          }}
                        >
                          <TableCell align="left">
                            <Typography variant="menuCaption" noWrap>
                              Subscription Plan
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="menuCaption" noWrap>
                              Cost
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="menuCaption" noWrap>
                              Date Charged
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userSubscriptionDetails?.subscription.map(
                          (item: Subscription) => (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  align="left"
                                  variant="subMenuCaption"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {typeHash[`${item.product_id}`]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  align="left"
                                  variant="subMenuCaption"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {item.cost}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  align="left"
                                  variant="subMenuCaption"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {item?.datePurchased}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ height: 2, width: "100%", marginBottom: "20px" }} />
            <Grid item>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="fixed-width"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default SubscriptionDetail;
