import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface FilterProps {
  status?: string[] | null;
  subscription?: string[] | null;
}
interface InitialStateProps {
  openDialog: boolean;
  page: number;
  limit: number;
  searchText: string | null;
  name: string | null;
  filter?: FilterProps | null;
}

const initialState: InitialStateProps = {
  openDialog: false,
  page: 1,
  limit: 10,
  searchText: null,
  name: null,
  filter: {
    status: null,
    subscription: null,
  },
};

export const appUserSlice = createSlice({
  name: "appUser",
  initialState,

  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setUserFilter: (state, action: PayloadAction<FilterProps>) => {
      state.page = 1;
      state.filter = action.payload;
    },

    setDialogOpen: (state) => {
      state.openDialog = true;
    },

    setDialogClose: (state) => {
      state.openDialog = false;
    },

    setClearFilter: (state) => {
      state.filter = null;
    },
    resetAppUser: () => initialState,
  },
});

export const {
  setLimit,
  setPage,
  setSearchText,
  setName,
  resetAppUser,
  setUserFilter,
  setDialogClose,
  setDialogOpen,
  setClearFilter,
} = appUserSlice.actions;

export const selectAppUser = (state: any) => state.appUser;

export default appUserSlice.reducer;
