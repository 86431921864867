// ============================================================
// material-ui

import { Avatar, Box, ButtonBase } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { IconMenu2 } from "@tabler/icons";
import LogoSection from "../LogoSection";
import NotificationSection from "./NotificationSection";
import ProfileSection from "./ProfileSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const theme = useTheme();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component='span'
          sx={{ display: { xs: "none", lg: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            display: { xs: "block", lg: "none" },
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <Avatar
            variant='rounded'
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.primary.main,
              color: "#fff",
              "&:hover": {
                background: theme.palette.primary.main,
                opacity: "0.8",
              },
            }}
            onClick={handleLeftDrawerToggle}
            color='inherit'
          >
            <IconMenu2 stroke={2} size='1.5rem' />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <NotificationSection />
      <ProfileSection />
    </>
  );
};

export default Header;
