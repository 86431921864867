import { useCallback } from "react";

import debounce from "lodash.debounce";

type Props = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
) => void;

const useDebounceSearch = (handleSearch: Props) => {
  return useCallback(debounce(handleSearch, 500), []);
};

export default useDebounceSearch;
