import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterProps {
  adType?: string[] | null;
  adStatus?: string[] | null;
  fromDate?: Date | null;
  toDate?: Date | null;
  urgent?: string[] | null;
}
interface InitialStateProps {
  openDialog: boolean;
  page: number;
  limit: number;
  searchText: string | null;
  name: string | null;
  tabIndex: number;
  filter?: FilterProps | null;
}

const initialState: InitialStateProps = {
  openDialog: false,
  page: 1,
  limit: 10,
  searchText: null,
  name: null,
  tabIndex: 0,
  filter: {
    adType: null,
    adStatus: null,
    fromDate: null,
    toDate: null,
    urgent: null,
  },
};

export const appUserSavedAdsSlice = createSlice({
  name: "appUserSavedAds",
  initialState,

  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },

    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload;
    },

    setAdFilter: (state, action: PayloadAction<FilterProps>) => {
      state.page = 1;
      state.filter = action.payload;
    },

    setDialogOpen: (state) => {
      state.openDialog = true;
    },

    setDialogClose: (state) => {
      state.openDialog = false;
    },

    setClearFilter: (state) => {
      state.filter = null;
    },

    resetAppUserSavedAds: () => initialState,
  },
});

export const {
  setLimit,
  setTabIndex,
  setPage,
  setSearchText,
  resetAppUserSavedAds,
  setAdFilter,
  setDialogOpen,
  setClearFilter,
  setDialogClose,
} = appUserSavedAdsSlice.actions;

export const selectAppUserSavedAds = (state: any) => state.appUserSavedAds;

export default appUserSavedAdsSlice.reducer;
