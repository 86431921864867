import { useQuery } from "@apollo/client";
import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { GET_APP_USER_LIST } from "schemas/query";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAppUser, setLimit, setPage } from "store/slice/appUserSlice";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { StyledBarLoader } from "styles/globalStyles";
import NoItemFound from "ui-component/NoItemFound";

import { APP_USERS_ROUTE } from "constants/routes";
import { useNavigate } from "react-router-dom";
import CustomPagination from "ui-component/CustomPagination";
import Chip from "ui-component/extended/Chip";
import AppEyeIcon from "ui-component/Icons/AppEyeIcon";
import { AppUser } from "./app.user.interface";
import AppUserColumnHead from "./AppUserColumnHead";
import CONST from "constants/generalConstants";

const columnHead = [
  "First Name",

  "Last Name",

  "Subscription",

  "Email",

  "Phone",

  "Account Status",

  "Action",
];

const AppUserTable = () => {
  const { page, limit, searchText, filter: userFilter } = useAppSelector(
    selectAppUser
  );
  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useAppDispatch();

  const { data, error, loading } = useQuery(GET_APP_USER_LIST, {
    variables: {
      body: {
        page: page,
        limit,
        searchText: searchText || null,
        filter: userFilter
          ? {
              status: userFilter?.status,
              subscription: userFilter?.subscription,
            }
          : null,
      },
    },
  });

  if (error) {
    dispatch(
      snackbarOpen({ open: true, severity: "error", message: error.message })
    );
  }

  const handleClick = (id: string) => {
    navigate(`${APP_USERS_ROUTE}/${id}`);
  };
  return (
    <>
      <StyledBarLoader>
        {loading && <LinearProgress color='inherit' />}
      </StyledBarLoader>
      {data?.listAppUsers?.meta?.totalItems <= 0 && !loading ? (
        <NoItemFound />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label='simple table'
            className='customDataTable'
          >
            <AppUserColumnHead columnHead={columnHead} />
            <TableBody>
              {data?.listAppUsers?.items.map((item: AppUser) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.firstName || "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.lastName || "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell align='left'>
                    {item?.currentPackage === CONST.YEARLY && (
                      <Typography
                        align='left'
                        variant='subMenuCaption'
                        sx={{ textTransform: "capitalize" }}
                      >
                        Yearly
                      </Typography>
                    )}
                    {item?.currentPackage === CONST.MONTHLY && (
                      <Typography
                        align='left'
                        variant='subMenuCaption'
                        sx={{ textTransform: "capitalize" }}
                      >
                        Monthly
                      </Typography>
                    )}
                    {item?.currentPackage === CONST.WEEKLY && (
                      <Typography
                        align='left'
                        variant='subMenuCaption'
                        sx={{ textTransform: "capitalize" }}
                      >
                        Weekly
                      </Typography>
                    )}
                    {!item?.currentPackage && (
                      <Typography
                        align='left'
                        variant='subMenuCaption'
                        sx={{ textTransform: "capitalize" }}
                      >
                        N/A
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell>
                    <Typography align='left' variant='subMenuCaption'>
                      {item?.email || "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align='left' variant='subMenuCaption'>
                      {item?.phoneNumber || "N/A"}
                    </Typography>
                  </TableCell>

                  <TableCell align='left'>
                    {item?.accountStatus === "active" && (
                      <Chip
                        label={item.accountStatus}
                        size='medium'
                        chipcolor='success'
                        className='fixed-width'
                      />
                    )}
                    {item?.accountStatus === "disabled" && (
                      <Chip
                        label={item.accountStatus}
                        size='medium'
                        chipcolor='grey'
                        className='fixed-width'
                      />
                    )}
                  </TableCell>
                  <TableCell align='left'>
                    <Button
                      variant='outlined'
                      startIcon={<AppEyeIcon />}
                      onClick={() => handleClick(item._id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {data?.listAppUsers?.meta?.totalItems > 0 && (
        <CustomPagination
          totalPages={data?.listAppUsers?.meta?.totalPages}
          setPage={setPage}
          setLimit={setLimit}
          currentPage={data?.listAppUsers?.meta?.currentPage}
          itemCount={data?.listAppUsers?.meta?.itemCount}
        />
      )}
    </>
  );
};

export default AppUserTable;
