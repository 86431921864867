import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterProps {
  adType?: string[] | null;
  adStatus?: string[] | null;
  fromDate?: Date | null;
  toDate?: Date | null;
  urgent?: string[] | null;
}
interface InitialStateProps {
  openDialog: boolean;
  page: number;
  limit: number;
  searchText?: string | null;
  count: number;
  filter?: FilterProps | null;
}

const initialState: InitialStateProps = {
  openDialog: false,
  page: 1,
  limit: 10,
  searchText: null,
  count: 0,
  filter: {
    adType: null,
    adStatus: null,
    fromDate: null,
    toDate: null,
    urgent: null,
  },
};

export const appUserAdSlice = createSlice({
  name: "appUserAds",
  initialState,

  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      // state.filter = null;
      state.searchText = action.payload;
    },

    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },

    setAdFilter: (state, action: PayloadAction<FilterProps>) => {
      state.page = 1;
      state.filter = action.payload;
    },

    setDialogOpen: (state) => {
      state.openDialog = true;
    },

    setDialogClose: (state) => {
      state.openDialog = false;
    },

    setClearFilter: (state) => {
      state.filter = null;
    },

    resetAppUserAds: () => initialState,
  },
});

export const {
  setLimit,
  setPage,
  setSearchText,
  setCount,
  resetAppUserAds,
  setAdFilter,
  setDialogOpen,
  setDialogClose,
  setClearFilter,
} = appUserAdSlice.actions;

export const selectAppUserAd = (state: any) => state.appUserAds;

export default appUserAdSlice.reducer;
