import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import tt from "@tomtom-international/web-sdk-services";
import { useEffect, useState } from "react";

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fullAddress: string;
  label: string;
  fieldName: string;
  setAddressEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
}
const TomTomAutoComplete = ({
  setFieldValue,
  fullAddress = "",
  label,
  fieldName,
  setAddressEmpty,
}: Props) => {
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>(fullAddress);

  useEffect(() => {
    if (!process.env.REACT_APP_TOM_TOM_API_KEYS) {
      return;
    }

    if (searchTerm) {
      if (setAddressEmpty) {
        setAddressEmpty(false);
      }
      const timerId = setTimeout(() => {
        tt.services
          .fuzzySearch({
            query: searchTerm,
            key: process.env.REACT_APP_TOM_TOM_API_KEYS || "",
            limit: 5,
            countrySet: "AUS",
            extendedPostalCodesFor: "XStr",
            minFuzzyLevel: 1,
            maxFuzzyLevel: 2,
            idxSet: "POI",
            view: "Unified",
            relatedPois: "all",
          })
          .then(({ results }: any) => {
            setResults(
              results.filter((value: any, index: number, self: any) => {
                return (
                  index ===
                  self.findIndex(
                    (t: any) =>
                      t.address.freeformAddress ===
                      value.address.freeformAddress
                  )
                );
              })
            );
          });
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [searchTerm, setAddressEmpty]);

  const handleOnChange = (event: any, value: string, reason: any) => {
    const filteredValue: any = results.filter(
      (item: any) => item.address.freeformAddress === value
    );

    if (!filteredValue.length) {
      setFieldValue(fieldName, {
        fullAddress: "",
        state: "",
        town: "",
        zip: "",
        coordinates: [],
      });
      return;
    }

    setFieldValue(fieldName, {
      fullAddress: filteredValue[0]?.address?.freeformAddress || null,
      state: filteredValue[0]?.address?.countrySubdivision || null,
      town: filteredValue[0]?.address?.municipality || null,
      zip: filteredValue[0]?.address?.postalCode || null,
      coordinates: [
        filteredValue[0]?.position?.lat.toString(),
        filteredValue[0]?.position?.lng.toString(),
      ],
    });
  };

  useEffect(() => {
    if (!searchTerm.length) {
      if (setAddressEmpty) {
        setAddressEmpty(true);
      }
    }
  }, [searchTerm, setAddressEmpty]);

  const handleSearchTermChange = (event: any, value: any) => {
    setSearchTerm(value);
  };

  return (
    <div>
      <Autocomplete
        freeSolo
        noOptionsText={"No Match Found"}
        options={results.map((result: any) => result.address.freeformAddress)}
        inputValue={searchTerm}
        value={fullAddress}
        onInputChange={handleSearchTermChange}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin='normal'
            variant='outlined'
            sx={{
              " .MuiInputBase-input": {
                color: "#2A2A2A",
              },
              ".MuiButtonBase-root .MuiSvgIcon-root": {
                fill: "#2A2A2A",
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default TomTomAutoComplete;
