import { DateTime } from "luxon";

import dayjs from "dayjs";
import CONST from "constants/generalConstants";
// 20/10/2010
export const formatDate = (value: string) => {
  if (!value) {
    return;
  }
  return DateTime.fromISO(value)
    .setLocale("fr")
    .toLocaleString(DateTime.DATE_SHORT);
};

export const formatStringDate = (value: string) => {
  return dayjs(value).format("YYYY/MM/DD");
};

export const defaultDateFormatter = (value?: string) => {
  return value && dayjs(value).format(CONST.defaultDateFormat);
};
