// third-party
import { FormattedMessage } from "react-intl";

// ui
import DashboardIcon from "ui-component/Icons/DashboardIcon";

//project imports
import {
  ADS_LIST_ROUTE,
    /*   USER_MANAGEMENT_ROUTE,
     */ DASHBOARD_ROUTE
} from "constants/routes";
import AdsListIcon from "ui-component/Icons/AdsLIstIcon";

const dashboard = {
  id: "dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: DASHBOARD_ROUTE,
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "ads-list",
      title: <FormattedMessage id="ads listed" />,
      type: "item",
      url: ADS_LIST_ROUTE,
      icon: AdsListIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
