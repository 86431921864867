import { NavItemType } from "types";
import dashboard from "./dashboard";
import others from "./others";
import subscriptionManagement from "./subscriptionManagement";
import userManagement from "./userManagement";

// ==============================|| MENU-ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, userManagement, subscriptionManagement, others],
};

export default menuItems;
