import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  CREATE_PASSWORD_ROUTE,
} from "constants/routes";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);

const AuthForgotPassword = Loadable(
  lazy(
    () => import("views/pages/authentication/authentication3/ForgotPassword3")
  )
);

const ResetPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/ResetPassword"))
);
const CreatePassword = Loadable(
  lazy(
    () => import("views/pages/authentication/authentication3/CreatePassword")
  )
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: LOGIN_ROUTE,
      element: <AuthLogin />,
    },
    {
      path: FORGOT_PASSWORD_ROUTE,
      element: <AuthForgotPassword />,
    },

    {
      path: RESET_PASSWORD_ROUTE,
      element: <ResetPassword />,
    },
    {
      path: CREATE_PASSWORD_ROUTE,
      element: <CreatePassword />,
    },
  ],
};

export default LoginRoutes;
