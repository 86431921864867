// project-import
import useDebounceSearch from "hooks/useDebounceSearch";

// redux
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "store/hooks";

// ui
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import useCurrentRoute from "hooks/useCurrentRoute";
import { ADS_LIST_ROUTE } from "constants/routes";

interface Props {
  setSearchText: ActionCreatorWithPayload<string, string>;
  searchText?: string;
}
const SearchBar = ({ setSearchText, searchText }: Props) => {
  const dispatch = useAppDispatch();

  // current route
  const currentRoute = useCurrentRoute();

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    const newString = event?.target.value;

    dispatch(setSearchText(newString || ""));
  };

  const debounceSearchHandler = useDebounceSearch(handleSearch);

  return (
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon sx={{ fontSize: "16px" }} />
          </InputAdornment>
        ),
      }}
      onChange={debounceSearchHandler}
      placeholder={
        currentRoute === ADS_LIST_ROUTE ? "Search by Ad ID" : "Search"
      }
      defaultValue={searchText || ""}
      size='small'
      color='secondary'
    />
  );
};

export default SearchBar;
