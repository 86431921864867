import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface Props {
  titleList: string[];
}
const CustomBreadcrumb = ({ titleList }: Props) => {
  const breadcrumbs = titleList.map((item, index) => {
    return (
      <Typography
        key={index}
        sx={{
          color: "rgb(15, 28, 42)",
          fontWeight: 500,
          fontSize: "0.75rem",
          textTransform: "capitalize"
        }}
      >
        {item}
      </Typography>
    );
  });

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: "white",
        marginBottom: "1rem",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "12px 12px 12px 16px",
      }}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomBreadcrumb;
