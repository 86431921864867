import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
  totalUnreadNotifications: number;
  open: boolean;
}

const initialState: InitialStateProps = {
  totalUnreadNotifications: 0,
  open: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,

  reducers: {
    setUnreadNotificationCount: (state, action: PayloadAction<number>) => {
      state.totalUnreadNotifications = action.payload;
    },

    decreaseNotificationCount: (state) => {
      state.totalUnreadNotifications =
        state.totalUnreadNotifications - 1 < 0
          ? 0
          : state.totalUnreadNotifications - 1;
    },

    toggleNotificationWindow: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },

    resetNotification: () => initialState,
  },
});

export const {
  setUnreadNotificationCount,
  resetNotification,
  toggleNotificationWindow,
  decreaseNotificationCount,
} = notificationSlice.actions;

export const selectNotification = (state: any) => state.notification;

export default notificationSlice.reducer;
