import React from "react";

function ManagePagesIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7499 0C14.9407 0 15.9155 0.925162 15.9947 2.09595L15.9999 2.25V17.7481C15.9999 18.9389 15.0747 19.9137 13.9039 19.9929L13.7499 19.9981H2.25C1.05914 19.9981 0.0843551 19.0729 0.00519085 17.9021L0 17.7481V2.25C0 1.05914 0.925161 0.0843551 2.09595 0.00519085L2.25 0H13.7499ZM13.7499 1.5H2.25C1.8703 1.5 1.55651 1.78215 1.50685 2.14823L1.5 2.25V17.7481C1.5 18.1278 1.78215 18.4416 2.14823 18.4912L2.25 18.4981H13.7499C14.1296 18.4981 14.4434 18.2159 14.493 17.8498L14.4999 17.7481V2.25C14.4999 1.8703 14.2177 1.55651 13.8516 1.50685L13.7499 1.5ZM2.99994 13.749C2.99994 13.3348 3.33572 12.999 3.74994 12.999H12.2499C12.6642 12.999 12.9999 13.3348 12.9999 13.749C12.9999 14.1632 12.6642 14.499 12.2499 14.499H3.74994C3.33572 14.499 2.99994 14.1632 2.99994 13.749ZM2.99994 5.74903C2.99994 5.33482 3.33572 4.99903 3.74994 4.99903H12.2499C12.6642 4.99903 12.9999 5.33482 12.9999 5.74903C12.9999 6.16325 12.6642 6.49903 12.2499 6.49903H3.74994C3.33572 6.49903 2.99994 6.16325 2.99994 5.74903ZM2.99994 9.74903C2.99994 9.33482 3.33572 8.99903 3.74994 8.99903H12.2499C12.6642 8.99903 12.9999 9.33482 12.9999 9.74903C12.9999 10.1632 12.6642 10.499 12.2499 10.499H3.74994C3.33572 10.499 2.99994 10.1632 2.99994 9.74903Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default ManagePagesIcon;
