import CONST from "constants/generalConstants";
import MSG from "constants/validation.message";
import * as Yup from "yup";

export const editAdValidationSchema = Yup.object({
  adId: Yup.string().required(),
  postedBy: Yup.string().required(),
  type: Yup.string().oneOf([CONST.FREIGHT, CONST.CARRIER]).required(),
  datePosted: Yup.string().required(),
  isUrgent: Yup.boolean().required(),
  pickUpDetails: Yup.object({
    fullAddress: Yup.string().required("Pick-Up location is required."),
    state: Yup.string().nullable(),
    town: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    coordinates: Yup.array().of(Yup.string()).nullable(),
  }),
  dropOffDetails: Yup.object({
    fullAddress: Yup.string().required("Drop-Off location is required."),
    state: Yup.string().nullable(),
    town: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    coordinates: Yup.array().of(Yup.string()).nullable(),
  }),
  dateAvailableFrom: Yup.date().required(),
  dateAvailableTo: Yup.date().required(),
  truckType: Yup.array().of(Yup.string()).min(1, MSG.SELECT_ANY_ONE_OPTION),
  truckSize: Yup.array().of(Yup.string()).min(1, MSG.SELECT_ANY_ONE_OPTION),
  description: Yup.string()
    .required("Description is required.")
    .test(
      "len",
      "Must be less than 1055 characters",
      (value: any) => value.length && value.toString().length <= 1055
    ),
  files: Yup.array()
    .max(4, "You can only select up to 4 items.")
    .of(
      Yup.object({
        file: Yup.mixed()
          .nullable()
          .test(
            "FILE_SIZE",
            MSG.FILE_TOO_LARGE,
            (value) => !value || (value && value.size <= CONST.MAX_FILE_SIZE)
          )
          .test(
            "FILE_FORMAT",
            "Only jpg, jpeg or png  format is supported.",
            (value) =>
              !value ||
              (value && CONST.SUPPORTED_PROFILE_FORMATS.includes(value?.type))
          ),
        url: Yup.string().nullable().notRequired(),
        key: Yup.string().nullable().notRequired(),
      })
    ),
});
