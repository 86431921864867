import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

// ui
import ButtonWithIcon from "ui-component/extended/ButtonWithIcon";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "ui-component/Loader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "ui-component/CustomPagination";
import { Typography } from "@mui/material";
import { StyledBarLoader } from "styles/globalStyles";
import { LinearProgress, IconButton } from "@mui/material";
import AppEyeIcon from "ui-component/Icons/AppEyeIcon";

// project imports
import {
  EMAILS_ROUTE,
  REPORTS_ROUTE,
  SUBSCRIPTION_ROUTE,
} from "constants/routes";
import { GET_REPORT, GET_USER_SUBSCRIPTION } from "schemas/query";
import { formatDate } from "utils/date-format";

// redux
import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { DefaultEditIcon } from "ui-component/Icons/DefaultEditIcon";

import {
  selectsubscription,
  setLimit,
  setPage,
} from "store/slice/subscriptionSlice";

import NoItemFound from "ui-component/NoItemFound";

interface Subscription {
  cost: number;
  dateExpired: string;
  datePurchased: string;
  product_id: String;
}

interface UserSubscription {
  _id: string;
  firstName: string;
  lastName: string;
  status: string;
  subscription: Subscription[];
}

const typeHash: any = {
  freight_search_monthly_subscription: "Monthly",
  freight_search_half_yearly_subscription: "Half Yearly",
  freight_search_weekly_subscription: "Weekly",
};

const SubscriptionTable = () => {
  const {
    page,
    limit,
    searchText,
    subscriptionStatus,
    subscriptionPlan,
    fromDate,
    toDate,
  } = useAppSelector(selectsubscription);
  const [userSubscription, setUserSubscription] = useState<
    UserSubscription[]
  >();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, error, loading } = useQuery(GET_USER_SUBSCRIPTION, {
    variables: {
      body: {
        page: page,
        limit: limit,
        subscriptionStatus: subscriptionStatus.length
          ? subscriptionStatus
          : null,
        subscriptionPlan: subscriptionPlan.length ? subscriptionPlan : null,
        searchText: searchText || null,
        fromDate: fromDate,
        toDate: toDate,
      },
    },
  });

  if (error) {
    dispatch(
      snackbarOpen({ open: true, severity: "error", message: error.message })
    );
  }

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setUserSubscription(data.getUserSubscriptionList.items);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  const handleClick = (id: string) => {
    // console.log("id", id);
    navigate(`${SUBSCRIPTION_ROUTE}/${id}`);
  };

  return (
    <>
      <StyledBarLoader>
        {loading && <LinearProgress color="inherit" />}
      </StyledBarLoader>
      {data?.getUserSubscriptionList?.meta?.totalItems <= 0 && !loading ? (
        <NoItemFound />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="customDataTable"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    First Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Last Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Subscription Plan
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Date Purchased
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Plan Renewed on
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Subscription Status
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getUserSubscriptionList?.items.map(
                (item: UserSubscription) => (
                  <TableRow
                    key={item._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography
                        align="left"
                        variant="subMenuCaption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.firstName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        align="left"
                        variant="subMenuCaption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        align="left"
                        variant="subMenuCaption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {
                          typeHash[
                            `${
                              item?.subscription[item.subscription.length - 1]
                                .product_id
                            }`
                          ]
                        }
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        align="left"
                        variant="subMenuCaption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {
                          item?.subscription[
                            item.subscription.length - 1
                          ].datePurchased.split("T")[0]
                        }
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        align="left"
                        variant="subMenuCaption"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {
                          item?.subscription[
                            item.subscription.length - 1
                          ].datePurchased.split("T")[0]
                        }
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        align="left"
                        variant="subMenuCaption"
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor:
                            item.status === "active"
                              ? "rgb(184,244,202)"
                              : "rgb(250,234,232)",
                          padding: "4px 25px",
                          borderRadius: "15px",
                          color:
                            item.status === "active"
                              ? "rgb(112,205,129)"
                              : "rgb(206,141,122)",
                        }}
                      >
                        {item?.status}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <IconButton onClick={() => handleClick(item._id)}>
                        <AppEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {data?.getUserSubscriptionList?.meta?.totalItems > 0 && (
        <CustomPagination
          totalPages={data?.getUserSubscriptionList?.meta?.totalPages}
          setPage={setPage}
          setLimit={setLimit}
          currentPage={data?.getUserSubscriptionList?.meta?.currentPage}
          itemCount={data?.getUserSubscriptionList?.meta?.itemCount}
        />
      )}
    </>
  );
};

export default SubscriptionTable;
