export const ClearIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_4981_53384)">
        <path
          d="M31.6668 10.683L29.3168 8.33301L20.0002 17.6497L10.6835 8.33301L8.3335 10.683L17.6502 19.9997L8.3335 29.3163L10.6835 31.6663L20.0002 22.3497L29.3168 31.6663L31.6668 29.3163L22.3502 19.9997L31.6668 10.683Z"
          fill="#001F8D"
        />
      </g>
      <rect x="0.5" y="0.5" width="39" height="39" rx="1.5" stroke="#001F8D" />
      <defs>
        <clipPath id="clip0_4981_53384">
          <rect width="40" height="40" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
