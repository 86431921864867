export const DELETE_USER_POP_OVER =
  "Are you sure you want to delete this user? The process is irreversible.";

export const DELETE_POP_OVER_TITLE = "Delete Admin";
export const SOMETHING_WENT_WRONG = "Something went wrong!";
export const DELETE_INVESTOR_POP_OVER = "Delete Investor";
export const DELETE_INVESTOR_USER_POP_OVER =
  "Are you sure you want to delete this investor? The process is irreversible.";
export const DELETE_COMPANY_POP_OVER_TITLE = "Delete Company";
export const DELETE_COMPANY_POP_OVER =
  "Are you sure you want to delete this company? The process is irreversible.";
