import { useNavigate } from "react-router-dom";

// project imports
import { useEffect } from "react";
import { GuardProps } from "types";

import { LOGIN_ROUTE } from "constants/routes";
import { useAppSelector } from "store/hooks";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const DashboardGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(LOGIN_ROUTE, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default DashboardGuard;
