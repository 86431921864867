/* eslint no-underscore-dangle: 0 */

import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Button as MuiButton,
  Grid as MuiGrid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Form, Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";

import Spinner from "components/spinner";
import FailureLoad from "components/spinner/fail";
import MainCard from "ui-component/cards/MainCard";
/* assets */
import { useMutation, useQuery } from "@apollo/client";
import { ADMIN_USERS_ROUTE } from "constants/routes";
import { AdminUser } from "constants/types";
import MSG from "constants/validation.message";

import Divider from "@mui/material/Divider";
import useSuperUserPermission from "hooks/useSuperUserPermission";
import { EDIT_ADMIN_USER_DETAILS } from "schemas/mutation";
import { GET_ADMIN_USER } from "schemas/query";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";
import Loader from "ui-component/Loader";
import { accountStatus } from "../../constants/types";

const ProfileSettings = (props: any) => {
  // dispatch
  const { id } = useParams();
  const userId = props?.userId || id;
  const dispatch = useAppDispatch();

  const isSuperAdmin = useSuperUserPermission();

  const [user, setUser] = useState<AdminUser>();

  const navigate = useNavigate();
  const location = useLocation();
  // get user details
  const { data, error, loading } = useQuery(GET_ADMIN_USER, {
    variables: {
      body: {
        userId,
      },
    },
  });

  // edit user
  const [editAdminUser, { loading: editAdminUserLoading }] = useMutation(
    EDIT_ADMIN_USER_DETAILS
  );

  useEffect(() => {
    if (data?.getAdminUserProfile) {
      setUser(data.getAdminUserProfile);
    }
  }, [data]);
  if (loading || !user) {
    return <Spinner />;
  }

  if (error) {
    return <FailureLoad />;
  }
  const handleSubmit = async (values: any) => {
    // role check

    if (values.editorAdmin) {
      values.role = "editor";
    }

    if ((values.editorAdmin && values.superAdmin) || values.superAdmin) {
      values.role = "super";
    }

    if (!values.superAdmin && !values.editorAdmin) {
      values.role = "viewer";
    }

    try {
      let isMailSent = true;
      if (location?.state?.caller === "addAdminUser") {
        isMailSent = false;
      }

      const { data } = await editAdminUser({
        variables: {
          body: {
            userId,
            status: values.status,
            role: values.role,
            isMailSent,
          },
        },
      });

      dispatch(
        snackbarOpen({
          open: true,
          severity: "success",
          message: data?.updateAdminUsersProfile.status,
        })
      );
      navigate(`${ADMIN_USERS_ROUTE}`);
    } catch (error: any) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    }
  };

  if (editAdminUserLoading) {
    return <Loader />;
  }

  return (
    <>
      <MainCard sx={{ position: "relative" }}>
        <Formik
          initialValues={{
            status: data?.getAdminUserProfile?.status || "",
            editorAdmin:
              data?.getAdminUserProfile?.role === "editor" ? true : false,
            superAdmin:
              data?.getAdminUserProfile?.role === "super" ? true : false,
          }}
          validationSchema={Yup.object().shape({
            status: Yup.mixed()
              .oneOf(["active", "disabled"])
              .required(MSG.STATUS_IS_REQUIRED),
            editorAdmin: Yup.boolean().default(false),
            superAdmin: Yup.boolean().default(false),
          })}
          onSubmit={async (values) => {
            await handleSubmit({ ...values });
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            touched,
            errors,
            handleChange,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item md={6}>
                  <Typography variant='h4'>Account Status</Typography>
                  <Divider sx={{ my: 2.5 }} />
                  <Stack
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='stretch'
                    spacing={2.5}
                  >
                    <TextField
                      id='status'
                      name='status'
                      label='Enable/Disable account'
                      select
                      variant='filled'
                      value={values.status}
                      fullWidth
                      onChange={handleChange}
                    >
                      {accountStatus.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.status && (
                      <FormHelperText error id='status-error'>
                        {errors.status}
                      </FormHelperText>
                    )}

                    <FormControlLabel
                      label='Allow this admin to edit user details'
                      control={<Checkbox checked={values.editorAdmin} />}
                      name='editorAdmin'
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue("editorAdmin", !values.editorAdmin);
                      }}
                    />

                    <FormControlLabel
                      label='Allow this admin to edit admin details(SUPER ADMIN)
                          '
                      control={<Checkbox checked={values.superAdmin} />}
                      name='superAdmin'
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue("superAdmin", !values.superAdmin);
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2.5 }} />

                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={2}
                  >
                    {isSuperAdmin && (
                      <Button
                        size='large'
                        color='primary'
                        variant='contained'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        Save Changes
                      </Button>
                    )}

                    <Button
                      size='large'
                      color='primary'
                      variant='outlined'
                      sx={{ position: "relative" }}
                      onClick={() => navigate(`${ADMIN_USERS_ROUTE}`)}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

const GridContainer = styled(({ ...otherProps }) => (
  <MuiGrid {...otherProps} />
))`
  padding: 0.3em;
`;

const Grid = styled(({ ...otherProps }) => <MuiGrid {...otherProps} />)`
  //height: 1rem;
`;

export const Button = styled(({ ...otherProps }) => (
  <MuiButton {...otherProps} />
))`
  top: 0;
`;

export default ProfileSettings;

{
  /* <FormControl component="fieldset">
          <RadioGroup value={values.role} onChange={handleChange}>
            <FormControlLabel
              value="active"
              control={<Checkbox checked={values.editorAdmin} />}
              label="Allow this admin to edit user details"
            />
            <FormControlLabel
              name="editorAdmin"
              control=<Checkbox checked={values.superAdmin} />}
              label="Allow this admin to edit admin details(SUPER ADMIN)"
            />
          </RadioGroup>
        </FormControl> */
}
