import { lazy } from "react";

// project imports
import { DASHBOARD_ROUTE } from "constants/routes";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import DashboardGuard from "utils/route-guard/DashboardGuard";
// dashboard routing

const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoute = {
  path: "/",
  element: (
    <DashboardGuard>
      <MainLayout />
    </DashboardGuard>
  ),
  children: [
    {
      path: DASHBOARD_ROUTE,
      element: <DashboardDefault />,
    },
  ],
};

export default DashboardRoute;
