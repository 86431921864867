//ui
import { Grid, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

//project imports
import { gridSpacing } from "store/constant";
import EmailTemplateTable from "views/emails/EmailTemplateTable";

// ==============================|| USER LIST STYLE 1 ||============================== //

const Emails = () => {
  return (
    <MainCard sx={{ pt: 1.5 }} content={false}>
      <EmailTemplateTable />
    </MainCard>
  );
};

export default Emails;
