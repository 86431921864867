const validationMessages = {
  EN: {
    TITLE_REQUIRED: "Page title is required.",
    CONTENT_REQUIRED: "Page content is required.",
    EMAIL_REQUIRED: "Email is required.",
    INVALID_EMAIL: "Invalid email address.",
    PROVIDE_VALID_EMAIL: "Please provide valid email.",

    PERMISSION_REQUIRED: "Permission is required.",

    FULL_NAME_REQUIRED: "Full name is required.",
    MIN_USERNAME_CHARACTER: "Must be  at least three characters.",
    FIRST_NAME_REQUIRED: "First name is required.",
    FIRST_NAME_MIN_LENGTH: "First name must be at least 3 characters.",
    FIRST_NAME_MAX_LENGTH: "First name must be at most 20 characters.",

    LAST_NAME_REQUIRED: "Last name is required.",
    LAST_NAME_MAX_LENGTH: "Last name must be at most 20 characters.",
    LAST_NAME_MIN_LENGTH: "Last name must be at least 3 characters.",
    EMAIL_MUST_BE_VALID: "Email must be a valid email.",
    TITLE_IS_REQUIRED: "Title is required.",
    CONTACTNUMBER_IS_REQUIRED: "Contact number is required.",
    CONTACTNUMBER_MIN_LENGTH: "Contact number must be at least 10 digits.",
    CONTACTNUMBER_MAX_LENGTH: "Contact number must be at most 12 digits.",

    ADDRESS_REQUIRED: "Address is required.",
    ACCOUNT_STATUS_IS_REQUIRED: "Account status is required.",
    PROVIDE_VALID_FULL_NAME:
      "Please provide at least first name and last name.",

    MIN_PASSWORD_CHARACTER: "Must be  at least 8 characters.",
    PASSWORD_REQUIRED: "Password is required.",
    OLD_PASSWORD_REQUIRED: "Current password is required.",
    NEW_PASSWORD_REQUIRED: "New password is required.",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required.",
    PASSWORD_MUST_MATCH: "Password should match.",

    JOB_TITLE_REQUIRED: "Job title is required.",
    COMPANY_NAME_REQUIRED: "Company name is required.",
    COMPANY_NAME_MIN_LENGTH: "Company name must be at least 3 characters.",
    COMPANY_NAME_MAX_LENGTH: "Company name must be at most 20 characters.",
    COMPANY_WEBSITE_REQUIRED: "Company website is required.",
    COMPANY_ABN_REQUIRED: "ABN required.",

    PROVIDE_VALID_ABN_LENGTH:
      "Australian Business Number(ABN) must contain 11 digits.",

    //mail
    TEMPLATE_NAME_REQUIRED: "Template name is required.",
    SUBJECT_LINE_REQUIRED: "Subject line is required.",

    // admin permission
    INVALID_ADMIN_PERMISSION: "Invalid Admin Permission",

    // truck
    TRUCK_TYPE_MIN_LENGTH: "Should be at atleast 3 characters",
    TRUCK_TYPE_MAX_LENGTH: "Should be at atleast 20 characters",
    // edit company
    ABOUT_REQUIRED: "About is required.",
    ADDRESS_LINE_ONE_REQUIRED: "Address line one is required.",
    LEGAL_BUSINESS_NAME_REQUIRED: "Legal business name is required.",
    POST_CODE_REQUIRED: "Post code is required.",
    PROVIDE_VALID_WEBSITE: "Domain couldn't be verified. Not a valid URL.",
    STATE_REQUIRED: "State is required.",
    SUBURB_REQUIRED: "Suburb is required.",
    WEBSITE_REQUIRED: "Website is required",

    // file
    FILE_TOO_LARGE: "Uploaded file is too large.",

    //phone
    PROVIDE_VALID_MOBILE: "Provide a valid mobile number",
    PROVIDE_VALID_CONTACTNUMBER: "Provide a valid contact number",
    MOBILE_MIN_LENGTH: "Minimum length should be 8 or more.",
    MOBILE_REQUIRED: "Phone is required.",

    // gender

    VALID_GENDERS:
      "Gender must be either male/female/transgender/nonBinary/preferNotToSay",
    GENDER_REQUIRED: "Gender is required.",
    // app user
    APP_USER_AGE_LIMIT: "App user must be at least 17 years.",

    // city
    CITY_REQUIRED: "City is required.",
    COUNTRY_REQUIRED: "Country is required.",

    SELECT_ANY_ONE_OPTION: "Select at least one option.",
  },
};
const lang = process.env.SYSTEM_LANGUAGE || "EN";

let MSG: any;

if (lang === "EN") {
  MSG = validationMessages.EN;
}

export default MSG;
