import { useState } from "react";

import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  TextField,
  Grid,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
// import AnimateButton from "ui-component/extended/AnimateButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  selectReport,
  setFilter,
  setFromDate,
  setToDate,
} from "store/slice/reportSlice";

import { useAppDispatch, useAppSelector } from "store/hooks";
interface Props {
  open: boolean;
  onClose: (filter?: "active" | "disabled") => void;
}

export const StatusFilterDialog = (props: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(selectReport);

  const [reason, setReason] = useState<string[]>(filter);

  const [fromDateLocal, setFromDateLocal] = useState<string | null>(null);

  const [toDateLocal, setToDateLocal] = useState<string | null>(null);

  let newArray;

  const handleSearch = () => {
    props.onClose(filter);
    dispatch(setFilter(reason));
    dispatch(setFromDate(fromDateLocal));
    dispatch(setToDate(toDateLocal));
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleChange = (event: any) => {
    if (filter.length) {
      if (reason.includes(event.target.value)) {
        newArray = reason.filter(function (item: string) {
          return item !== event.target.value;
        });
        setReason(newArray);
      } else {
        newArray = [...reason, event.target.value];
        setReason(newArray);
      }
    } else if (reason.includes(event.target.value)) {
      newArray = reason.filter(function (item: string) {
        return item !== event.target.value;
      });
      setReason(newArray);
    } else {
      setReason((oldArray) => [...oldArray, event.target.value]);
    }
  };

  const handleStartDateChange = (value: any) => {
    setFromDateLocal(value);
  };

  const handleEndDateChange = (value: any) => {
    setToDateLocal(value);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={handleCancel}>
      <DialogTitle>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#111936",
          }}
        >
          Filter
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider />

        <Typography variant="h2">Reasons for reporting</Typography>
        <Container sx={{ ml: -1 }}>
          <FormControl component="fieldset">
            <RadioGroup value={filter} sx={{ mt: 2, mb: 1 }}>
              <FormControlLabel
                sx={{ mb: 2 }}
                value="fraudulent"
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={reason.includes("fraudulent")}
                  />
                }
                label="Fraudulent Activity"
              />
              <FormControlLabel
                sx={{ mb: 2 }}
                value="fake_account"
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={reason.includes("fake_account")}
                  />
                }
                label="Fake Account"
              />
              <FormControlLabel
                sx={{ mb: 2 }}
                value="harassment"
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={reason.includes("harassment")}
                  />
                }
                label="Harassment"
              />
              <FormControlLabel
                sx={{ mb: 2 }}
                value="false_advertising"
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={reason.includes("false_advertising")}
                  />
                }
                label="False Advertising"
              />
            </RadioGroup>
          </FormControl>
        </Container>

        <Divider />
        <Typography variant="h2" sx={{ mb: 2 }}>
          Reported Date Range
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker-field"
                  label="Startdate"
                  value={fromDateLocal ? dayjs(fromDateLocal) : null}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  sx={{
                    "& label.Mui-root": {
                      top: "0px",
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker-field"
                  label="Enddate"
                  value={toDateLocal ? dayjs(toDateLocal) : null}
                  onChange={(newValue) => handleEndDateChange(newValue)}
                  sx={{
                    "& label.Mui-root": {
                      top: "0px",
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSearch}
          sx={{ padding: "12px 15px !important" }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
