import { deleteCookie } from "utils/cookie";

export const removeTokens = () => {
  // remove tokens
  localStorage.removeItem("accesstoken");
  localStorage.removeItem("refreshtoken");
};

export const removeCookies = () => {
  deleteCookie("accesstoken");
  deleteCookie("refreshtoken");
};
