import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

// ui
import ButtonWithIcon from "ui-component/extended/ButtonWithIcon";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "ui-component/Loader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// project imports
import { EMAILS_ROUTE } from "constants/routes";
import { GET_EMAIL_TEMPLATES } from "schemas/query";
import { formatDate } from "utils/date-format";

// redux
import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch } from "store/hooks";
import { Typography } from "@mui/material";
import { DefaultEditIcon } from "ui-component/Icons/DefaultEditIcon";

interface EmailTemplate {
  _id: string;
  createdAt: Date;
  name: string;
  slug: string;
  updatedAt: Date;
}

const EmailTemplateTable = () => {
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate[]>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, loading } = useQuery(GET_EMAIL_TEMPLATES, {
    onError: (error) => {
      dispatch(
        snackbarOpen({ open: true, severity: "error", message: error.message })
      );
    },
  });

  useEffect(() => {
    if (data) {
      setEmailTemplate(data.listEmailTemplates.items);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TableContainer component={Paper} className="table-emails">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="menuCaption" noWrap>
                  Template Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="menuCaption" noWrap>
                  Subject
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="menuCaption" noWrap>
                  Date Published
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="menuCaption" noWrap>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailTemplate &&
              emailTemplate.map((item: EmailTemplate, index) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.name}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {formatDate(item.updatedAt?.toString())}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <ButtonWithIcon
                      icon={<DefaultEditIcon />}
                      clickHandler={() =>
                        navigate(`${EMAILS_ROUTE}/${item.slug}`)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EmailTemplateTable;
