import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

// ui
import ButtonWithIcon from "ui-component/extended/ButtonWithIcon";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "ui-component/Loader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "ui-component/CustomPagination";
import { Typography, Button } from "@mui/material";
import { StyledBarLoader } from "styles/globalStyles";
import { LinearProgress } from "@mui/material";
import AppEyeIcon from "ui-component/Icons/AppEyeIcon";

// project imports
import {
  EMAILS_ROUTE,
  REPORTS_ADS_ROUTE,
  REPORTS_ROUTE,
} from "constants/routes";
import { GET_ADS_REPORT } from "schemas/query";
import { formatDate } from "utils/date-format";

// redux
import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { DefaultEditIcon } from "ui-component/Icons/DefaultEditIcon";

import {
  selectReportAds,
  setLimit,
  setPage,
} from "store/slice/reportsAdsSclice";

import NoItemFound from "ui-component/NoItemFound";

interface ReportAds {
  _id: string;
  firstName: string;
  lastName: string;
  reason: string;
  createdAt: string;
}

const ReportsTable = () => {
  const { page, limit, searchText, filter, fromDate, toDate } = useAppSelector(
    selectReportAds
  );
  const [reportAds, setReportAds] = useState<ReportAds[]>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, error, loading } = useQuery(GET_ADS_REPORT, {
    variables: {
      body: {
        page: page,
        limit: limit,
        searchText: searchText || null,
        filter: filter.length ? filter : null,
        fromDate: fromDate,
        toDate: toDate,
      },
    },
  });

  if (error) {
    dispatch(
      snackbarOpen({ open: true, severity: "error", message: error.message })
    );
  }

  useEffect(() => {
    if (data) {
      setReportAds(data.getReportAdsReasons.items);
    }
  }, [data]);

  const handleClick = (id: string) => {
    navigate(`${REPORTS_ADS_ROUTE}/${id}`);
  };

  return (
    <>
      <StyledBarLoader>
        {loading && <LinearProgress color='inherit' />}
      </StyledBarLoader>
      {data?.getReportAdsReasons?.meta?.totalItems <= 0 && !loading ? (
        <NoItemFound />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label='simple table'
            className='customDataTable'
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Name
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Title
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Date Reported
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getReportAdsReasons?.items.map((item: ReportAds) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.firstName} {item.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.reason}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.createdAt?.split("T")[0]}
                    </Typography>
                  </TableCell>

                  <TableCell align='left'>
                    <Button
                      variant='outlined'
                      startIcon={<AppEyeIcon />}
                      onClick={() => handleClick(item._id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {data?.getReportAdsReasons?.meta?.totalItems > 0 && (
        <CustomPagination
          totalPages={data?.getReportAdsReasons?.meta?.totalPages}
          setPage={setPage}
          setLimit={setLimit}
          currentPage={data?.getReportAdsReasons?.meta?.currentPage}
          itemCount={data?.getReportAdsReasons?.meta?.itemCount}
        />
      )}
    </>
  );
};

export default ReportsTable;
