import { InputLabel } from "@mui/material";
import { useField } from "formik";
import { StyledError, StyledTextArea } from "styles/globalStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material/styles";

// @ts-ignore
const MyTextArea = (props) => {
  const [field, meta] = useField(props);
  const theme = useTheme();
  const msgColor = theme.palette.error.dark;

  return (
    <>
      <InputLabel
        htmlFor={props.id}
        sx={{
          position: "relative",
        }}
      >
        {props.label}
      </InputLabel>
      <StyledTextArea {...field} {...props} />

      {meta.touched && meta.error ? (
        <StyledError msgColor={msgColor}>
          <InfoOutlinedIcon sx={{ fontSize: "12px", mr: ".25rem" }} />
          {meta.error}
        </StyledError>
      ) : null}
    </>
  );
};

export default MyTextArea;
