import React from "react";

function AdminUserIcon() {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7545 11.9999C14.9626 11.9999 15.9482 12.9525 16.0012 14.1475L16.0004 14.25H8.00036L7.99991 13.499L2.25278 13.4999C1.83919 13.4999 1.50391 13.8352 1.50391 14.2488V14.8265C1.50391 15.3621 1.69502 15.8802 2.04287 16.2876C3.29618 17.7553 5.26206 18.5011 8.00036 18.5011V17.75L14.6425 17.751C13.0816 19.2569 10.8527 20.0011 8.00036 20.0011C4.8545 20.0011 2.46849 19.0959 0.902186 17.2617C0.322425 16.5827 0.00390625 15.7193 0.00390625 14.8265V14.2488C0.00390625 13.0068 1.01076 11.9999 2.25278 11.9999H13.7545ZM15.4705 16.7512L8.00036 16.75V15.25L15.9791 15.2502C15.9184 15.7818 15.7446 16.2935 15.4705 16.7512ZM8.00036 0C9.6361 0 11.0884 0.78547 12.0006 1.99981L8.00036 2V1.5C6.06737 1.5 4.50036 3.067 4.50036 5C4.50036 6.933 6.06737 8.5 8.00036 8.5V8L11.9998 8.0012C11.0876 9.21496 9.63564 10 8.00036 10C5.23894 10 3.00036 7.76142 3.00036 5C3.00036 2.23858 5.23894 0 8.00036 0ZM12.9756 5.5007C12.9231 6.02919 12.7882 6.53347 12.5841 7.00053L8.00036 7V5.5L12.9756 5.5007ZM12.5845 3.00046C12.7885 3.46754 12.9233 3.97182 12.9757 4.5003L8.00036 4.5V3L12.5845 3.00046Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default AdminUserIcon;
