import { Box, Typography } from "@mui/material";

interface Props {
  message?: string;
}

const NoItemFound = ({ message }: Props) => {
  return (
    <Box
      sx={{ padding: "10px 5px 0px 20px", minHeight: "calc(100vh - 310px)" }}
    >
      <Typography>{message ? message : `No item found.`}</Typography>
    </Box>
  );
};

export default NoItemFound;
