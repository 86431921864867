import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "utils/cookie";

interface UpdateUserProps {
  firstName: string;
  lastName: string;
  phone?: string | null;
  email: string;
  profilePicture?: string | null;
  companyName?: string | null;
  siteInformation?: string | null;
  _id: string;
}

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture?: string | null;
  status: string;
  role: string;
  title: string;
  address?: string;
  phone?: string | null;
  companyName: string;
  siteInformation?: string | null;
}

interface InitialState {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  isLoggedIn: boolean;
  status: string;
  role: string;
  address?: string;
  phone?: string | null;
  title: string;
  companyName: string;
  siteInformation?: string | null;
}
const initialState: InitialState = {
  _id: "",
  email: "",
  firstName: "",
  lastName: "",
  profilePicture: null,
  isLoggedIn: false,
  status: "",
  role: "",
  address: "",
  phone: "",
  title: "",
  companyName: "",
  siteInformation: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<User>) => {
      const {
        _id,
        email,
        firstName,
        lastName,
        profilePicture,
        role,
        status,
        title,
        address,
        phone,
        siteInformation,
      } = action.payload;
      state.isLoggedIn =
        localStorage.getItem("accesstoken") || getCookie("accesstoken")
          ? true
          : false;
      state._id = _id;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.profilePicture = profilePicture || null;
      state.status = status;
      state.role = role;
      state.title = title;
      state.address = address;
      state.phone = phone;
      state.siteInformation = siteInformation;
    },

    updateLoggedInUser: (state, action: PayloadAction<UpdateUserProps>) => {
      const {
        email,
        firstName,
        lastName,
        profilePicture,
        _id,
        phone,
        siteInformation,
      } = action.payload;
      state.isLoggedIn =
        localStorage.getItem("accesstoken") || getCookie("accesstoken")
          ? true
          : false;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.profilePicture = profilePicture || null;
      state.phone = phone || null;
      state.siteInformation = siteInformation || null;
      state._id = _id;
    },

    logoutUser() {},

    setProfileUrl: (state, action: PayloadAction<string>) => {
      state.profilePicture = action.payload;
    },
  },
});

export const {
  setLoggedInUser,
  logoutUser,
  setProfileUrl,
  updateLoggedInUser,
} = userSlice.actions;
export const selectUser = (state: any) => state.user;

export default userSlice.reducer;
