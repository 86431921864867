import { TableCell, TableHead, TableRow, Typography } from "@mui/material";

interface AppUserColumnHeadProps {
  columnHead: string[];
}
const AppUserColumnHead = ({ columnHead }: AppUserColumnHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {columnHead.map((item: string, index) => (
          <TableCell key={index}>
            <Typography variant="menuCaption" noWrap>
              {item}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AppUserColumnHead;
