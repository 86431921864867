import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  gql,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { store } from "store";
import { logoutUser } from "store/slice/userSlice";
import { getCookie } from "utils/cookie";

const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken =
    localStorage.getItem("accesstoken") || getCookie("accesstoken");

  if (accessToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  }

  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            store.dispatch(logoutUser());
        }
      }
    }
  }
);
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_ADMIN_API_URL,
});

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
  delay: {
    initial: 300,
    jitter: true,
    max: 5000,
  },
});

let client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, retryLink, httpLink]),
  name: "FreightSearch",
  version: "1.1",
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  },
};

export default client;
