import MainCard from "ui-component/cards/MainCard";
import EditCategories from "./EditCategories";

const ManageCategories = () => {
  return (
    <MainCard sx={{ pt: 1.5 }} content={false}>
      <EditCategories />
    </MainCard>
  );
};
export default ManageCategories;
