import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation LoginAdmin($body: LoginUserInput!) {
    loginAdmin(body: $body) {
      admin {
        _id
        companyName
        email
        firstName
        lastName
        phone
        address
        role
        siteInformation
        status
        title
      }
      tokens {
        accesstoken
        refreshtoken
      }
    }
  }
`;

export const getUploadUrl = gql`
  mutation GetUploadUrl($body: GetUploadUrlInput!) {
    getUploadUrl(body: $body) {
      key
      url
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GetSignedUrl($body: GetKeyInput!) {
    getSignedUrl(body: $body) {
      signedUrl
    }
  }
`;

export const CREATE_ADMIN_USER = gql`
  mutation Signup($body: CreateAdminInput!) {
    signup(body: $body) {
      status
      userId
    }
  }
`;

export const CREATE_PASSWORD = gql`
  mutation CreatePassword($body: ResetPasswordInput!) {
    createPassword(Body: $body) {
      status
    }
  }
`;

export const EDIT_ADMIN_USER_DETAILS = gql`
  mutation UpdateAdminUsersProfile($body: UpdateAdminProfileInput!) {
    updateAdminUsersProfile(Body: $body) {
      status
    }
  }
`;

export const EDIT_APP_USER_DETAILS = gql`
  mutation UpdateAppUserProfile($body: UpdateAppUserInput!) {
    updateAppUserProfile(Body: $body) {
      status
    }
  }
`;
export const SEND_RESET_PASSWORD_OTP = gql`
  mutation SendResetPasswordOtp($body: EmailInput!) {
    sendResetPasswordOtp(body: $body) {
      otpExpiryDate
      status
    }
  }
`;

export const VERIFY_RESET_PASSWORD_OTP = gql`
  mutation VerifyResetPasswordEmail($body: VerifyOtpInput!) {
    verifyResetPasswordEmail(body: $body) {
      coupon
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($body: ResetPasswordInput!) {
    resetPassword(body: $body) {
      status
    }
  }
`;

export const RESEND_OTP = gql`
  mutation ResendResetPasswordOtp($body: EmailInput!) {
    resendResetPasswordOtp(body: $body) {
      otpExpiryDate
      status
    }
  }
`;

export const UPDATE_APP_USER_DETAILS = gql`
  mutation updateAppUserDetail($body: UpdateAppUserDetailInput!) {
    updateAppUserDetail(body: $body) {
      status
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation getUploadUrl($body: GetUploadUrlInput!) {
    getUploadUrl(body: $body) {
      url
      key
    }
  }
`;

export const DELETE_APP_USER = gql`
  mutation delteAppUser($body: DeleteAppUserInput!) {
    deleteAppUser(body: $body) {
      status
    }
  }
`;

export const DELETE_APP_USER_SPORT_CARD = gql`
  mutation deleteAppUserSportCard($body: DeleteAppUserSportCardInput!) {
    deleteAppUserSportCard(body: $body) {
      status
    }
  }
`;

export const ADD_SPORT_CARD = gql`
  mutation createSportCard($body: CreateSportCardMetaInput!) {
    createSportCard(body: $body) {
      status
    }
  }
`;

export const DELETE_SPORT_CARD = gql`
  mutation deleteSportcardMeta($body: DeleteSportCardMetaInput!) {
    deleteSportcardMeta(body: $body) {
      status
    }
  }
`;
// ================================================================================================
export const EDIT_PAGE = gql`
  mutation UpdatePage($body: UpdatePageInput!) {
    updatePage(body: $body) {
      status
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($body: CreateAdminInput!) {
    createAdmin(body: $body) {
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($body: ChangePasswordInput!) {
    changePassword(body: $body) {
      status
    }
  }
`;

export const UPDATE_ADMIN_PROFILE = gql`
  mutation UpdateAdminProfile($body: UpdateProfileInput!) {
    updateAdminProfile(Body: $body) {
      status
      profilePicture
    }
  }
`;

export const EDIT_ADMIN = gql`
  mutation EditProfile($body: EditAdminInput!) {
    editProfile(body: $body) {
      status
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($body: AdminIdInput!) {
    deleteAdmin(body: $body) {
      status
    }
  }
`;

export const EDIT_COMPANY_USER = gql`
  mutation editCompanyUser($body: EditCompanyUserInput!) {
    editCompanyUser(body: $body) {
      status
    }
  }
`;

export const EDIT_EMAIL_TEMPLATE = gql`
  mutation EditEmailTemplate($body: EditEmailTemplateInput!) {
    editEmailTemplate(body: $body) {
      status
    }
  }
`;

export const ADD_TRUCK_TYPE = gql`
  mutation AddTruckType($body: AddTruckTypeInput!) {
    addTruckType(body: $body) {
      status
    }
  }
`;

export const EDIT_MANAGE_CATEGORIES = gql`
  mutation UpdateManageCategories(
    $body: AddTruckSizeInput!
    $typeBody: AddTruckTypeInput!
    $updateMinMaxDistanceBody2: UpdateMinMaxDistanceInput!
  ) {
    addTruckSize(body: $body) {
      status
    }
    addTruckType(body: $typeBody) {
      status
    }
    updateMinMaxDistance(body: $updateMinMaxDistanceBody2) {
      maxDistance
      minDistance
    }
  }
`;

export const UPDATE_GRACE_AND_EXPIRY = gql`
  mutation AddGraceAndExpiryDetails($body: GraceAndExpiryDetailsInput!) {
    addGraceAndExpiryDetails(body: $body) {
      status
    }
  }
`;

export const DISABLE_APP_USER = gql`
  mutation DisableAppUser($body: DisableAppUserInput!) {
    disableAppUser(Body: $body) {
      status
    }
  }
`;

export const ENABLE_APP_USER = gql`
  mutation EnableAppUser($body: DisableAppUserInput!) {
    enableAppUser(Body: $body) {
      status
    }
  }
`;

export const SET_NOTIFICATION_READ = gql`
  mutation HasReadNotification($body: NotificationIdInput!) {
    hasReadNotification(body: $body) {
      status
    }
  }
`;

export const EDIT_AD = gql`
  mutation EditAd($body: EditAdInput!) {
    editAd(body: $body) {
      status
    }
  }
`;

export const DELETE_S3_IMAGE = gql`
  mutation DeleteS3Image($body: GetKeyInput!) {
    deleteS3Image(body: $body) {
      status
    }
  }
`;
