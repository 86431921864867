import styled from "styled-components";

import {
  MenuItem,
  InputLabel,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

export const StyledError = styled.div<{ msgColor: string }>`
  color: ${(props) => props.msgColor || "red"};
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  margin: 8px 0 0 1px;
  line-height: 1;
`;

export const MenuItemWrapper = styled(MenuItem)<{ highlight: string }>`
  background: ${({ highlight }) => (highlight === "true" ? "#e5e5e8" : "#fff")};
`;

export const StyledMenuItem = styled(MenuItem)`
  text-transform: capitalize;
  padding: 10px 10px !important;
`;

export const StyledInputLabel = styled(InputLabel)`
  background-color: "white";
  padding: "0 0.3rem";
`;

export const StyledButtonWithIcon = styled.button<{
  mainColor: string;
  borderRadius: string;
}>`
  background-color: white;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "3px")};
  border: 1px solid ${({ mainColor }) => mainColor};
  box-shadow: none;
  height: 2.5rem;
  margin: 0.25rem;
  width: 2.5rem;

  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: 0.9rem;
    color: ${({ mainColor }) => mainColor};
  }
`;

export const StyledButton = styled(Button)`
  text-transform: none;
`;

export const StyledBarLoader = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: transparent;
`;

export const StyledTextArea = styled(TextareaAutosize)`
  border-radius: 12px;
  background: #fafafa;
  padding: 31px 16px 12px;
  resize: vertical;
  line-height: 20px;
  overflow: auto;
  width: "100%";
  color: "#2A2A2A";
  border: 1px solid #ede7f6;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.4px;
  &:focus {
    outline: none;
  }
`;

export const CustomButton = styled.button<{
  backgroundColor: string;
  color: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 12px;
  border: none;
  color: ${({ color }) => color};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.75em;
  min-width: 4rem;
  padding: 12px 15px;
`;

export const TypoGraphyLink = styled(Typography)`
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: "#757575";
  }
`;
