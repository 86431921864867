import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CardActions,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

//ui
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { Editor } from "@tinymce/tinymce-react";
import { Formik } from "formik";
import { EDIT_EMAIL_TEMPLATE } from "schemas/mutation";
import { GET_EMAIL_TEMPLATE } from "schemas/query";
import Loader from "ui-component/Loader";
import AnimateButton from "ui-component/extended/AnimateButton";

//project imports
import MSG from "constants/validation.message";
import { StyledError } from "styles/globalStyles";
import MainCard from "ui-component/cards/MainCard";
//redux
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";

import { useTheme } from "@mui/material/styles";
import { EMAILS_ROUTE } from "constants/routes";
import useEditorPermission from "hooks/useEditorPermission";
import { useNavigate, useParams } from "react-router-dom";
import { ValidationErrorMessage } from "./EditEmailTemplateWrapper";

interface ValueProps {
  content: string;
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required(MSG.TEMPLATE_NAME_REQUIRED),
  content: Yup.string().required(MSG.SUBJECT_LINE_REQUIRED),
});

const EditEmailTemplate = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const editorRef = useRef<any>(null);
  const log = () => {
    if (editorRef.current) {
      setContent(editorRef.current?.getContent());
    }
  };

  const { slug } = useParams();

  const isEditor = useEditorPermission();

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { loading: contentLoading, data, refetch, error } = useQuery(
    GET_EMAIL_TEMPLATE,
    {
      variables: {
        body: {
          slug,
        },
      },
    }
  );

  const [editEmailTemplate, { loading: editLoading }] = useMutation(
    EDIT_EMAIL_TEMPLATE
  );

  const handleSubmit = async (values: ValueProps) => {
    try {
      let content = editorRef?.current?.getContent();
      const { data: updatedData } = await editEmailTemplate({
        variables: {
          body: {
            content,
            id: data?.getEmailTemplate?._id,
          },
        },
      });

      refetch();

      updatedData &&
        dispatch(
          snackbarOpen({
            open: true,
            severity: "success",
            message: updatedData?.editEmailTemplate.status,
          })
        );

      navigate(EMAILS_ROUTE);
    } catch (error: any) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    }
  };

  useEffect(() => {
    if (data) {
      setContent(data.getEmailTemplate.content);
    }

    if (error) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    }
  }, [data, error, dispatch]);

  if (editLoading || contentLoading) {
    return <Loader />;
  }

  return (
    <>
      {loading && <Loader />}
      <MainCard content={false}>
        <Grid container rowSpacing={0} sx={{ padding: "30px 20px 11px" }}>
          <Grid
            container
            sx={{
              border: "1px solid #DBDCDC",
              borderRadius: "12px",
              padding: "20px 16px",
            }}
          >
            <Grid item xs={12}>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: data?.getEmailTemplate?.name,
                  content: data?.getEmailTemplate?.content,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setErrors }) => {
                  let content = editorRef?.current?.getContent();
                  if (!content) {
                    setErrors({ content: "Cannot be empty" });
                  } else {
                    await handleSubmit({ ...values });
                  }
                }}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit} id='edit-email-template'>
                    <Grid container rowSpacing={0}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <FormControl
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                              color='secondary'
                            >
                              <InputLabel htmlFor='outlined-adornment-template-name'>
                                Page Title
                              </InputLabel>
                              <FilledInput
                                fullWidth
                                placeholder='Template name'
                                id='name'
                                size='medium'
                                {...formik.getFieldProps("name")}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <StyledError
                                  msgColor={theme.palette.error.dark}
                                >
                                  {formik.errors.name}
                                </StyledError>
                              ) : null}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} mt={1}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Editor
                            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                            onInit={(evt, editor) => {
                              setLoading(false);
                              editorRef.current = editor;
                            }}
                            id='content'
                            initialValue={data?.getEmailTemplate?.content}
                            init={{
                              entity_encoding: "raw",
                              verify_html: false,
                              height: 488,

                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                " blocks | bold italic strikethrough forecolor backcolor blockquote   | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | code | preview",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:16px; }",
                            }}
                          />
                          {formik.touched.content && formik.errors.content ? (
                            <ValidationErrorMessage
                              color={theme.palette.error.dark}
                            >
                              <InfoTwoToneIcon
                                sx={{ fontSize: "12px", mr: ".25rem" }}
                              />
                              {formik.errors.content}
                            </ValidationErrorMessage>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    {isEditor && (
                      <Grid item xs={12} justifyContent='flex-end'>
                        <CardActions>
                          {
                            <Grid container justifyContent='flex-end'>
                              <Grid item>
                                <Stack
                                  direction='row'
                                  spacing={2}
                                  sx={{
                                    "@media (max-width: 400px)": {
                                      position: "relative",
                                      left: "35px",
                                    },
                                  }}
                                >
                                  <AnimateButton>
                                    <Button
                                      variant='contained'
                                      size='medium'
                                      sx={{ borderRadius: "4px" }}
                                      type='submit'
                                      onClick={log}
                                      color='primary'
                                      className='fixed-width'
                                      form='edit-email-template'
                                    >
                                      <Typography
                                        variant='caption'
                                        color='white'
                                      >
                                        Save Changes
                                      </Typography>
                                    </Button>
                                  </AnimateButton>
                                  <AnimateButton>
                                    <Button
                                      variant='outlined'
                                      sx={{ borderRadius: "4px" }}
                                      type='button'
                                      size='medium'
                                      color='primary'
                                      className='fixed-width'
                                      onClick={() => navigate(-1)}
                                    >
                                      <Typography variant='caption'>
                                        Cancel
                                      </Typography>
                                    </Button>
                                  </AnimateButton>
                                </Stack>
                              </Grid>
                            </Grid>
                          }
                        </CardActions>
                      </Grid>
                    )}
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default EditEmailTemplate;
