import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchResults {
  id: string;
  address: {
    freeformAddress: string;
    countrySubdivision: string;
    localName: string;
    postalCode: string;
  };
  position: {
    lat: string;
    lon: string;
  };
}

interface InitialStateProps {
  searchText: string;
  searchResults: SearchResults[];
}

const initialState: InitialStateProps = {
  searchText: "",
  searchResults: [],
};
export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<SearchResults[]>) => {
      state.searchResults = action.payload;
    },
  },
});

export const { setSearchText, setSearchResults } = addressSlice.actions;

export const selectAddress = (state: any) => state.address;

export default addressSlice.reducer;
