function Icon({ width = "24", height = "24", fill = "#3d4853" }) {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 5C3.22386 5 3 5.22386 3 5.5C3 5.77614 3.22386 6 3.5 6H8.5C8.77614 6 9 5.77614 9 5.5C9 5.22386 8.77614 5 8.5 5H3.5ZM3 8.5C3 8.22386 3.22386 8 3.5 8H8.5C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9H3.5C3.22386 9 3 8.77614 3 8.5ZM3.5 11C3.22386 11 3 11.2239 3 11.5C3 11.7761 3.22386 12 3.5 12H5.5C5.77614 12 6 11.7761 6 11.5C6 11.2239 5.77614 11 5.5 11H3.5ZM2.5 0C2.22386 0 2 0.223858 2 0.5V1H1.5C0.671573 1 0 1.67157 0 2.5V14.5C0 15.3284 0.671573 16 1.5 16H7.5C7.63261 16 7.75979 15.9473 7.85355 15.8536L11.8536 11.8536C11.9473 11.7598 12 11.6326 12 11.5V2.5C12 1.67157 11.3284 1 10.5 1H10V0.5C10 0.223858 9.77614 0 9.5 0C9.22386 0 9 0.223858 9 0.5V1H6.5V0.5C6.5 0.223858 6.27614 0 6 0C5.72386 0 5.5 0.223858 5.5 0.5V1H3V0.5C3 0.223858 2.77614 0 2.5 0ZM10.5 2C10.7761 2 11 2.22386 11 2.5V11H8.5C7.67157 11 7 11.6716 7 12.5V15H1.5C1.22386 15 1 14.7761 1 14.5V2.5C1 2.22386 1.22386 2 1.5 2H10.5ZM10.2929 12L8 14.2929V12.5C8 12.2239 8.22386 12 8.5 12H10.2929Z"
        fill="#001F8D"
      />
    </svg>
  );
}

export default Icon;
