import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Theme, styled, useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { drawerWidth } from "store/constant";

import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "constants/routes";
import useBreadCrumbTitles from "hooks/useBreadCrumbTitles";
import { SET_MENU } from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { logoutUser, selectUser } from "store/slice/userSlice";
import { DefaultRootStateProps } from "types";
import CustomBreadcrumb from "ui-component/CustomBreadCrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { getCookie } from "utils/cookie";

// material-ui

// project imports

// assets

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("lg")]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const titleList = useBreadCrumbTitles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  const { pathname: currentRoute } = useLocation();
  const navigate = useNavigate();

  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: DefaultRootStateProps) => state.customization.opened
  );
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  React.useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    if (currentRoute === "/") {
      navigate(DASHBOARD_ROUTE);
    }

    const accesstoken =
      localStorage.getItem("accesstoken") || getCookie("accesstoken");
    const refreshtoken =
      localStorage.getItem("refreshtoken") || getCookie("refreshtoken");

    if (!accesstoken || !refreshtoken) {
      appDispatch(logoutUser());
      navigate(LOGIN_ROUTE);
    }
  }, [currentRoute, appDispatch, navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position='fixed'
        color='inherit'
        elevation={0}
        sx={{
          backgroundColor: "#fff !important",
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main
        theme={theme}
        open={leftDrawerOpened}
        sx={{ background: `${theme.palette.background.default}`, mr: 0 }}
      >
        <Box
          sx={{
            mt: { xs: "92px", lg: "86px" },
            borderRadius: "12px",
            p: 3,
            background: `${theme.palette.background.paper}`,
            width: "100%",
          }}
        >
          <CustomBreadcrumb titleList={titleList} />
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
};

export default MainLayout;
