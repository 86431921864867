import { lazy } from "react";

// project imports
import {
  ADD_ADMIN_USER_ROUTE,
  ADMIN_CHANGE_PASSWORD,
  ADMIN_USERS_ROUTE,
  ADMIN_USER_DETAILS_ROUTE,
  ADMIN_USER_PROFILE_SETTINGS_ROUTE,
  ADS_LIST_ROUTE,
  AD_DETAILS_ROUTE,
  APP_USERS_ROUTE,
  APP_USER_DETAILS_ROUTE,
  EDIT_ADMIN_USER_ROUTE,
  EDIT_APP_USER_ROUTE,
  EMAILS_ROUTE,
  GRACE_AND_EXPIRY_ROUTE,
  LOGGED_IN_ADMIN_PROFILE_ROUTE,
  MANAGE_CATEGORIES_ROUTE,
  PAGE_MANAGEMENT_ROUTE,
  REPORTS_ADS_DETAILS_ROUTE,
  REPORTS_ADS_ROUTE,
  REPORTS_DETAILS_ROUTE,
  REPORTS_ROUTE,
  SAVED_SEARCH_ROUTE,
  SEARCH_AD_LIST_ROUTE,
  SIMILAR_SAVED_SEARCH_ROUTE,
  SUBSCRIPTION_DETAILS_ROUTE,
  SUBSCRIPTION_ROUTE,
  EDIT_AD_ROUTE,
  APP_USER_AD_DETAILS_ROUTE,
  APP_USER_SAVED_AD_DETAILS_ROUTE,
} from "constants/routes";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import ManageCategories from "views/Categories";
import EditAd from "views/ad/ad-edit";
import Emails from "views/emails";
import EditEmailTemplate from "views/emails/EditEmailTemplate";
import PageManagement from "views/page-management";
import EditPage from "views/page-management/EditPage";
import Reports from "views/reports";
import ReportsAds from "views/reports-ads";
import ReportAdsDetail from "views/reports-ads/reportAdsDetails";
import ReportsDetails from "views/reports/reportDetails";
import SavedSearch from "views/saved-search";
import SearchAdList from "views/saved-search/searchAdlist.index";
import SimilarSavedSearch from "views/saved-search/similar-saved-search.index";
import Subscription from "views/subscription";
import ProfileSettings from "views/user-management/admin-user/admin-user-details/settings";
import AppUserList from "views/user-management/app-user/app-user-list";
import SubscriptionDetail from "views/subscription/subscriptionUsersDetails";

const AdminUserList = Loadable(
  lazy(() => import("views/user-management/admin-user/admin-user-list"))
);
const AdminUserDetails = Loadable(
  lazy(() => import("views/user-management/admin-user/admin-user-details"))
);
const AppUserDetails = Loadable(
  lazy(() => import("views/user-management/app-user/app-user-details"))
);
const AddAdminUser = Loadable(
  lazy(
    () =>
      import(
        "views/user-management/admin-user/admin-user-details/add-admin-user"
      )
  )
);
const EditAdminUser = Loadable(
  lazy(
    () =>
      import(
        "views/user-management/admin-user/admin-user-details/edit-admin-user"
      )
  )
);

//user-management routing
const AdminChangePassword = Loadable(
  lazy(
    () =>
      import("views/application/users/account-profile/Profile2/ChangePassword")
  )
);

const AdminProfile = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile2"))
);
const EditAppUser = Loadable(
  lazy(
    () =>
      import("views/user-management/app-user/app-user-details/edit-app-user")
  )
);

const GraceAndExpiry = Loadable(lazy(() => import("views/grace-and-expiry")));

const AdList = Loadable(lazy(() => import("views/ad/ad-list")));

const AdDetail = Loadable(lazy(() => import("views/ad/ad-details")));
const AppUserAdDetail = Loadable(lazy(() => import("views/ad/ad-details")));
const AppUserSavedAdDetail = Loadable(
  lazy(() => import("views/ad/ad-details"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: PAGE_MANAGEMENT_ROUTE,
      element: <PageManagement />,
    },
    {
      path: `${PAGE_MANAGEMENT_ROUTE}/:slug`,
      element: <EditPage />,
    },
    {
      path: ADMIN_USERS_ROUTE,
      element: <AdminUserList />,
    },
    {
      path: APP_USERS_ROUTE,
      element: <AppUserList />,
    },
    {
      path: APP_USER_DETAILS_ROUTE,
      element: <AppUserDetails />,
    },
    {
      path: EDIT_APP_USER_ROUTE,
      element: <EditAppUser />,
    },
    {
      path: ADMIN_USER_DETAILS_ROUTE,
      element: <AdminUserDetails />,
    },
    {
      path: ADMIN_USER_PROFILE_SETTINGS_ROUTE,
      element: <ProfileSettings />,
    },
    {
      path: LOGGED_IN_ADMIN_PROFILE_ROUTE,
      element: <AdminProfile />,
    },
    {
      path: ADMIN_CHANGE_PASSWORD,
      element: <AdminChangePassword />,
    },
    {
      path: ADD_ADMIN_USER_ROUTE,
      element: <AddAdminUser />,
    },
    {
      path: EDIT_ADMIN_USER_ROUTE,
      element: <EditAdminUser />,
    },
    {
      path: EMAILS_ROUTE,
      element: <Emails />,
    },
    {
      path: MANAGE_CATEGORIES_ROUTE,
      element: <ManageCategories />,
    },
    {
      path: `${EMAILS_ROUTE}/:slug`,
      element: <EditEmailTemplate />,
    },
    {
      path: REPORTS_ROUTE,
      element: <Reports />,
    },
    {
      path: REPORTS_DETAILS_ROUTE,
      element: <ReportsDetails />,
    },
    {
      path: GRACE_AND_EXPIRY_ROUTE,
      element: <GraceAndExpiry />,
    },
    {
      path: SUBSCRIPTION_ROUTE,
      element: <Subscription />,
    },
    {
      path: ADS_LIST_ROUTE,
      element: <AdList />,
    },
    {
      path: AD_DETAILS_ROUTE,
      element: <AdDetail />,
    },
    {
      path: REPORTS_ADS_ROUTE,
      element: <ReportsAds />,
    },
    {
      path: REPORTS_ADS_DETAILS_ROUTE,
      element: <ReportAdsDetail />,
    },
    {
      path: APP_USER_AD_DETAILS_ROUTE,
      element: <AppUserAdDetail />,
    },
    {
      path: APP_USER_SAVED_AD_DETAILS_ROUTE,
      element: <AppUserSavedAdDetail />,
    },
    {
      path: SAVED_SEARCH_ROUTE,
      element: <SavedSearch />,
    },
    {
      path: SIMILAR_SAVED_SEARCH_ROUTE,
      element: <SimilarSavedSearch />,
    },
    {
      path: SEARCH_AD_LIST_ROUTE,
      element: <SearchAdList />,
    },
    {
      path: EDIT_AD_ROUTE,
      element: <EditAd />,
    },
    {
      path: SUBSCRIPTION_DETAILS_ROUTE,
      element: <SubscriptionDetail />,
    },
  ],
};

export default MainRoutes;
