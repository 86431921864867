// material-ui
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import loader from "assets/images/loader.gif";
import FreightSearchLogo from "./Icons/FreightSearchLogo";
// styles
const LoaderWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  zIndex: 999,
  width: "100%",
  position: "fixed",
  left: "0",
  top: "0",

  "@keyframes myEffect": {
    "0%": {
      opacity: 0.3,
      transform: "scale(0.3)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(0.6)",
    },
  },

  "& svg": {
    animation: `myEffect 1s linear infinite`,
  },
});

// ==============================|| LOADER ||============================== //

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    {/* <img src={loader} alt="Loader" /> */}
    <FreightSearchLogo />
  </LoaderWrapper>
);

export default Loader;
