import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

// ui
import { Button, LinearProgress, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledBarLoader } from "styles/globalStyles";
import CustomPagination from "ui-component/CustomPagination";
import AppEyeIcon from "ui-component/Icons/AppEyeIcon";

// project imports
import { SIMILAR_SAVED_SEARCH_ROUTE } from "constants/routes";
import { GET_SAVED_SEARCH_LIST } from "schemas/query";

// redux
import { useAppDispatch, useAppSelector } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";

import {
  selectSavedSearch,
  setLimit,
  setPage,
} from "store/slice/savedSearchSlice";

import NoItemFound from "ui-component/NoItemFound";

interface SavedSearch {
  _id: string;
  adType: string;
  truckSize: [string];
  truckType: [string];
  createdAt: string;
  isSaved: Boolean;
  savedCounts: number;
}

const SavedSearchTable = () => {
  const {
    page,
    limit,
    searchText,
    truckType,
    truckSize,
    adType,
  } = useAppSelector(selectSavedSearch);
  const [savedSearch, setSavedSearch] = useState<SavedSearch[]>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, error, loading } = useQuery(GET_SAVED_SEARCH_LIST, {
    variables: {
      body: {
        page: page,
        limit: limit,
        searchText: searchText || null,
        truckType: truckType.length ? truckType : null,
        truckSize: truckSize.length ? truckSize : null,
        adType: adType.length ? adType : null,
      },
    },
  });

  if (error) {
    dispatch(
      snackbarOpen({ open: true, severity: "error", message: error.message })
    );
  }

  useEffect(() => {
    if (data) {
      setSavedSearch(data.listSavedSearch.items);
    }
  }, [data]);

  const handleClick = (
    id: string,
    adType: string | null,
    truckType: [string] | null,
    truckSize: [string] | null
  ) => {
    navigate(
      `${SIMILAR_SAVED_SEARCH_ROUTE}?adType=${adType}&truckType=${truckType}&truckSize=${truckSize}`
    );
  };

  return (
    <>
      <StyledBarLoader>
        {loading && <LinearProgress color="inherit" />}
      </StyledBarLoader>
      {data?.listSavedSearch?.meta?.totalItems <= 0 && !loading ? (
        <NoItemFound />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="customDataTable"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Adtype
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Truck type
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Truck size
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Save counts
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="menuCaption" noWrap>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.listSavedSearch?.items.map((item: SavedSearch) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.adType ? item.adType : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.truckType.length
                        ? item.truckType.join(",  ")
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.truckSize.length
                        ? item.truckSize.join(",  ")
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align="left"
                      variant="subMenuCaption"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.savedCounts}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      startIcon={<AppEyeIcon />}
                      onClick={() =>
                        handleClick(
                          item._id,
                          item.adType ? item.adType : null,
                          item.truckType.length ? item.truckType : null,
                          item.truckSize.length ? item.truckSize : null
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {data?.listSavedSearch?.meta?.totalItems > 0 && (
        <CustomPagination
          totalPages={data?.listSavedSearch?.meta?.totalPages}
          setPage={setPage}
          setLimit={setLimit}
          currentPage={data?.listSavedSearch?.meta?.currentPage}
          itemCount={data?.listSavedSearch?.meta?.itemCount}
        />
      )}
    </>
  );
};

export default SavedSearchTable;
