import { gql } from "@apollo/client";

export const GET_APP_USER_LIST = gql`
  query ListAppUsers($body: ListAppUsersInput!) {
    listAppUsers(Body: $body) {
      items {
        _id
        accountStatus
        currentPackage
        email
        firstName
        hasSubscription
        lastName
        phoneNumber
        profileCompleted
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_APP_USER_ADS_LIST = gql`
  query ListAppUserAds($body: ListAppUsersAdsInput!) {
    listAppUserAds(Body: $body) {
      items {
        _id
        adId
        adOwnerName
        adStatus
        createdAt
        isUrgent
        type
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_APP_USER_SAVED_ADS_LIST = gql`
  query ListSavedAds($body: ListAppUsersAdsInput!) {
    listSavedAds(Body: $body) {
      items {
        _id
        adId
        adStatus
        dateSaved
        isUrgent
        type
        savedSearchTitle
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_APP_USER_PROFILE = gql`
  query GetAppUserProfile($body: UserIdInput!) {
    getAppUserProfile(Body: $body) {
      _id
      abn
      accountStatus
      address {
        city
        fullAddress
        state
        zip
      }
      companyEmail
      companyId
      companyLocationScreenShot {
        key
        originalUrl
        thumbnailUrl
      }
      companyPhoneNumber
      companyWebsite
      email
      firstName
      lastName
      name
      phoneNumber
      profilePicture {
        key
        originalUrl
        thumbnailUrl
      }
      subscription
      title
      userAddress {
        address
        location {
          coordinates
        }
      }
      userLocationScreenShot {
        key
        originalUrl
        thumbnailUrl
      }
      profileCompleted
    }
  }
`;

export const GET_ADMIN_USER_LIST = gql`
  query ListAdminUsers($body: ListAdminUsersInput!) {
    listAdminUsers(Body: $body) {
      items {
        _id
        address
        companyName
        email
        firstName
        lastName
        phone
        profilePicture {
          key
          originalUrl
          thumbnailUrl
        }
        role
        siteInformation
        status
        title
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_ADMIN_USER = gql`
  query GetAdminUserProfile($body: UserIdInput!) {
    getAdminUserProfile(Body: $body) {
      _id
      address
      companyName
      email
      firstName
      lastName
      phone
      profilePicture {
        key
        originalUrl
        thumbnailUrl
      }
      role
      siteInformation
      status
      title
    }
  }
`;

export const GET_PAGES = gql`
  query ListPages {
    listPages {
      _id
      content
      title
      updatedAt
      slug
      pageStatus
    }
  }
`;

export const GET_PAGE = gql`
  query GetPage($body: SlugInput!) {
    getPage(body: $body) {
      _id
      content
      title
      slug
      pageStatus
    }
  }
`;

export const GET_ADMINS = gql`
  query GetAdmins($body: ListAdminsInput!) {
    listAdmins(body: $body) {
      items {
        _id
        email
        permission
        username
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_EMAIL_TEMPLATES = gql`
  query {
    listEmailTemplates {
      items {
        _id
        name
        slug
        updatedAt
      }
    }
  }
`;

export const GET_EMAIL_TEMPLATE = gql`
  query GetEmailTemplate($body: MailSlugInput!) {
    getEmailTemplate(body: $body) {
      _id
      content
      createdAt
      name
      slug
      subject
      updatedAt
    }
  }
`;

export const GetSignedUrlFromKey = gql`
  query getSignedUrlFromKey($body: KeyInput!) {
    getSignedUrlFromKey(body: $body) {
      url
    }
  }
`;

export const GET_MANAGE_CATEGORIES = gql`
  query GetTruckSize {
    getTruckSize {
      value {
        id
        isDeleted
        label
      }
    }
    getTruckType {
      value {
        id
        isDeleted
        label
      }
    }
    getMinMaxDistance {
      maxDistance
      minDistance
    }
  }
`;

export const GET_REPORT = gql`
  query getReportUserReasons($body: ListReportUsersInput!) {
    getReportUserReasons(Body: $body) {
      items {
        _id
        firstName
        lastName
        reason
        createdAt
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_REPORT_DETAILS = gql`
  query GetReportUserDetails($body: ReportIdInput!) {
    getReportUserDetails(Body: $body) {
      createdAt
      firstName
      lastName
      reason
      reporterLastName
      reporterFirstName
      userId
      description
      accountStatus
    }
  }
`;

export const GET_AD_GRACE_AND_EXPIRY_DETAILS = gql`
  query GetGraceAndExpiryDetails {
    getGraceAndExpiryDetails {
      expiryPeriod
      _id
      gracePeriod
    }
  }
`;

export const AD_LIST = gql`
  query ListAds($body: ListAdInput!) {
    listAds(body: $body) {
      items {
        _id
        adId
        adOwnerName
        createdAt
        isUrgent
        status
        type
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_AD_DETAILS = gql`
  query GetAdDetails($body: AdMongoIdInput!) {
    getAdDetails(body: $body) {
      _id
      adId
      createdAt
      dateAvailableFrom
      dateAvailableTo
      description
      dropOffDetails {
        coordinates
        fullAddress
        state
        town
        zip
      }
      freightParticulars {
        height
        length
        weight
        width
      }
      isUrgent
      locationScreenShot {
        originalUrl
        thumbnailUrl
      }
      photos {
        key
        originalUrl
        thumbnailUrl
      }
      pickUpDetails {
        coordinates
        fullAddress
        state
        town
        zip
      }
      postedBy
      truckSize
      truckType
      type
    }
  }
`;

export const GET_ADS_REPORT = gql`
  query getReportAdsReasons($body: ListReportAdsInput!) {
    getReportAdsReasons(Body: $body) {
      items {
        _id
        firstName
        lastName
        reason
        createdAt
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_REPORT_ADS_DETAILS = gql`
  query GetReportAdsDetails($body: ReportAdsIdInput!) {
    getReportAdsDetails(Body: $body) {
      createdAt
      firstName
      lastName
      reason
      reporterLastName
      reporterFirstName
      userId
      description
    }
  }
`;

export const GET_SAVED_SEARCH_LIST = gql`
  query ListSavedSearch($body: ListSavedSearchInput!) {
    listSavedSearch(Body: $body) {
      items {
        _id
        adType
        truckSize
        truckType
        searchDetail {
          fullAddress
        }
        isSaved
        savedCounts
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_AD_STAT = gql`
  query GetAdStats {
    getAdStats {
      carrierActiveCount
      carrierAds
      carrierExpiredCount
      carrierGraceCount
      freightActiveCount
      freightAds
      freightExpiredCount
      freightGraceCount
      totalAds
    }
  }
`;

export const GET_NEW_AD_USER_ACCOUNT_STAT = gql`
  query GetNewAdAndUserStats($body: NewAdUserAndStatInput!) {
    getNewAdAndUserStats(body: $body) {
      totalNewAds
      totalNewSubscribedAccounts
      totalNewUsers
    }
  }
`;

export const GET_SIMILAR_SAVED_SEARCH_LIST = gql`
  query ListSimilarSavedSearch($body: ListSimilarSavedSearchInput!) {
    listSimilarSavedSearch(Body: $body) {
      items {
        _id
        adType
        truckSize
        truckType
        searchDetail {
          fullAddress
        }
        isSaved
        savedCounts
        firstName
        lastName
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_SAVED_SEARCH_By_ID = gql`
  query GetSavedSearchById($body: SavedSearchIdInput!) {
    getSavedSearchById(Body: $body) {
      _id
      adType
      dateDetails {
        from
        to
      }
      dropOffcoordinates
      filterCount
      firstName
      isSaved
      lastName
      pickUpcoordinates
      savedCounts
      searchDetail {
        fullAddress
        location {
          coordinates
          type
        }
        radius
        state
        town
        zip
      }
      truckSize
      truckType
    }
  }
`;

export const GET_FILTERED_ADS = gql`
  query GetFilteredAds($body: GetFilterAdInput!) {
    getFilteredAds(body: $body) {
      items {
        _id
        adId
        adType
        status
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const LIST_ADMIN_NOTIFICATION = gql`
  query ListAdminNotifications($body: ListNotificationInput!) {
    listAdminNotifications(body: $body) {
      items {
        _id
        message
        relativeTime
        type
        userName
        hasRead
        profileImage
        notificationMetaData {
          reportId
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query GetUnreadNotificationCount {
    getUnreadNotificationCount {
      totalUnreadNotifications
    }
  }
`;

export const GET_ADMIN_PROFILE_URL = gql`
  query GetAdminProfileUrl {
    getAdminProfileUrl {
      profilePicture
    }
  }
`;

export const GET_USER_SUBSCRIPTION = gql`
  query GetUserSubscriptionList($body: ListUserSubscriptionInput!) {
    getUserSubscriptionList(Body: $body) {
      items {
        _id
        status
        subscription {
          cost
          dateExpired
          datePurchased
          product_id
        }
        firstName
        lastName
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_USER_SUBSCRIPTION_DETAIL = gql`
  query GetUserSubscriptionDetails($body: UserSubscriptionIdInput!) {
    getUserSubscriptionDetails(Body: $body) {
      _id
      firstName
      lastName
      status
      subscription {
        cost
        dateExpired
        datePurchased
        product_id
      }
      adCount
    }
  }
`;

export const GET_APP_USER_SUBSCRIPTION = gql`
  query GetUserSubscriptionDetailsByUserId($body: UserInput!) {
    getUserSubscriptionDetailsByUserId(Body: $body) {
      _id
      firstName
      lastName
      status
      subscription {
        cost
        dateExpired
        datePurchased
        product_id
      }
    }
  }
`;

export const GET_TRUCK_DETAILS = gql`
  query GetTruckDetails {
    getTruckSize {
      value {
        id
        isDeleted
        label
      }
    }
    getTruckType {
      value {
        id
        isDeleted
        label
      }
    }
  }
`;

export const GET_SUBSCRIPTION_STATS = gql`
  query GetUserSubscriptionStats($body: UserSubscriptionStatsInput!) {
    getUserSubscriptionStats(Body: $body) {
      monthlySubscription
      weeklySubscription
      yearlySubscription
      nonSubscribedUser
      subscribedUser
    }
  }
`;
