interface Props {
  key?: string | null;
  originalUrl?: string | null;
  thumbnailUrl?: string | null;
}

const formatAdPhotos = (photos: Props[]) => {
  let files: {
    file?: string | null;
    url?: string | null;
    key?: string | null;
  }[] = [];

  photos.forEach((item) => {
    if (item.originalUrl) {
      files.push({ url: item.originalUrl, file: null, key: item.key });
    }
  });

  return files;
};

export { formatAdPhotos };
