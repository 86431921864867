// status
interface StatusProps {
  status: string[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  statusType: string;
}

export const handleChangeStatus = ({
  status,
  setFieldValue,
  statusType,
}: StatusProps) => {
  if (status.includes(statusType)) {
    const filteredValue = status.filter((item: string) => item !== statusType);

    setFieldValue(`status`, filteredValue);
    return;
  }
  setFieldValue(`status.${status?.length}`, statusType);
};

// subscription
interface SubscriptionProps {
  subscription: string[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  type: string;
}

export const handleChangeSubscription = ({
  subscription,
  setFieldValue,
  type,
}: SubscriptionProps) => {
  if (subscription.includes(type)) {
    const filteredValue = subscription.filter((item: string) => item !== type);

    setFieldValue(`subscription`, filteredValue);
    return;
  }
  setFieldValue(`subscription.${subscription?.length}`, type);
};
