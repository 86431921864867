// third-party
import { FormattedMessage } from "react-intl";

//project imports
import AppUserIcon from "ui-component/Icons/AppUserIcon";
import AdminUserIcon from "ui-component/Icons/AdminUserIcon";
import { ADMIN_USERS_ROUTE, APP_USERS_ROUTE } from "constants/routes";

const userManagement = {
  id: "user-management",
  title: <FormattedMessage id="user management" />,
  disabled: true,
  type: "group",
  children: [
    {
      id: "app-user",
      title: <FormattedMessage id="app users" />,
      type: "item",
      icon: AppUserIcon,
      url: APP_USERS_ROUTE,
      breadcrumbs: false,
    },
    {
      id: "admin-user",
      title: <FormattedMessage id="admin users" />,
      type: "item",
      icon: AdminUserIcon,
      url: ADMIN_USERS_ROUTE,
      breadcrumbs: false,
    },
  ],
};

export default userManagement;
