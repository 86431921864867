import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { GET_PAGES } from "schemas/query";

import ReactTable from "ui-component/ReactTable";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";

const PageList = () => {
  const [pages, setPages] = useState([]);

  const dispatch = useAppDispatch();

  const { loading, data } = useQuery(GET_PAGES, {
    onError: (error) => {
      dispatch(
        snackbarOpen({
          open: true,
          message: error.message,
          severity: "error",
        })
      );
    },
  });

  const columns = [
    {
      Header: "Page Title",
      accessor: "title",
    },
    {
      Header: "Date Published",
      accessor: "updatedAt",
    },
    {
      Header: "Status",
      accessor: "pageStatus",
    },
    {
      Header: "Action",
      accessor: "actions",
    },
  ];

  useEffect(() => {
    if (data) {
      setPages(data.listPages);
    }
  }, [data]);

  return (
    <ReactTable
      data={pages}
      pageColumns={columns}
      loading={loading}
    ></ReactTable>
  );
};

export default PageList;
