import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { Grid, Pagination, Button, Menu, MenuItem } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useAppDispatch } from "store/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import scrollToTop from "utils/scrollToTop";

interface Props {
  totalPages: number | undefined;
  setPage: ActionCreatorWithPayload<any>;
  setLimit: ActionCreatorWithPayload<any>;
  currentPage: number | undefined;
  itemCount: number | undefined;
}

const CustomPagination = ({
  totalPages,
  setPage,
  setLimit,
  currentPage,
  itemCount,
}: Props) => {
  const [row, setRow] = useState<number>(0);

  useEffect(() => {
    if (itemCount) {
      itemCount > 20 && setRow(30);

      itemCount > 10 && itemCount <= 20 && setRow(20);

      itemCount <= 10 && setRow(10);
    }
  }, [itemCount]);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    scrollToTop();

    dispatch(setPage(value));
  };

  const handleRowsPerPage = (value: number) => {
    dispatch(setLimit(value));

    dispatch(setPage(1));
    setRow(value);
    handleClose();
  };

  return (
    <Grid container className="table-pager">
      <Grid item xs={12} sm={8}>
        <Pagination
          count={totalPages ? totalPages : 1}
          color="primary"
          shape="rounded"
          siblingCount={0}
          size="medium"
          onChange={handleChange}
          page={currentPage ? currentPage : 1}
          defaultPage={1}
          boundaryCount={1}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant="text"
          size="medium"
          sx={{ color: theme.palette.grey[800] }}
          color="inherit"
          endIcon={<ExpandMoreRoundedIcon />}
          onClick={handleClick}
        >
          {`${row} Rows`}
        </Button>
        <Menu
          id="menu-user-card-style1"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => {
              handleRowsPerPage(10);
            }}
          >
            10 Rows
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleRowsPerPage(20);
            }}
          >
            20 Rows
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleRowsPerPage(30);
            }}
          >
            30 Rows
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default CustomPagination;
