export const SubscriptionIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31376 13.4992C8.48617 12.9657 8.72568 12.4624 9.02233 11.9992H2.25278C1.01076 11.9992 0.00390625 13.0061 0.00390625 14.2481V14.8258C0.00390625 15.7185 0.322425 16.582 0.902186 17.2609C2.46849 19.0952 4.8545 20.0004 8.00036 20.0004C8.60027 20.0004 9.1726 19.9674 9.71693 19.9014C9.32586 19.4767 8.99065 18.9997 8.72294 18.4821C8.48833 18.4943 8.24749 18.5004 8.00036 18.5004C5.26206 18.5004 3.29618 17.7546 2.04287 16.2869C1.69502 15.8795 1.50391 15.3614 1.50391 14.8258V14.2481C1.50391 13.8345 1.83919 13.4992 2.25278 13.4992H8.31376ZM8.00036 0.00390625C10.7618 0.00390625 13.0004 2.24248 13.0004 5.00391C13.0004 7.76533 10.7618 10.0039 8.00036 10.0039C5.23894 10.0039 3.00036 7.76533 3.00036 5.00391C3.00036 2.24248 5.23894 0.00390625 8.00036 0.00390625ZM8.00036 1.50391C6.06737 1.50391 4.50036 3.07091 4.50036 5.00391C4.50036 6.9369 6.06737 8.50391 8.00036 8.50391C9.93336 8.50391 11.5004 6.9369 11.5004 5.00391C11.5004 3.07091 9.93336 1.50391 8.00036 1.50391ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM15.0554 12.4206C14.8806 11.8598 14.1194 11.8598 13.9446 12.4206L13.3876 14.2077H11.5851C11.0194 14.2077 10.7842 14.9623 11.2418 15.3089L12.7001 16.4134L12.1431 18.2004C11.9683 18.7612 12.584 19.2276 13.0417 18.881L14.5 17.7766L15.9583 18.881C16.416 19.2276 17.0317 18.7612 16.8569 18.2004L16.2999 16.4134L17.7582 15.3089C18.2158 14.9623 17.9806 14.2077 17.4149 14.2077H15.6124L15.0554 12.4206Z"
        fill="#5C5959"
      />
    </svg>
  );
};
