import React from "react";

function AdsListIcon() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8C15.1046 8 16 7.10457 16 6C16 4.89543 15.1046 4 14 4C12.8954 4 12 4.89543 12 6C12 7.10457 12.8954 8 14 8ZM11 10C11 9.44772 11.4477 9 12 9H16C16.5523 9 17 9.44772 17 10V10.25C17 11.7688 15.7688 13 14.25 13H13.75C12.2312 13 11 11.7688 11 10.25V10ZM4.25 3C3.55964 3 3 3.55964 3 4.25V7.75C3 8.44036 3.55964 9 4.25 9H8.75C9.44036 9 10 8.44036 10 7.75V4.25C10 3.55964 9.44036 3 8.75 3H4.25ZM3.25 0C1.45507 0 0 1.45507 0 3.25V12.75C0 14.5449 1.45507 16 3.25 16H16.75C18.5449 16 20 14.5449 20 12.75V3.25C20 1.45507 18.5449 0 16.75 0H3.25ZM1.5 3.25C1.5 2.2835 2.2835 1.5 3.25 1.5H16.75C17.7165 1.5 18.5 2.2835 18.5 3.25V12.75C18.5 13.7165 17.7165 14.5 16.75 14.5H3.25C2.2835 14.5 1.5 13.7165 1.5 12.75V3.25Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default AdsListIcon;
