import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";

import CloseIcon from "@mui/icons-material/Close";

import CONST from "constants/generalConstants";
import { ErrorMessage, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectAppUser,
  setClearFilter,
  setDialogClose,
  setUserFilter,
} from "store/slice/appUserSlice";
import { handleChangeStatus, handleChangeSubscription } from "./utils";

interface ValuesProps {
  status: string[];
  subscription: string[];
}

export const StatusFilterDialog = () => {
  const { openDialog, filter } = useAppSelector(selectAppUser);

  const dispatch = useAppDispatch();

  const handleDialogClose = () => dispatch(setDialogClose());

  const handleClearFilter = () => {
    handleDialogClose();
    dispatch(setClearFilter());
  };

  // handle filter submit
  const handleSubmit = ({ status, subscription }: ValuesProps) => {
    dispatch(
      setUserFilter({
        status,
        subscription,
      })
    );

    handleDialogClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openDialog}
      onClose={handleDialogClose}
    >
      <DialogTitle>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#111936",
          }}
        >
          Filter
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{ position: "absolute", top: 18, right: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={{
          status: filter?.status?.length ? filter.status : [],
          subscription: filter?.subscription?.length ? filter.subscription : [],
        }}
        validationSchema={Yup.object().shape({
          status: Yup.array().of(Yup.string()).nullable(),
          subscription: Yup.array().of(Yup.string()).nullable(),
        })}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, setFieldValue }) => (
          <>
            <DialogContent>
              <Divider />

              <Button
                variant="contained"
                onClick={handleClearFilter}
                sx={{
                  mb: 2,
                  cursor: "pointer",
                  padding: "10px 16px !important",
                }}
              >
                Clear filter
              </Button>

              <Form id="filter-app-user">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 2,
                      }}
                    >
                      Status
                    </Typography>

                    <Stack direction="column" spacing={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.status.includes(CONST.ACTIVE)}
                          />
                        }
                        label="Active"
                        name="status"
                        onChange={() =>
                          handleChangeStatus({
                            status: values?.status,
                            setFieldValue,
                            statusType: CONST.ACTIVE,
                          })
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.status.includes(CONST.DISABLED)}
                          />
                        }
                        label="Disabled"
                        name="status"
                        onChange={() =>
                          handleChangeStatus({
                            status: values?.status,
                            setFieldValue,
                            statusType: CONST.DISABLED,
                          })
                        }
                      />
                    </Stack>
                    <ErrorMessage name="status" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                      Subscription
                    </Typography>
                    <Stack direction="column" spacing={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.subscription.includes(
                              CONST.HALF_YEARLY
                            )}
                          />
                        }
                        label="Half Yearly"
                        name="subscription"
                        onChange={() =>
                          handleChangeSubscription({
                            subscription: values?.subscription,
                            setFieldValue,
                            type: CONST.HALF_YEARLY,
                          })
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.subscription.includes(
                              CONST.MONTHLY
                            )}
                          />
                        }
                        label="Monthly"
                        name="subscription"
                        onChange={() =>
                          handleChangeSubscription({
                            subscription: values?.subscription,
                            setFieldValue,
                            type: CONST.MONTHLY,
                          })
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.subscription.includes(
                              CONST.WEEKLY
                            )}
                          />
                        }
                        label="Weekly"
                        name="subscription"
                        onChange={() =>
                          handleChangeSubscription({
                            subscription: values?.subscription,
                            setFieldValue,
                            type: CONST.WEEKLY,
                          })
                        }
                      />
                    </Stack>
                    <ErrorMessage name="subscription" />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <Divider sx={{ my: 2.5 }} />
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                form="filter-app-user"
                type="submit"
                size="large"
                disabled={
                  !values?.subscription.length && !values?.status.length
                }
              >
                Apply
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
