import React from "react";

function SavedSearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M0 2.74902C0 1.23024 1.23122 -0.000976562 2.75 -0.000976562H12.7145C13.5764 -0.000976562 14.4031 0.341433 15.0126 0.950926L17.0481 2.98646C17.6576 3.59595 18 4.4226 18 5.28456V15.249C18 16.7678 16.7688 17.999 15.25 17.999H2.75C1.23122 17.999 0 16.7678 0 15.249V2.74902ZM2.75 1.49902C2.05964 1.49902 1.5 2.05867 1.5 2.74902V15.249C1.5 15.9394 2.05964 16.499 2.75 16.499H3V11.249C3 10.0064 4.00736 8.99902 5.25 8.99902H12.75C13.9926 8.99902 15 10.0064 15 11.249V16.499H15.25C15.9404 16.499 16.5 15.9394 16.5 15.249V5.28456C16.5 4.82043 16.3156 4.37531 15.9874 4.04712L13.9519 2.01159C13.6918 1.75148 13.3582 1.58171 13 1.52247V4.24902C13 5.49166 11.9926 6.49902 10.75 6.49902H6.25C5.00736 6.49902 4 5.49166 4 4.24902V1.49902H2.75ZM13.5 16.499V11.249C13.5 10.8348 13.1642 10.499 12.75 10.499H5.25C4.83579 10.499 4.5 10.8348 4.5 11.249V16.499H13.5ZM5.5 1.49902V4.24902C5.5 4.66324 5.83579 4.99902 6.25 4.99902H10.75C11.1642 4.99902 11.5 4.66324 11.5 4.24902V1.49902H5.5Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default SavedSearchIcon;
