// freight search routes

export const APP_USERS_ROUTE = "/app-user";
export const APP_USER_DETAILS_ROUTE = "/app-user/:id";
export const EDIT_APP_USER_ROUTE = "/app-user/edit/:id";
export const ADMIN_USERS_ROUTE = "/admin-user";
export const ADD_ADMIN_USER_ROUTE = "/admin-user/add";
export const ADMIN_USER_PROFILE_SETTINGS_ROUTE = "/admin-user/settings/:id";
export const ADMIN_USER_DETAILS_ROUTE = "/admin-user/:id";
export const EDIT_ADMIN_USER_ROUTE = "/admin-user/edit/:id";
export const CODE_VERIFICATION_ROUTE = "/verify/otp";
export const DASHBOARD_ROUTE = "/dashboard";
export const EMAILS_ROUTE = "/email";
export const REPORTS_ROUTE = "/reports-users";
export const REPORTS_DETAILS_ROUTE = "/reports-users/:id";
export const REPORTS_ADS_ROUTE = "/reports-ads";
export const REPORTS_ADS_DETAILS_ROUTE = "/reports-ads/:id";
export const ERROR_ROUTE = "/error";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const LOGIN_ROUTE = "/login";
export const PAGE_MANAGEMENT_ROUTE = "/page-management";
export const MANAGE_CATEGORIES_ROUTE = "/manage-categories";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const CREATE_PASSWORD_ROUTE = "/create-password";
export const SAVED_SEARCHES = "/saved-searches";
export const ADS_LIST_ROUTE = "/ads";
export const APP_USER_AD_DETAILS_ROUTE = "/app-user/:id/ad/:adId";
export const APP_USER_SAVED_AD_DETAILS_ROUTE = "/app-user/:id/saved-ad/:adId";
export const AD_DETAILS_ROUTE = "/ads/:adId";
export const EDIT_AD_ROUTE = "/ads/edit/:adId";
export const ADMIN_CHANGE_PASSWORD = "/admin/change-password";
export const LOGGED_IN_ADMIN_PROFILE_ROUTE = "/admin/profile";
export const SAVED_SEARCH_ROUTE = "/saved-searches";
export const SIMILAR_SAVED_SEARCH_ROUTE = "/similar-saved-search";
export const SEARCH_AD_LIST = "/saved-search-ad";
export const SEARCH_AD_LIST_ROUTE = "/saved-search-ad/:id";
export const GRACE_AND_EXPIRY_ROUTE = "/grace-and-expiry";
export const SUBSCRIPTION_ROUTE = "/subscription";
export const SUBSCRIPTION_DETAILS_ROUTE = "/subscription/:id";
