import React from "react";

function AppUserIcon() {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7545 11.9992C14.9966 11.9992 16.0034 13.0061 16.0034 14.2481V14.8235C16.0034 15.7178 15.6838 16.5826 15.1023 17.262C13.5329 19.0955 11.1457 20.0004 8.00036 20.0004C4.8545 20.0004 2.46849 19.0952 0.902186 17.2609C0.322425 16.582 0.00390625 15.7185 0.00390625 14.8258V14.2481C0.00390625 13.0061 1.01076 11.9992 2.25278 11.9992H13.7545ZM13.7545 13.4992H2.25278C1.83919 13.4992 1.50391 13.8345 1.50391 14.2481V14.8258C1.50391 15.3614 1.69502 15.8795 2.04287 16.2869C3.29618 17.7546 5.26206 18.5004 8.00036 18.5004C10.7387 18.5004 12.7063 17.7545 13.9627 16.2866C14.3117 15.879 14.5034 15.3601 14.5034 14.8235V14.2481C14.5034 13.8345 14.1681 13.4992 13.7545 13.4992ZM8.00036 0.00390625C10.7618 0.00390625 13.0004 2.24248 13.0004 5.00391C13.0004 7.76533 10.7618 10.0039 8.00036 10.0039C5.23894 10.0039 3.00036 7.76533 3.00036 5.00391C3.00036 2.24248 5.23894 0.00390625 8.00036 0.00390625ZM8.00036 1.50391C6.06737 1.50391 4.50036 3.07091 4.50036 5.00391C4.50036 6.9369 6.06737 8.50391 8.00036 8.50391C9.93336 8.50391 11.5004 6.9369 11.5004 5.00391C11.5004 3.07091 9.93336 1.50391 8.00036 1.50391Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default AppUserIcon;
