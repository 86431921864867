import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import EditorRoutes from "./EditorRoutes";
import DashboardRoute from "./DashboardRoute";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    LoginRoutes,
    AuthenticationRoutes,
    DashboardRoute,
    EditorRoutes,
  ]);
}
