import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

// ui
import ButtonWithIcon from "ui-component/extended/ButtonWithIcon";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "ui-component/Loader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "ui-component/CustomPagination";
import { Typography, Button } from "@mui/material";
import { StyledBarLoader } from "styles/globalStyles";
import { LinearProgress } from "@mui/material";
import AppEyeIcon from "ui-component/Icons/AppEyeIcon";

// project imports
import {
  ADS_LIST_ROUTE,
  EMAILS_ROUTE,
  REPORTS_ADS_ROUTE,
  REPORTS_ROUTE,
  SEARCH_AD_LIST,
  SEARCH_AD_LIST_ROUTE,
} from "constants/routes";
import {
  GET_MANAGE_CATEGORIES,
  GET_SAVED_SEARCH_LIST,
  GET_SIMILAR_SAVED_SEARCH_LIST,
} from "schemas/query";
import { formatDate } from "utils/date-format";

// redux
import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { DefaultEditIcon } from "ui-component/Icons/DefaultEditIcon";

import {
  selectSimilarSavedSearch,
  setLimit,
  setPage,
} from "store/slice/similarSavedSearchSlice";

import NoItemFound from "ui-component/NoItemFound";

interface SearchDetail {
  fullAddress: string;
}

interface SavedSearch {
  _id: string;
  adType: string;
  truckSize: [string];
  truckType: [string];
  firstName: string;
  lastName: string;
  searchDetail: SearchDetail;
}

const SimilarSavedSearchTable = () => {
  const { page, limit, searchText, filter } = useAppSelector(
    selectSimilarSavedSearch
  );
  const [savedSearch, setSavedSearch] = useState<SavedSearch[]>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const adType = searchParams.get("adType");
  const truckType = searchParams.get("truckType");
  const truckSize = searchParams.get("truckSize");

  const { data, error, loading } = useQuery(GET_SIMILAR_SAVED_SEARCH_LIST, {
    variables: {
      body: {
        page: page,
        limit: limit,
        adType: adType === "null" ? null : adType,
        truckType: truckType === "null" ? null : truckType?.split(","),
        truckSize: truckSize === "null" ? null : truckSize?.split(","),
        // filter: filter.length ? filter : null,
        // fromDate: fromDate,
        // toDate: toDate,
      },
    },
  });

  if (error) {
    dispatch(
      snackbarOpen({ open: true, severity: "error", message: error.message })
    );
  }

  useEffect(() => {
    if (data) {
      setSavedSearch(data.listSimilarSavedSearch.items);
    }
  }, [data]);

  const handleClick = (id: string) => {
    navigate(`${SEARCH_AD_LIST}/${id}`);
  };

  return (
    <>
      <StyledBarLoader>
        {loading && <LinearProgress color='inherit' />}
      </StyledBarLoader>
      {data?.listSimilarSavedSearch?.meta?.totalItems <= 0 && !loading ? (
        <NoItemFound />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label='simple table'
            className='customDataTable'
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Adtype
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Truck type
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Truck size
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Saved by
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Save title
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography variant='menuCaption' noWrap>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.listSimilarSavedSearch?.items.map((item: SavedSearch) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.adType ? item.adType : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.truckType.length ? item.truckType : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.truckSize.length ? item.truckSize : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.firstName}
                      {item.lastName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      align='left'
                      variant='subMenuCaption'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.searchDetail.fullAddress}
                    </Typography>
                  </TableCell>

                  <TableCell align='left'>
                    <Button
                      variant='outlined'
                      startIcon={<AppEyeIcon />}
                      onClick={() => handleClick(item._id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {data?.listSimilarSavedSearch?.meta?.totalItems > 0 && (
        <CustomPagination
          totalPages={data?.listSimilarSavedSearch?.meta?.totalPages}
          setPage={setPage}
          setLimit={setLimit}
          currentPage={data?.listSimilarSavedSearch?.meta?.currentPage}
          itemCount={data?.listSimilarSavedSearch?.meta?.itemCount}
        />
      )}
    </>
  );
};

export default SimilarSavedSearchTable;
