import { useState } from "react";

//ui
import { Grid, Stack, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import Button from "@mui/material/Button";

//project imports
import { gridSpacing } from "store/constant";

import SearchBar from "ui-component/SearchBar";
import { useAppDispatch, useAppSelector } from "store/hooks";

import {
  selectSavedSearch,
  setSearchText,
  setTruckType,
  setTruckSize,
  setAdTypes,
} from "store/slice/savedSearchSlice";
import { StatusFilterDialog } from "./searchFilterDialog";
import SavedSearchTable from "./saved-search-table";

// ==============================|| USER LIST STYLE 1 ||============================== //

const SavedSearch = () => {
  const dispatch = useAppDispatch();
  const [statusFilterOpen, setStatusFilterOpen] = useState<boolean>(false);

  const { searchText, truckType, truckSize, adType } = useAppSelector(
    selectSavedSearch
  );

  const handleStatusFilterOpen = () => {
    setStatusFilterOpen(true);
  };

  const handleStatusFilterClose = (filter?: "active" | "disabled") => {
    setStatusFilterOpen(false);
    // if (filter) {
    //   dispatch(setFilter(filter || ""));
    //   // perform search/filter action with selected filter here
    // }
  };

  const handleRemoveFilter = () => {
    dispatch(setTruckType([]));
    dispatch(setTruckSize([]));
    dispatch(setAdTypes([]));
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#fff",
            padding: "30px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item md={6}>
              <Typography variant="h3" gutterBottom>
                Saved Search
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                {(truckType.length != 0 || truckSize.length != 0,
                adType.length != 0) && (
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={handleRemoveFilter}
                    className="fixed-width"
                  >
                    Clear filter
                  </Button>
                )}
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="fixed-width"
                  onClick={handleStatusFilterOpen}
                >
                  Filter
                </Button>
                <SearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StatusFilterDialog
            open={statusFilterOpen}
            onClose={handleStatusFilterClose}
          />
          <SavedSearchTable />
        </Grid>
      </Grid>
    </>
    // <MainCard sx={{ pt: 1.5 }} content={false}>
    //   <ReportsTable />
    // </MainCard>
  );
};

export default SavedSearch;
