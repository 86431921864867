import React from "react";

function Icon({ width = "21px", height = "20px", fill = "#3d4853" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2514 2.49805C19.9844 2.49805 21.4007 3.85437 21.4963 5.56352L21.5014 5.74794L21.5008 17.7518C21.5006 19.5466 20.0456 21.0017 18.2507 21.0017H6.25006C4.51701 21.0017 3.10076 19.6454 3.00514 17.9362L3 17.7518L3.0006 5.74796C3.00081 3.95313 4.45582 2.49805 6.25065 2.49805H18.2514ZM11.5 9.49805L4.5 9.49905L4.5001 17.7517L4.50728 17.9124C4.58839 18.8036 5.33765 19.5017 6.25 19.5017L11.5 19.501V9.49805ZM20 15.498L13 15.499V19.501L18.2506 19.5017C19.2172 19.5017 20.0007 18.7181 20.0008 17.7516L20 15.498ZM18.2514 3.99805L13 3.99705V13.999L20 13.998L20.0013 5.74802L19.9941 5.58729C19.913 4.69609 19.1638 3.99805 18.2514 3.99805ZM11.5 3.99705L6.25073 3.99805L6.10723 4.00386C5.20785 4.07693 4.50071 4.82994 4.5006 5.74808L4.5 7.99905L11.5 7.99805V3.99705Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default Icon;
