// third-party
import { FormattedMessage } from "react-intl";

//project imports
import AppUserIcon from "ui-component/Icons/AppUserIcon";
import AdminUserIcon from "ui-component/Icons/AdminUserIcon";
import {
  ADMIN_USERS_ROUTE,
  APP_USERS_ROUTE,
  SUBSCRIPTION_ROUTE,
} from "constants/routes";
import PackagesIcon from "ui-component/Icons/PackagesIcon";
import { SubscriptionIcon } from "ui-component/Icons/SubscriptionIcon";

const subscriptionManagement = {
  id: "subscription-management",
  title: <FormattedMessage id="subscription-management" />,
  type: "group",
  children: [
    // {
    //   id: "packages",
    //   title: <FormattedMessage id="packages" />,
    //   type: "item",
    //   icon: PackagesIcon,
    //   /* url: , */
    //   breadcrumbs: true,
    // },
    {
      id: "account-subscriptions",
      title: <FormattedMessage id="account subscriptions" />,
      type: "item",
      url: SUBSCRIPTION_ROUTE,
      icon: SubscriptionIcon,
      breadcrumbs: true,
    },
  ],
};

export default subscriptionManagement;
