import { useAppSelector } from "store/hooks";

import CONST from "constants/generalConstants";
import { selectUser } from "store/slice/userSlice";

const useSuperUserPermission = () => {
  const { role } = useAppSelector(selectUser);

  if (role === CONST.EDITOR || role === CONST.VIEWER) return false;

  if (role === CONST.SUPER) return true;
};

export default useSuperUserPermission;
