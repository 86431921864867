// material-ui
import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// assets
import {
  REPORTS_ADS_ROUTE,
  REPORTS_ROUTE,
  SUBSCRIPTION_ROUTE,
} from "constants/routes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SET_NOTIFICATION_READ } from "schemas/mutation";
import { LIST_ADMIN_NOTIFICATION } from "schemas/query";
import { useAppDispatch } from "store/hooks";
import {
  decreaseNotificationCount,
  toggleNotificationWindow,
} from "store/slice/notificationSlice";
import { snackbarOpen } from "store/slice/snackbarSlice";
import { stringAvatar } from "utils/string.avatar";
import { NotificationListItemProps } from "./notification.types";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 8,
  "&:hover": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
  },
  borderRadius: "10px",
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
  const [notifications, setNotifications] = useState<
    Array<NotificationListItemProps>
  >([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { error, loading: dataLoading, data, refetch } = useQuery(
    LIST_ADMIN_NOTIFICATION,
    {
      variables: {
        body: {
          limit: 5,
          page,
        },
      },
    }
  );

  // hasRead mutation
  const [setNotificationRead] = useMutation(SET_NOTIFICATION_READ);

  let observer = useRef<IntersectionObserver>();

  const lastNotificationElementRef = useCallback(
    (node) => {
      if (dataLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore, dataLoading]
  );

  // handle notification click
  const handleNotificationClick = async (
    notificationId: string,
    notificationType: string,
    hasRead: boolean,
    id?: string
  ) => {
    dispatch(toggleNotificationWindow(false));
    if (!hasRead) {
      dispatch(decreaseNotificationCount());

      try {
        await setNotificationRead({
          variables: {
            body: {
              notificationId,
            },
          },
        });

        refetch();
      } catch (error) {
        console.log("Cannot set notification read.");
      }
    }

    if (notificationType === "report_ad") {
      navigate(`${REPORTS_ADS_ROUTE}/${id}`);
    }

    if (notificationType === "report_user") {
      navigate(`${REPORTS_ROUTE}/${id}`);
    }

    if (
      notificationType === "subscription_expired" ||
      notificationType === "subscription_purchased"
    ) {
      navigate(`${SUBSCRIPTION_ROUTE}/${id}`);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: error.message,
        })
      );
    }

    if (data) {
      setNotifications((prev) => {
        return [...prev, ...data?.listAdminNotifications?.items].filter(
          (value: any, index: any, self: any) => {
            return (
              index ===
              self.findIndex((t: any) => {
                return t._id === value._id;
              })
            );
          }
        );
      });

      setHasMore(page < data?.listAdminNotifications?.meta.totalPages);
    }
  }, [error, data, dispatch, page]);

  return (
    <List
      sx={{
        width: "100%",
        maxHeight: 400,
        overflowY: "scroll !important",
        py: 0,
        borderRadius: "10px",
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
        },
        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
        "& .list-container": {
          pl: "72px",
        },
      }}
    >
      {notifications.map((item, index) => {
        if (notifications?.length === index + 1) {
          return (
            <ListItemWrapper
              key={item?._id}
              sx={{
                background: item?.hasRead ? "#ffff" : "#F6F4EF",
                mb: "3px",
              }}
              onClick={() =>
                item?.notificationMetaData?.reportId &&
                handleNotificationClick(
                  item._id,
                  item.type,
                  item.hasRead,
                  item.notificationMetaData?.reportId
                )
              }
              ref={lastNotificationElementRef}
            >
              <ListItem
                alignItems="center"
                sx={{ gap: "10px", paddingInline: "8px !important" }}
              >
                <ListItemAvatar>
                  {item?.profileImage && (
                    <Avatar
                      src={item?.profileImage}
                      sx={{ height: 50, width: 50 }}
                    />
                  )}

                  {!item?.profileImage && item?.userName && (
                    <Avatar
                      {...stringAvatar(item?.userName)}
                      sx={{ height: 50, width: 50 }}
                    />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#2B2929",
                      }}
                    >
                      {item?.userName}
                    </Typography>
                  }
                />
              </ListItem>
              <Grid container direction="column" className="list-container">
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ color: "#040303", fontSize: "14px", fontWeight: 500 }}
                  >
                    {item?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ color: "#757575", fontSize: "12px" }}
                  >
                    {item?.relativeTime}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemWrapper>
          );
        } else {
          return (
            <ListItemWrapper
              key={item?._id}
              sx={{
                background: item?.hasRead ? "#ffff" : "#F6F4EF",
                mb: "3px",
                borderRadius: "6px",
              }}
              onClick={() =>
                item?.notificationMetaData?.reportId &&
                handleNotificationClick(
                  item._id,
                  item.type,
                  item.hasRead,
                  item.notificationMetaData?.reportId
                )
              }
            >
              <ListItem
                alignItems="center"
                sx={{ gap: "10px", paddingInline: "8px !important" }}
              >
                <ListItemAvatar>
                  {item?.profileImage && (
                    <Avatar
                      src={item?.profileImage}
                      sx={{ height: 50, width: 50 }}
                    />
                  )}

                  {!item?.profileImage && (
                    <Avatar
                      {...stringAvatar(item?.userName)}
                      sx={{ height: 50, width: 50 }}
                    />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#2B2929",
                      }}
                    >
                      {item?.userName}
                    </Typography>
                  }
                />
              </ListItem>
              <Grid container direction="column" className="list-container">
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ color: "#040303", fontSize: "14px", fontWeight: 500 }}
                  >
                    {item?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ color: "#757575", fontSize: "12px" }}
                  >
                    {item?.relativeTime}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemWrapper>
          );
        }
      })}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!notifications?.length && !dataLoading && (
          <p>No Notification Available</p>
        )}

        {dataLoading && <CircularProgress color="secondary" />}
      </Box>
    </List>
  );
};

export default NotificationList;
