import { useState } from "react";

//ui
import { Grid, Stack, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import Button from "@mui/material/Button";

//project imports
import { gridSpacing } from "store/constant";

import SearchBar from "ui-component/SearchBar";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { selectSavedSearch, setSearchText } from "store/slice/savedSearchSlice";
import { StatusFilterDialog } from "./searchFilterDialog";

import SimilarSavedSearchTable from "./similar-saved-search-table";

// ==============================|| USER LIST STYLE 1 ||============================== //

const SimilarSavedSearch = () => {
  const dispatch = useAppDispatch();
  const [statusFilterOpen, setStatusFilterOpen] = useState<boolean>(false);

  const { searchText } = useAppSelector(selectSavedSearch);

  const handleStatusFilterOpen = () => {
    setStatusFilterOpen(true);
  };

  const handleStatusFilterClose = (filter?: "active" | "disabled") => {
    setStatusFilterOpen(false);
    // if (filter) {
    //   dispatch(setFilter(filter || ""));
    //   // perform search/filter action with selected filter here
    // }
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#fff",
            padding: "30px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item md={8}>
              <Typography variant="h3" gutterBottom>
                Saved Search
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                {/* <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="fixed-width"
                  onClick={handleStatusFilterOpen}
                >
                  Filter
                </Button>
                <SearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                /> */}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StatusFilterDialog
            open={statusFilterOpen}
            onClose={handleStatusFilterClose}
          />
          <SimilarSavedSearchTable />
        </Grid>
      </Grid>
    </>
    // <MainCard sx={{ pt: 1.5 }} content={false}>
    //   <ReportsTable />
    // </MainCard>
  );
};

export default SimilarSavedSearch;
