import { useMutation, useQuery } from "@apollo/client";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import dayjs, { Dayjs } from "dayjs";
import { ErrorMessage, Field, Form, Formik } from "formik";

import CONST from "constants/generalConstants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";
// date picker

import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ADS_LIST_ROUTE } from "constants/routes";
import { EDIT_AD, GET_UPLOAD_URL } from "schemas/mutation";
import { GET_AD_DETAILS, GET_TRUCK_DETAILS } from "schemas/query";
import CustomMultipleSelectCheckmarks from "ui-component/CustomMultipleSelectCheckmarks";
import Loader from "ui-component/Loader";
import TomTomAutoComplete from "ui-component/TomTomAutoComplete";
import MainCard from "ui-component/cards/MainCard";
import { formatAdPhotos } from "utils/formatAdPhotos";
import { getTruckSizes, getTruckTypes } from "../utils/truck.details.utils";
import MyTextArea from "./MyTextArea";
import { editAdValidationSchema } from "./ad.edit.validation.schema";
import TrashIcon from "ui-component/Icons/TrashIcon";

const validateFiles = (files: any) => {
  const errors: any = [];

  Array.from(files).forEach((file: any) => {
    if (file.size > CONST.MAX_FILE_SIZE) {
      errors.push(`${file.name} is too large (max size is 5 MB)`);
    }

    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      errors.push(
        `${file.name} is not a valid file type (only PNG, JPG and JPEG are allowed)`
      );
    }
  });

  return errors;
};

// @ts-ignore
const FileUpload = ({ field, form, ...props }): any => {
  const { name } = field;

  const { value } = field;

  const handleChange = (event: any) => {
    const files = event.currentTarget.files;
    const errors = validateFiles(files);

    if (errors.length) {
      form.setFieldError(name, errors);
    } else {
      const urls = Array.from(files).map((file: any) =>
        URL.createObjectURL(file)
      );
      const newFiles = Array.from(files).map((file: any) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      form.setFieldValue(name, [...value, ...newFiles]);
      form.setFieldError(name, null);
    }
  };

  const handleRemove = (fileToRemove: any) => {
    const updatedFiles = value.filter((file: any) => file !== fileToRemove);
    form.setFieldValue(name, updatedFiles);
  };

  return (
    <>
      <Stack
        useFlexGap
        flexWrap='wrap'
        direction='row'
        sx={{ gap: "10px", mt: 1 }}
      >
        {value.map((file: any, index: number) => (
          <Box key={file.url} sx={{ position: "relative" }}>
            {file.url && (
              <img
                src={file.url}
                alt='Preview'
                style={{
                  maxWidth: "200px",
                  height: "200px",
                  objectFit: "cover",
                }}
              />
            )}
            <DeleteOutlineOutlinedIcon
              onClick={() => handleRemove(file)}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "5px",
                top: "5px",
                background: "#FFE600",
              }}
            />
          </Box>
        ))}

        {value.length < 4 && (
          <IconButton
            aria-label='upload picture'
            component='label'
            sx={{
              height: "200px",
              width: "200px",
              borderRadius: 2,
              backgroundColor: "#FBF7C8",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#FBF7C8",
              },
            }}
          >
            <input
              hidden
              accept='image/*'
              multiple
              type='file'
              onChange={handleChange}
              {...props}
            />
            <AddOutlinedIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );
};
const EditAd = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const theme = useTheme();

  const capitalizeInput = { input: { textTransform: "capitalize" } };
  // dispatch
  const dispatch = useAppDispatch();
  // navigate
  const navigate = useNavigate();
  // location payload
  const { adId } = useParams();

  // get user details
  const {
    data,
    error: adDetailError,
    loading,
    refetch: refetchAdDetails,
  } = useQuery(GET_AD_DETAILS, {
    variables: {
      body: {
        adMongoId: adId,
      },
    },
  });

  // upload url
  const [getUploadURL, { loading: uploadURLLoading }] = useMutation(
    GET_UPLOAD_URL
  );

  // edit ad
  const [editAd, { loading: editLoading }] = useMutation(EDIT_AD);

  const {
    data: truckDetails,
    error: truckDetailsError,
    loading: truckDetailsLoading,
  } = useQuery(GET_TRUCK_DETAILS);

  const handleSubmit = async (values: any) => {
    let fileKeys: string[] = [];

    setLoadingState(true);

    values?.files?.length &&
      (await Promise.all(
        values?.files.map(
          async (item: {
            file: File | null;
            url: string | null;
            key: string | null;
          }) => {
            if (item.file) {
              let uploadLink: string = "";
              const extension = item?.file?.name.split(".").pop();
              let s3Key: string = "";

              try {
                const { data } = await getUploadURL({
                  variables: {
                    body: {
                      contentType: item.file.type,
                      extension,
                      prefix: CONST.AD_PHOTOS_PREFIX,
                    },
                  },
                });

                s3Key = data?.getUploadUrl.key;

                uploadLink = data?.getUploadUrl.url;
              } catch (error: any) {
                dispatch(
                  snackbarOpen({
                    open: true,
                    severity: "error",
                    message: error.message,
                  })
                );
              }

              try {
                // setLoadingState(true);
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", item.file.type);

                if (uploadLink.length) {
                  await fetch(uploadLink, {
                    method: "PUT",
                    headers: myHeaders,
                    body: item.file,
                    redirect: "follow",
                  });
                }

                setLoadingState(false);
              } catch (error) {
                // setLoadingState(false);
                console.log("Cannot post picture", error);
              }

              fileKeys.push(s3Key);

              return;
            }

            item?.key && fileKeys.push(item?.key);
          }
        )
      ));

    try {
      // update ad
      const { data: editAdData } = await editAd({
        variables: {
          body: {
            adMongoId: adId,
            dateAvailableFrom: dayjs(values?.dateAvailableFrom).format(
              "YYYY/MM/DD"
            ),
            dateAvailableTo: dayjs(values?.dateAvailableTo).format(
              "YYYY/MM/DD"
            ),
            description: values?.description,
            dropOffDetails: {
              coordinates: [
                +values?.dropOffDetails?.coordinates[1],
                +values?.dropOffDetails?.coordinates[0],
              ],
              fullAddress: values?.dropOffDetails?.fullAddress,
              state: values?.dropOffDetails?.state,
              town: values?.dropOffDetails?.town,
              zip: values?.dropOffDetails?.zip,
            },
            isUrgent: values?.isUrgent === "true" ? true : false,
            photos: fileKeys,
            pickUpDetails: {
              coordinates: [
                +values?.pickUpDetails?.coordinates[1],
                +values?.pickUpDetails?.coordinates[0],
              ],
              fullAddress: values?.pickUpDetails?.fullAddress,
              state: values?.pickUpDetails?.state,
              town: values?.pickUpDetails?.town,
              zip: values?.pickUpDetails?.zip,
            },
            truckSize: values?.truckSize,
            truckType: values?.truckType,
            type: values?.type,
          },
        },
      });

      dispatch(
        snackbarOpen({
          open: true,
          severity: "success",
          message: editAdData?.editAd?.status,
        })
      );
      setLoadingState(false);

      refetchAdDetails();
    } catch (error: any) {
      dispatch(
        snackbarOpen({ open: true, severity: "error", message: error.message })
      );
    }
  };

  useEffect(() => {
    if (adDetailError || truckDetailsError) {
      dispatch(
        snackbarOpen({
          open: true,
          severity: "error",
          message: adDetailError?.message || truckDetailsError?.message,
        })
      );
    }
  }, [data, dispatch, adDetailError, truckDetailsError]);

  if (
    loading ||
    truckDetailsLoading ||
    uploadURLLoading ||
    editLoading ||
    loadingState
  ) {
    return <Loader />;
  }

  return (
    <>
      <MainCard>
        <Typography variant='h4'>Edit Ad Details</Typography>
        <Grid container sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                adId: data?.getAdDetails?.adId || "",
                postedBy: data?.getAdDetails?.postedBy || "",
                type: data?.getAdDetails?.type,
                datePosted: data?.getAdDetails?.createdAt || "",
                isUrgent: data?.getAdDetails?.isUrgent,
                pickUpDetails: {
                  fullAddress:
                    data?.getAdDetails?.pickUpDetails?.fullAddress || "",
                  state: data?.getAdDetails?.pickUpDetails?.state || "",
                  town: data?.getAdDetails?.pickUpDetails?.state || "",
                  zip: data?.getAdDetails?.pickUpDetails?.zip || "",
                  coordinates:
                    data?.getAdDetails?.pickUpDetails?.coordinates || [],
                },
                dropOffDetails: {
                  fullAddress:
                    data?.getAdDetails?.dropOffDetails?.fullAddress || "",
                  state: data?.getAdDetails?.dropOffDetails?.state || "",
                  town: data?.getAdDetails?.dropOffDetails?.state || "",
                  zip: data?.getAdDetails?.dropOffDetails?.zip || "",
                  coordinates:
                    data?.getAdDetails?.dropOffDetails?.coordinates || [],
                },
                dateAvailableFrom: data?.getAdDetails?.dateAvailableFrom || "",
                dateAvailableTo: data?.getAdDetails?.dateAvailableTo || "",
                truckType: data?.getAdDetails?.truckType || "",
                truckSize: data?.getAdDetails?.truckSize || "",
                description: data?.getAdDetails?.description || "",
                files: formatAdPhotos(data?.getAdDetails?.photos) || [],
              }}
              validationSchema={editAdValidationSchema}
              onSubmit={async (values) => {
                await handleSubmit(values);
              }}
            >
              {({
                handleSubmit,
                setFieldValue,
                getFieldProps,
                touched,
                errors,
                values,
                submitForm,
              }) => (
                <>
                  <Form onSubmit={handleSubmit} id='edit-ad-form'>
                    <Grid container alignItems='stretch'>
                      <Grid item md={6}>
                        <Stack
                          direction='column'
                          justifyContent='flex-start'
                          alignItems='stretch'
                          spacing={0.2}
                        >
                          <Box>
                            <FormControl
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor='outline-adornment-adID'>
                                AD ID
                              </InputLabel>
                              <FilledInput
                                fullWidth
                                placeholder='Ad Id'
                                {...getFieldProps("adId")}
                                inputProps={{
                                  readOnly: true,
                                }}
                                className='input-border-dark'
                                sx={capitalizeInput}
                              />
                              {touched.adId && errors.adId && (
                                <FormHelperText error id='adId-error'>
                                  {errors.adId}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>

                          <Box>
                            <FormControl
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor='outline-adornment-first-name'>
                                Posted By
                              </InputLabel>
                              <FilledInput
                                fullWidth
                                placeholder='Posted By'
                                {...getFieldProps("postedBy")}
                                inputProps={{
                                  readOnly: true,
                                }}
                                className='input-border-dark'
                                sx={capitalizeInput}
                              />
                              {touched.postedBy && errors.postedBy && (
                                <FormHelperText error id='postedBy-error'>
                                  {errors.postedBy}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>

                          <Box>
                            <FormControl
                              id='ad-details-form'
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel sx={{ top: "16px !important" }}>
                                AD Type
                              </InputLabel>
                              <Select
                                inputProps={{
                                  sx: {
                                    color: "#2a2a2a",
                                  },
                                }}
                                value={values?.type}
                                label='AD Type'
                                onChange={(e: SelectChangeEvent) => {
                                  setFieldValue("type", e.target.value);
                                }}
                              >
                                <MenuItem value={CONST.FREIGHT}>
                                  Freight
                                </MenuItem>
                                <MenuItem value={CONST.CARRIER}>
                                  Carrier
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                          <Box>
                            <FormControl
                              id='ad-details-form'
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor='outline-adornment-first-name'>
                                Date Posted
                              </InputLabel>
                              <FilledInput
                                fullWidth
                                placeholder='Date Posted'
                                {...getFieldProps("datePosted")}
                                inputProps={{
                                  readOnly: true,
                                }}
                                className='input-border-dark'
                                sx={capitalizeInput}
                              />
                              {touched.datePosted && errors.datePosted && (
                                <FormHelperText error id='postedBy-error'>
                                  {errors.datePosted}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>

                          <Box>
                            <TomTomAutoComplete
                              setFieldValue={setFieldValue}
                              fullAddress={values?.pickUpDetails?.fullAddress}
                              label='Pick-Up Location'
                              fieldName='pickUpDetails'
                            />

                            {touched?.pickUpDetails?.fullAddress &&
                              errors?.pickUpDetails?.fullAddress && (
                                <FormHelperText error id='pickUpDetails-error'>
                                  {errors?.pickUpDetails?.fullAddress}
                                </FormHelperText>
                              )}
                          </Box>

                          <Box>
                            <TomTomAutoComplete
                              setFieldValue={setFieldValue}
                              fullAddress={values?.dropOffDetails?.fullAddress}
                              label='Drop-Off Location'
                              fieldName='dropOffDetails'
                            />

                            {touched?.dropOffDetails?.fullAddress &&
                              errors?.dropOffDetails?.fullAddress && (
                                <FormHelperText error id='dropOffDetails-error'>
                                  {errors?.dropOffDetails?.fullAddress}
                                </FormHelperText>
                              )}
                          </Box>

                          <Box>
                            <FormControl
                              sx={{
                                ...theme.typography.customInput,
                                width: "100%",
                              }}
                              id='ad-details-form'
                            >
                              <Grid item md={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDatePicker
                                    sx={{
                                      width: "100%",
                                      ".MuiInputBase-input": {
                                        color: "#2a2a2a",
                                      },
                                    }}
                                    label='Date available from'
                                    className='datepicker-field'
                                    value={
                                      values?.dateAvailableFrom
                                        ? dayjs(values?.dateAvailableFrom)
                                        : null
                                    }
                                    maxDate={
                                      values?.dateAvailableTo
                                        ? dayjs(
                                            values.dateAvailableTo
                                          ).subtract(1, "d")
                                        : dayjs()
                                    }
                                    onChange={(value: Dayjs | null) =>
                                      setFieldValue(
                                        "dateAvailableFrom",
                                        dayjs(value)
                                      )
                                    }
                                    format='DD-MM-YYYY'
                                  />

                                  <ErrorMessage name='dateAvailableFrom' />
                                </LocalizationProvider>
                              </Grid>
                            </FormControl>
                          </Box>
                          <Box>
                            <FormControl
                              fullWidth
                              sx={{
                                ...theme.typography.customInput,
                                width: "100%",
                              }}
                              id='ad-details-form'
                            >
                              <Grid item md={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDatePicker
                                    sx={{
                                      width: "100%",
                                      ".MuiInputBase-input": {
                                        color: "#2a2a2a",
                                      },
                                    }}
                                    label='Date available to'
                                    className='datepicker-field'
                                    value={dayjs(values?.dateAvailableTo)}
                                    onChange={(value: Dayjs | null) => {
                                      setFieldValue(
                                        "dateAvailableTo",
                                        dayjs(value)
                                      );
                                    }}
                                    minDate={
                                      values?.dateAvailableFrom
                                        ? dayjs(values?.dateAvailableFrom).add(
                                            1,
                                            "d"
                                          )
                                        : dayjs()
                                    }
                                    format='DD-MM-YYYY'
                                  />

                                  <ErrorMessage name='dateAvailableTo' />
                                </LocalizationProvider>
                              </Grid>
                            </FormControl>
                          </Box>

                          <Box>
                            <FormControl
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                              id='ad-details-form'
                            >
                              <InputLabel sx={{ top: "16px !important" }}>
                                Urgency
                              </InputLabel>
                              <Select
                                inputProps={{
                                  sx: {
                                    color: "#2a2a2a",
                                  },
                                }}
                                value={values?.isUrgent}
                                label='Urgency'
                                onChange={(e: SelectChangeEvent) => {
                                  setFieldValue("isUrgent", e.target.value);
                                }}
                              >
                                <MenuItem value={"true"}>Urgent</MenuItem>
                                <MenuItem value={"false"}>Non-urgent</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                          <Box
                            sx={{
                              mt: "8px !important",
                              mb: "12px !important",
                              width: "100%",
                            }}
                          >
                            <CustomMultipleSelectCheckmarks
                              menus={getTruckTypes(
                                truckDetails?.getTruckType?.value,
                                data?.getAdDetails?.truckType
                              )}
                              setFieldValue={setFieldValue}
                              label='Truck Type'
                              fieldName='truckType'
                              checkedValues={data?.getAdDetails?.truckType}
                            />

                            {touched?.truckType && errors?.truckType && (
                              <FormHelperText error id='truckType-error'>
                                {errors?.truckType}
                              </FormHelperText>
                            )}
                          </Box>

                          <Box>
                            <CustomMultipleSelectCheckmarks
                              menus={getTruckSizes(
                                truckDetails?.getTruckSize.value,
                                data?.getAdDetails?.truckSize
                              )}
                              setFieldValue={setFieldValue}
                              label='Truck Size'
                              fieldName='truckSize'
                              checkedValues={data?.getAdDetails?.truckSize}
                            />

                            {touched?.truckSize && errors?.truckSize && (
                              <FormHelperText error id='truckSize-error'>
                                {errors?.truckSize}
                              </FormHelperText>
                            )}
                          </Box>

                          <Box>
                            <FormControl
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                            >
                              <MyTextArea
                                id='outline-adornment-about'
                                placeholder='Description'
                                label='Description'
                                {...getFieldProps("description")}
                                className='input-border-dark'
                              />
                              {touched.description && errors.description && (
                                <FormHelperText error id='postedBy-error'>
                                  {errors.description}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>

                          <Box>
                            <Typography
                              sx={{ textTransform: "capitalize" }}
                            >{`${values.type} photos`}</Typography>

                            <Grid>
                              <Field name='files' component={FileUpload} />
                              {typeof errors.files === "string" ? (
                                <p> {errors.files}</p>
                              ) : (
                                <p>
                                  {errors?.files?.length && errors.files[0]}
                                </p>
                              )}
                            </Grid>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>

                  <Grid item xs={12}>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Stack
                      direction='row'
                      flexWrap='wrap'
                      justifyContent='flex-end'
                      alignItems='center'
                      spacing={2}
                      sx={{ mt: "1rem", width: "100%" }}
                    >
                      <Button
                        size='large'
                        color='primary'
                        variant='contained'
                        className='fixed-width'
                        onClick={submitForm}
                      >
                        Save
                      </Button>
                      <Button
                        size='large'
                        color='primary'
                        variant='outlined'
                        onClick={() => navigate(`${ADS_LIST_ROUTE}/${adId}`)}
                        className='fixed-width'
                      >
                        Back
                      </Button>
                    </Stack>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default EditAd;
