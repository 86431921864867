import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
  page: number;
  limit: number;
  searchText: string | null;
  subscriptionStatus: string[];
  subscriptionPlan: string[];
  fromDate: string | null;
  toDate: string | null;
}

const initialState: InitialStateProps = {
  page: 1,
  limit: 10,
  searchText: null,
  subscriptionStatus: [],
  subscriptionPlan: [],
  fromDate: null,
  toDate: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,

  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },
    setSubscriptionStatus: (state, action: PayloadAction<string[] | null>) => {
      state.page = 1;
      if (action.payload?.length) {
        state.subscriptionStatus = action.payload;
      } else {
        state.subscriptionStatus = [];
      }
    },
    setSubscriptionPlan: (state, action: PayloadAction<string[] | null>) => {
      state.page = 1;
      if (action.payload?.length) {
        state.subscriptionPlan = action.payload;
      } else {
        state.subscriptionPlan = [];
      }
    },
    setFromDate: (state, action: PayloadAction<string | null>) => {
      state.page = 1;
      state.fromDate = action.payload;
    },
    setToDate: (state, action: PayloadAction<string | null>) => {
      state.page = 1;
      state.toDate = action.payload;
    },

    resetSubscriptionSlice: () => initialState,
  },
});

export const {
  setLimit,
  setPage,
  setSearchText,
  setSubscriptionStatus,
  setSubscriptionPlan,
  setFromDate,
  setToDate,
  resetSubscriptionSlice,
} = subscriptionSlice.actions;

export const selectsubscription = (state: any) => state.subscription;

export default subscriptionSlice.reducer;
