import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//ui
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "ui-component/cards/MainCard";

//project imports
import { DISABLE_APP_USER, ENABLE_APP_USER } from "schemas/mutation";
import { GET_REPORT_DETAILS } from "schemas/query";

//redux

import { REPORTS_ROUTE } from "constants/routes";
import { gridSpacing } from "store/constant";
import { useAppDispatch } from "store/hooks";
import { snackbarOpen } from "store/slice/snackbarSlice";
import DescriptionIcon from "ui-component/Icons/DescriptionIcon";
import ReportIcon from "ui-component/Icons/ReportIcon";
import { SmallTitleIcon } from "ui-component/Icons/SmallTitleIcon";
import Loader from "ui-component/Loader";
import useEditorPermission from "hooks/useEditorPermission";

interface ReportDetail {
  createdAt: string;
  firstName: string;
  lastName: string;
  reason: string;
  reporterLastName: string;
  reporterFirstName: string;
  userId: string;
  description: string;
  accountStatus: string;
}

const ReportDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [reportDetails, setReportDetails] = useState<ReportDetail>();

  const { loading: contentLoading, data, refetch } = useQuery(
    GET_REPORT_DETAILS,
    {
      variables: {
        body: {
          reportId: id,
        },
      },
      onError: (error) => {
        dispatch(
          snackbarOpen({
            open: true,
            severity: "error",
            message: error.message,
          })
        );
      },
    }
  );

  const isEditor = useEditorPermission();

  useEffect(() => {
    if (data) {
      setReportDetails(data.getReportUserDetails);
    }
  }, [data]);

  const handleBack = () => {
    navigate(`${REPORTS_ROUTE}`);
  };

  const [disableAppUser, { loading }] = useMutation(DISABLE_APP_USER, {
    onCompleted(data) {
      dispatch(
        snackbarOpen({
          open: true,
          message: "User disable sucessfully",
          severity: "success",
        })
      );
      refetch();
    },

    onError: (error) =>
      dispatch(
        snackbarOpen({
          open: true,
          message: error.message,
          severity: "error",
        })
      ),
  });

  const [enableAppUser] = useMutation(ENABLE_APP_USER, {
    onCompleted(data) {
      dispatch(
        snackbarOpen({
          open: true,
          message: "User enable sucessfully",
          severity: "success",
        })
      );
      refetch();
    },

    onError: (error) =>
      dispatch(
        snackbarOpen({
          open: true,
          message: error.message,
          severity: "error",
        })
      ),
  });

  const handleDisable = async (id: string | undefined) => {
    disableAppUser({
      variables: {
        body: {
          userId: reportDetails?.userId,
        },
      },
    });
  };

  const handleEnable = async (id: string | undefined) => {
    enableAppUser({
      variables: {
        body: {
          userId: reportDetails?.userId,
        },
      },
    });
  };

  if (contentLoading) {
    return <Loader />;
  }

  return (
    <MainCard>
      <Grid container spacing={gridSpacing} justifyContent='space-between'>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <Grid
              container
              alignItems='baseline'
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Stack direction='row' alignItems='center' spacing={2}>
                <Box sx={{ ml: "10px" }}>
                  <SmallTitleIcon />
                </Box>
                <Stack direction='row' spacing={2}>
                  <Typography variant='subtitle1'>Reported by:</Typography>
                  <Typography variant='body2'>
                    {reportDetails?.reporterFirstName}
                    {reportDetails?.reporterLastName}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <Grid
              container
              alignItems='baseline'
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Stack direction='row' alignItems='center' spacing={2}>
                <Box sx={{ ml: "10px" }}>
                  <SmallTitleIcon />
                </Box>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  useFlexGap
                  spacing={2}
                  sx={{
                    "@media (min-width: 600px)": { alignItems: "center" },
                  }}
                >
                  <Stack direction='row' spacing={2}>
                    <Typography variant='subtitle1'>
                      User being reported:
                    </Typography>
                    <Typography variant='body2'>
                      {reportDetails?.firstName} {reportDetails?.lastName}
                    </Typography>
                  </Stack>
                  {isEditor && (
                    <>
                      {reportDetails?.accountStatus === "active" ? (
                        <Button
                          variant='contained'
                          sx={{
                            cursor: "pointer",
                            paddingInline: "10px !important",
                          }}
                          // onClick={handleDisable}
                          onClick={() => handleDisable(id)}
                        >
                          Disable this user
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          sx={{
                            cursor: "pointer",
                            paddingInline: "10px !important",
                          }}
                          // onClick={handleDisable}
                          onClick={() => handleEnable(id)}
                        >
                          Enable this user
                        </Button>
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ my: "15px" }}>
            <Grid
              container
              alignItems='baseline'
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Stack direction='row' alignItems='center' spacing={2}>
                <Box sx={{ ml: "10px", mr: "10px" }}>
                  <ReportIcon />
                </Box>
                <Stack direction='row' spacing={2}>
                  <Typography variant='subtitle1'>Reason:</Typography>
                  <Typography variant='body2'>
                    {reportDetails?.reason}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <Grid
              container
              alignItems='baseline'
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Stack direction='row' spacing={2}>
                <Box sx={{ ml: "10px" }}>
                  <DescriptionIcon />
                </Box>
                <Stack direction='row' spacing={2}>
                  <Typography variant='subtitle1'>Description:</Typography>
                  <Typography variant='body2'>
                    {reportDetails?.description}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <Divider />
          </Grid>
          <Grid item>
            <Stack
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              spacing={2}
            >
              <Button
                size='large'
                color='primary'
                variant='outlined'
                className='fixed-width'
                onClick={handleBack}
              >
                Back
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ReportDetail;
