import React from "react";

function Icon({ width = "21px", height = "20px", fill = "#3d4853" }) {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5964_29520)">
        <path
          d="M10.325 5.62141C10.751 3.72515 13.249 3.72515 13.675 5.62141C13.7389 5.90628 13.8642 6.17083 14.0407 6.39352C14.2172 6.61622 14.4399 6.79076 14.6907 6.90295C14.9414 7.01514 15.2132 7.06181 15.4838 7.03915C15.7544 7.01649 16.0162 6.92515 16.248 6.77256C17.791 5.75748 19.558 7.66454 18.618 9.33186C18.4769 9.58202 18.3924 9.86461 18.3715 10.1566C18.3506 10.4487 18.3938 10.7419 18.4975 11.0126C18.6013 11.2832 18.7627 11.5236 18.9687 11.7141C19.1747 11.9047 19.4194 12.0401 19.683 12.1093C21.439 12.5693 21.439 15.2669 19.683 15.7269C19.4192 15.7959 19.1742 15.9312 18.968 16.1218C18.7618 16.3124 18.6001 16.5529 18.4963 16.8237C18.3924 17.0945 18.3491 17.3879 18.3701 17.6801C18.3911 17.9724 18.4757 18.2551 18.617 18.5054C19.557 20.1716 17.791 22.0798 16.247 21.0647C16.0153 20.9123 15.7537 20.8211 15.4832 20.7985C15.2128 20.7759 14.9412 20.8225 14.6906 20.9346C14.44 21.0467 14.2174 21.221 14.0409 21.4434C13.8645 21.6659 13.7391 21.9302 13.675 22.2148C13.249 24.111 10.751 24.111 10.325 22.2148C10.2611 21.9299 10.1358 21.6654 9.95929 21.4427C9.7828 21.22 9.56011 21.0454 9.30935 20.9332C9.05859 20.821 8.78683 20.7744 8.51621 20.797C8.24559 20.8197 7.98375 20.911 7.752 21.0636C6.209 22.0787 4.442 20.1716 5.382 18.5043C5.5231 18.2542 5.60755 17.9716 5.62848 17.6795C5.64942 17.3875 5.60624 17.0943 5.50247 16.8236C5.3987 16.553 5.23726 16.3126 5.03127 16.1221C4.82529 15.9315 4.58056 15.7961 4.317 15.7269C2.561 15.2669 2.561 12.5693 4.317 12.1093C4.5808 12.0403 4.82578 11.905 5.032 11.7144C5.23822 11.5238 5.39985 11.2833 5.50375 11.0125C5.60764 10.7417 5.65085 10.4483 5.62987 10.156C5.60889 9.8638 5.5243 9.58105 5.383 9.33078C4.443 7.66454 6.209 5.7564 7.753 6.77148C8.753 7.42804 10.049 6.84707 10.325 5.62141Z"
          stroke="#2196F3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 17.157C13.6569 17.157 15 15.7066 15 13.9174C15 12.1282 13.6569 10.6777 12 10.6777C10.3431 10.6777 9 12.1282 9 13.9174C9 15.7066 10.3431 17.157 12 17.157Z"
          stroke="#2196F3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5964_29520">
          <rect
            width="24"
            height="25.917"
            fill="white"
            transform="translate(0 0.958984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
