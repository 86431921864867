import {
  ADMIN_USERS_ROUTE,
  ADS_LIST_ROUTE,
  APP_USERS_ROUTE,
  DASHBOARD_ROUTE,
  EMAILS_ROUTE,
  GRACE_AND_EXPIRY_ROUTE,
  LOGGED_IN_ADMIN_PROFILE_ROUTE,
  MANAGE_CATEGORIES_ROUTE,
  PAGE_MANAGEMENT_ROUTE,
  REPORTS_ROUTE,
  SAVED_SEARCH_ROUTE,
  SUBSCRIPTION_ROUTE,
} from "constants/routes";
import { useLocation } from "react-router-dom";
import {
  REPORTS_ADS_ROUTE,
  SEARCH_AD_LIST,
  SIMILAR_SAVED_SEARCH_ROUTE,
} from "./../constants/routes";

const useBreadCrumbTitles = () => {
  const { pathname: currentRoute } = useLocation();

  let titleList: any = [];

  // dashboard
  if (currentRoute === DASHBOARD_ROUTE) titleList = ["Dashboard", ""];

  // app users
  if (currentRoute === APP_USERS_ROUTE) titleList = ["App Users", ""];
  if (currentRoute.includes(`${APP_USERS_ROUTE}/`))
    titleList = ["App users", "User details", ""];

  // admin users
  if (currentRoute === ADMIN_USERS_ROUTE) titleList = ["Admin Users", ""];
  if (currentRoute.includes(`${ADMIN_USERS_ROUTE}/`))
    titleList = ["Admin users", "User details"];

  // page management
  if (currentRoute === PAGE_MANAGEMENT_ROUTE) titleList = ["Manage pages", ""];
  if (currentRoute.includes(`${PAGE_MANAGEMENT_ROUTE}/`))
    titleList = ["Manage pages", "Edit page"];

  //email
  if (currentRoute === EMAILS_ROUTE) titleList = ["Emails", ""];
  if (currentRoute.includes(`${EMAILS_ROUTE}/`))
    titleList = ["Emails", "Edit email"];

  // reports
  if (currentRoute === REPORTS_ROUTE) titleList = ["Reports", ""];
  if (currentRoute.includes(`${REPORTS_ROUTE}/`))
    titleList = ["Reports", "Reports Details"];

  // grace and expiry
  if (currentRoute === GRACE_AND_EXPIRY_ROUTE)
    titleList = ["Grace and Expiry", ""];
  //manage categories
  if (currentRoute === MANAGE_CATEGORIES_ROUTE)
    titleList = ["Manage Categories", ""];

  //saved search
  if (currentRoute === SAVED_SEARCH_ROUTE)
    if (currentRoute.includes(`${SAVED_SEARCH_ROUTE}/`))
      titleList = ["Saved Searches", ""];

  if (currentRoute === SUBSCRIPTION_ROUTE) titleList = ["Subscription", ""];
  if (currentRoute.includes(`${SUBSCRIPTION_ROUTE}/`))
    titleList = ["Subscription", "Subscription details"];
  // ad
  if (currentRoute === ADS_LIST_ROUTE) titleList = ["Ads Listed", ""];

  // ad details
  if (currentRoute.includes(`${ADS_LIST_ROUTE}/`))
    titleList = ["Ads Listed", "Ad Details"];

  // reports
  if (currentRoute === REPORTS_ADS_ROUTE) titleList = ["Report Ads", ""];
  if (currentRoute.includes(`${REPORTS_ADS_ROUTE}/`))
    titleList = ["Report Ads", "Report Ads Details"];

  // reports
  if (currentRoute === SAVED_SEARCH_ROUTE) titleList = ["Saved Search", ""];
  if (currentRoute.includes(`${SAVED_SEARCH_ROUTE}/`))
    titleList = ["Saved Search", "Saved Search Details"];

  if (currentRoute === SIMILAR_SAVED_SEARCH_ROUTE)
    titleList = ["Saved Search", ""];

  if (currentRoute.includes(`${SEARCH_AD_LIST}/`))
    titleList = ["Ads listed", ""];

  if (currentRoute === LOGGED_IN_ADMIN_PROFILE_ROUTE)
    titleList = ["User Profile", "Account settings"];

  return titleList;
};

export default useBreadCrumbTitles;
