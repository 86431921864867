export function setCookie(name: string, val: string, days: number = 0) {
  const date = new Date();
  const value = val;

  // Set it expire in x days
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  // Set it
  const cookieString =
    days === 0
      ? `${name}=${value}; path=/ ; SameSite=Strict`
      : `${name}=${value}; expires=${date.toUTCString()}; path=/; SameSite=Strict`;

  document.cookie = cookieString;
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts?.pop()?.split(";").shift();
  }

  return false;
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -2 minutes
  date.setTime(date.getTime());

  // Set it
  document.cookie = `${name}=; expires=${date.toUTCString()}; path=/; SameSite=Strict`;
}
