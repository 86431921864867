export type Admin = {
  _id: string;
  name: string;
  email: string;
  phone: string;
  role: string;
  status: string;
};

export type HeadCell = {
  id: string;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
};

export enum role {
  SUPER_ADMIN = "superAdmin",
}

export type RegisterType = {
  name: string;
  email: string;
  phone: string;
  role: string;
};

export const userRole = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "editor",
    label: "Editor",
  },
];

export const title = [
  {
    value: "mr",
    label: "Mr.",
  },
  {
    value: "ms",
    label: "Ms.",
  },
  {
    value: "miss",
    label: "Miss.",
  },
  {
    value: "mrs",
    label: "Mrs.",
  },
];

// review state options
export const status = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "pending",
    label: "Pending",
  },
];

// account status types
export const accountStatus = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "disabled",
    label: "Disabled",
  },
];
// page status types
export const pageStatus = [
  {
    value: "live",
    label: "Live",
  },
  {
    value: "disabled",
    label: "Disabled",
  },
];
