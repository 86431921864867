//ui
import MainCard from "ui-component/cards/MainCard";

// project imports
import PageList from "./PageList";

const PageManagement = () => {
  return (
    <MainCard sx={{ pt: 1.5 }} content={false}>
      <PageList />
    </MainCard>
  );
};

export default PageManagement;
