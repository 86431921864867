import { useEffect, useRef } from "react";

// material-ui
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import { useQuery } from "@apollo/client";
import { GET_NOTIFICATION_COUNT } from "schemas/query";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectNotification,
  setUnreadNotificationCount,
  toggleNotificationWindow,
} from "store/slice/notificationSlice";
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import NotificationList from "./NotificationList";

// assets
//import { IconBell } from "@tabler/icons";

// notification status options
const status = [
  {
    value: "all",
    label: "All Notification",
  },
  {
    value: "new",
    label: "New",
  },
  {
    value: "unread",
    label: "Unread",
  },
  {
    value: "other",
    label: "Other",
  },
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const { open, totalUnreadNotifications } = useAppSelector(selectNotification);
  const dispatch = useAppDispatch();

  const { data, refetch } = useQuery(GET_NOTIFICATION_COUNT);

  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    dispatch(toggleNotificationWindow(!open));
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    dispatch(toggleNotificationWindow(false));
    refetch();
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (data) {
      dispatch(
        setUnreadNotificationCount(
          data.getUnreadNotificationCount?.totalUnreadNotifications
        )
      );
    }
  }, [data, dispatch]);

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 4,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: "all .2s ease-in-out",
            background:
              theme.palette.mode === "dark"
                ? theme.palette.secondary[100]
                : theme.palette.secondary[100],
            color:
              theme.palette.mode === "dark"
                ? theme.palette.secondary[100]
                : theme.palette.primary.main,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary[100]
                  : theme.palette.primary.dark,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.secondary[100],
            },
          }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          color='inherit'
        >
          <NotificationsIcon fontSize='small' />
        </Avatar>
      </Box>

      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions
              position={matchesXs ? "top" : "top-right"}
              in={open}
              {...TransitionProps}
            >
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                    sx={{ width: "300px" }}
                  >
                    <Grid container direction='column' spacing={2}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ pt: 2, px: 2 }}
                        >
                          <Grid item>
                            <Stack
                              direction='row'
                              justifyContent='space-between'
                              spacing={2}
                            >
                              <Typography variant='subtitle1'>
                                All Notification
                              </Typography>
                              <Chip
                                size='small'
                                label={totalUnreadNotifications}
                                sx={{
                                  color: theme.palette.background.default,
                                  bgcolor: theme.palette.warning.dark,
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction='column' spacing={2}>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <NotificationList />
                      </Grid>
                    </Grid>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
