import * as Yup from "yup";
import { Editor } from "@tinymce/tinymce-react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useRef, useState } from "react";

//ui
import {
  Button,
  CardActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  FilledInput,
  Stack,
  Typography,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";
import Loader from "ui-component/Loader";
import MainCard from "ui-component/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

//project imports
import MSG from "constants/validation.message";
import { EDIT_PAGE } from "schemas/mutation";
import { GET_PAGE } from "schemas/query";
import { ValidationErrorMessage } from "./EditPageWrapper";

//redux

import { snackbarOpen } from "store/slice/snackbarSlice";
import { useAppDispatch } from "store/hooks";
import { PAGE_MANAGEMENT_ROUTE } from "constants/routes";
import { pageStatus } from "views/user-management/admin-user/constants/types";
import useEditorPermission from "hooks/useEditorPermission";

const validationSchema = Yup.object({
  title: Yup.string().required(MSG.TITLE_REQUIRED),
  content: Yup.string().required(MSG.CONTENT_REQUIRED),
  pageStatus: Yup.string(),
});

interface EditPageProps {
  title: string;
  content: string;
  pageStatus: string;
}

const EditPage = () => {
  const theme = useTheme();
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const editorRef = useRef<any>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { loading: contentLoading, data: getPages, refetch } = useQuery(
    GET_PAGE,
    {
      variables: {
        body: {
          slug,
        },
      },
      onError: (error) => {
        dispatch(
          snackbarOpen({
            open: true,
            severity: "error",
            message: error.message,
          })
        );
      },
    }
  );

  const isEditor = useEditorPermission();

  const [editPage, { loading: editLoading }] = useMutation(EDIT_PAGE);

  const handleEditPage = async (values: EditPageProps) => {
    try {
      let content = editorRef?.current?.getContent();
      const { data } = await editPage({
        variables: {
          body: {
            content: content,
            slug,
            title: values.title,
            pageStatus: values.pageStatus,
          },
        },
      });

      dispatch(
        snackbarOpen({
          open: true,
          severity: "success",
          message: data?.updatePage?.status,
        })
      );

      refetch();
      navigate(PAGE_MANAGEMENT_ROUTE);
    } catch (error: any) {
      dispatch(
        snackbarOpen({ open: true, severity: "error", message: error.message })
      );
    }
  };

  if (contentLoading || editLoading) {
    return <Loader />;
  }

  return (
    <>
      {loading && <Loader />}
      <MainCard content={false}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: getPages?.getPage?.title || "",
            content: getPages?.getPage?.content,
            pageStatus: getPages?.getPage?.pageStatus || "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors }) => {
            let content = editorRef?.current?.getContent();
            if (!content) {
              setErrors({ content: "Cannot be empty" });
            } else {
              await handleEditPage({ ...values });
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                spacing={2}
                rowSpacing={1}
                sx={{ padding: "30px 20px 11px" }}
              >
                <Grid
                  container
                  sx={{
                    border: "1px solid #DBDCDC",
                    borderRadius: "12px",
                    padding: "20px 16px",
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <FormControl
                          fullWidth
                          sx={{ ...theme.typography.customInput }}
                          color='primary'
                        >
                          <InputLabel htmlFor='outlined-adornment-page-title'>
                            Page Title
                          </InputLabel>
                          <FilledInput
                            fullWidth
                            id='title'
                            {...formik.getFieldProps("title")}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <ValidationErrorMessage
                              color={theme.palette.error.dark}
                            >
                              <InfoTwoToneIcon
                                sx={{ fontSize: "12px", mr: ".25rem" }}
                              />
                              {formik.errors.title}
                            </ValidationErrorMessage>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            sx={{ ...theme.typography.customInput }}
                          >
                            <Editor
                              tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                              onInit={(evt, editor) => {
                                setLoading(false);
                                editorRef.current = editor;
                              }}
                              id='content'
                              initialValue={getPages?.getPage?.content}
                              init={{
                                entity_encoding: "raw",
                                verify_html: false,
                                height: 488,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "charmap",
                                  "preview",
                                  "anchor",
                                  "searchreplace",
                                  "visualblocks",
                                  "code",
                                  "fullscreen",
                                  "insertdatetime",
                                  "media",
                                  "table",
                                  "help",
                                  "wordcount",
                                ],
                                toolbar:
                                  " blocks | bold italic strikethrough forecolor backcolor blockquote   | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | code | preview",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:16px; }",
                              }}
                            />
                            {formik.touched.content && formik.errors.content ? (
                              <ValidationErrorMessage
                                color={theme.palette.error.dark}
                              >
                                <InfoTwoToneIcon
                                  sx={{ fontSize: "12px", mr: ".25rem" }}
                                />
                                {formik.errors.content}
                              </ValidationErrorMessage>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{ borderColor: `${theme.palette.warning.light}` }}
                    />

                    <Grid item xs={4} sx={{ mt: 2 }}>
                      <InputLabel>Status</InputLabel>
                      <TextField
                        id='pageStatus'
                        name='pageStatus'
                        select
                        value={formik.values.pageStatus}
                        fullWidth
                        onChange={formik.handleChange}
                        sx={{
                          "@media (max-width: 500px)": {
                            width: "200px",
                          },
                        }}
                      >
                        {pageStatus.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {formik.errors.pageStatus && (
                        <FormHelperText error id='pageStatus'>
                          {formik.errors.pageStatus}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  {isEditor && (
                    <Grid container justifyContent='flex-end'>
                      <CardActions>
                        {
                          <Grid container>
                            <Grid item>
                              <Stack
                                spacing={2}
                                direction='row'
                                sx={{
                                  "@media (max-width: 380px)": {
                                    position: "relative",
                                    left: "35px",
                                  },
                                }}
                              >
                                <AnimateButton>
                                  <Button
                                    variant='contained'
                                    sx={{ borderRadius: "4px" }}
                                    type='submit'
                                    size='medium'
                                    color='primary'
                                    className='fixed-width'
                                  >
                                    <Typography variant='caption' color='white'>
                                      Save Changes
                                    </Typography>
                                  </Button>
                                </AnimateButton>
                                <AnimateButton>
                                  <Button
                                    variant='outlined'
                                    sx={{ borderRadius: "4px" }}
                                    type='button'
                                    size='medium'
                                    color='primary'
                                    className='fixed-width'
                                    onClick={() => navigate(-1)}
                                  >
                                    <Typography variant='caption'>
                                      Cancel
                                    </Typography>
                                  </Button>
                                </AnimateButton>
                              </Stack>
                            </Grid>
                          </Grid>
                        }
                      </CardActions>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default EditPage;
