import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
  page: number;
  limit: number;
  searchText: string | null;
  filter: string | null;
}

const initialState: InitialStateProps = {
  page: 1,
  limit: 10,
  searchText: null,
  filter: null,
};

export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,

  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.searchText = action.payload;
    },
    setFilter: (state, action: PayloadAction<string | null>) => {
      state.page = 1;
      state.filter = action.payload;
    },
    resetAdminUser: () => initialState,
  },
});

export const {
  setLimit,
  setPage,
  setSearchText,
  setFilter,
  resetAdminUser,
} = adminUserSlice.actions;

export const selectAdminUser = (state: any) => state.adminUser;

export default adminUserSlice.reducer;
