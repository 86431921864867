/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { CustomTypography } from "themes/types";

export default function themeTypography(
  theme: CustomTypography
): TypographyOptions {
  return {
    fontFamily: theme?.customization?.fontFamily!,
    h6: {
      fontWeight: 500,
      color: theme.colors?.greyA200!,
      fontSize: "0.875rem",
    },
    h5: {
      fontSize: "1rem",
      color: theme.colors?.greyA200!,
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.125rem",
      color: theme.heading!,
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      color: theme.heading!,
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5rem",
      color: theme.primaryDark!,
      fontWeight: 700,
    },
    h1: {
      fontSize: "2.125rem",
      color: theme.heading!,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "0.875rem",
      lineHeight: "24px",
      color: theme.grey700!,
      fontWeight: 400,
      //fontFamily: "'Outfit', sans-serif",
    },
    subtitle2: {
      fontSize: "1rem",
      lineHeight: "24px",
      color: theme.grey500,
      fontWeight: 400,
      //fontFamily: "'Outfit', sans-serif",
    },
    caption: {
      fontSize: "0.875rem",
      color: theme.darkTextSecondary!,
      fontWeight: 400,
      //fontFamily: "'Outfit', sans-serif",
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "1.334em",
      color: theme.grey900!,
    },
    body2: {
      letterSpacing: "0em",
      fontWeight: 400,
      lineHeight: "1.5em",
      color: theme.colors?.greyA200!,
      fontSize: "0.875rem",
    },
    error: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.03em",
      color: "#D84315",
    },
    button: {
      textTransform: "none",
    },
    customDatePicker: {
      marginTop: 1,
      marginBottom: 1,
      "& label": {
        fontSize: "15px",
        fontFamily: "'Outfit', sans-serif",
        fontWeight: "700 !important",
        color: theme.grey500,
      },
      "& .MuiOutlinedInput-root": {
        height: "67px",
        "& .MuiInputAdornment-root, & .MuiOutlinedInput-input": {
          zIndex: 1,
          marginTop: "20px",
          fontSize: "14px",
          lineHeight: 1.5,
          fontWeight: 700,
          fontFamily: "'Outfit', sans-serif",
        },
        "& .MuiInputAdornment-root,": {
          marginTop: "0px",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EDE7F6 !important",
        backgroundColor: "#f5f5f5",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    customDatePicker2: {
      marginTop: 1,
      marginBottom: 1,
      "& label": {
        fontSize: "15px",
        fontFamily: "'Outfit', sans-serif",
        fontWeight: "700 !important",
        color: theme.grey500,
      },
      "& .MuiOutlinedInput-root": {
        height: "52.133px",
        "& .MuiInputAdornment-root, & .MuiOutlinedInput-input": {
          zIndex: 1,
          marginTop: "0px",
          fontSize: "14px",
          lineHeight: 1.5,
          fontWeight: 700,
          fontFamily: "'Outfit', sans-serif",
        },
        "& .MuiInputAdornment-root,": {
          marginTop: "0px",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EDE7F6 !important",
        backgroundColor: "#f5f5f5",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    customSelect: {
      marginTop: 1,
      marginBottom: 1,
      // width: '100%',
      // padding: '1rem',
      // height: '63px',
      "& label": {
        top: "-3px !important",
        fontSize: "12px",
        fontFamily: "'Outfit', sans-serif",
        fontWeight: "700 !important",
        color: theme.grey500,
      },
      "& select": {
        height: "67px",
        width: "100%",
        borderRadius: "10px",
        border: "1px solid #EDE7F6 !important",
        outline: "none",
        backgroundColor: "#f5f5f5",
        padding: "0.9rem",

        paddingTop: "29px",
        fontSize: "14px",
        lineHeight: 1.5,
        fontWeight: 700,
        fontFamily: "'Outfit', sans-serif",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EDE7F6 !important",
        backgroundColor: "#f5f5f5",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        fontSize: "15px",
        fontFamily: "'Outfit', sans-serif",
        fontWeight: "700 !important",
        color: theme.grey500,
        // top: 24,
        // left: 2,
        // color: theme.grey600!,
        // //color: 'green',
        // '&[data-shrink="false"]': {
        //   top: 5,
        // },
      },
      "& > div > input,& > div > textarea": {
        fontSize: "14px",
        lineHeight: 1.5,
        fontWeight: 700,
        fontFamily: "'Outfit', sans-serif",
        padding: "33px 16px 12px !important",
        boxShadow: `0 0 0 40px ${theme.colors?.paper} inset !important`,
        //boxShadow: none;
        "&.MuiInputBase-inputAdornedStart": {
          padding: "31px 16px 12px 9px !important",
        },
      },
      "& > div > textarea": {
        padding: "12px 16px 12px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.colors?.primaryDark}!important`,
      },
      // "& > div.input-filled > input": {
      //   boxShadow: `0 0 0 40px ${theme.colors?.grey50} inset !important`,
      // },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    mainContent: {
      backgroundColor: theme.background!,
      width: "100%",
      minHeight: "100vh",
      //minHeight: "calc(100vh - 87px)",
      flexGrow: 1,
      padding: "0 20px 20px 20px",
      display: "flex",
      //marginTop: "87px",
      marginRight: "20px",
      //borderRadius: `${theme?.customization?.borderRadius}px`,
      "@media (max-width: 900px)": {
        padding: "0 16px 16px!important",
      },
      "@media (max-width: 600px)": {
        marginLeft: "0!important",
        marginRight: "0!important",
        ">.MuiGrid-container": {
          width: "100%!important",
          marginLeft: "0!important",
          ">.MuiGrid-item": {
            padding: "16px!important",
          },
        },
      },
    },
    caption2: {
      fontFamily: "Outfit",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "28px",
      color: "#212121",
    },
    menuCaption: {
      fontFamily: "Outfit",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#5C5959",
    },
    subMenuCaption: {
      fontFamily: "Outfit",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#212121",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem",
    },
  };
}
