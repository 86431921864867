import React from "react";

function ReportsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="21"
      viewBox="0 0 8 21"
      fill="none"
    >
      <path
        d="M4.00037 0.00195312C1.86006 0.00195312 0.125 1.73701 0.125 3.87732C0.125 6.79606 1.33243 10.4289 1.93776 12.0759C2.26055 12.9542 3.09696 13.4995 4.00253 13.4995C4.90567 13.4995 5.74086 12.957 6.06462 12.0809C6.67054 10.4412 7.87574 6.827 7.87574 3.87732C7.87574 1.73701 6.14067 0.00195312 4.00037 0.00195312ZM1.625 3.87732C1.625 2.56544 2.68849 1.50195 4.00037 1.50195C5.31225 1.50195 6.37574 2.56544 6.37574 3.87732C6.37574 6.53153 5.26395 9.92019 4.65762 11.561C4.56212 11.8194 4.30895 11.9995 4.00253 11.9995C3.69541 11.9995 3.44122 11.8184 3.34568 11.5585C2.73867 9.90688 1.625 6.50056 1.625 3.87732ZM4.00112 15.001C2.61979 15.001 1.5 16.1208 1.5 17.5021C1.5 18.8834 2.61979 20.0032 4.00112 20.0032C5.38245 20.0032 6.50224 18.8834 6.50224 17.5021C6.50224 16.1208 5.38245 15.001 4.00112 15.001ZM3 17.5021C3 16.9492 3.44822 16.501 4.00112 16.501C4.55402 16.501 5.00224 16.9492 5.00224 17.5021C5.00224 18.055 4.55402 18.5032 4.00112 18.5032C3.44822 18.5032 3 18.055 3 17.5021Z"
        fill="#5C5959"
      />
    </svg>
  );
}

export default ReportsIcon;
