// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import { drawerWidth } from "store/constant";

// ==============================|| SIDEBAR DRAWER ||============================== //

export interface SidebarProps {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
  window?: Window;
}

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarProps) => {
  const theme = useTheme();
  const matchUpLg = useMediaQuery(theme.breakpoints.up("lg"));

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        <Box sx={{ display: "flex", py: 1.75, px: 3, mx: "auto" }}>
          <LogoSection />
        </Box>
      </Box>
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpLg ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "10px",
        }}
      >
        <BrowserView>
          <MenuList />
        </BrowserView>
        <MobileView>
          <Box sx={{ px: 2 }}>
            <MenuList />
          </Box>
        </MobileView>
      </PerfectScrollbar>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpLg ? drawerWidth : "auto", }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpLg ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,

            background: "#fff",

            color: theme.palette.text.primary,
            borderRight: "none",
            //paddingTop: "40px",
            [theme.breakpoints.up("lg")]: {
              top: "93px",
              //paddingTop: "40px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
