import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  menus: string[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  label: string;
  fieldName: string;
  checkedValues: string[];
}

export default function CustomMultipleSelectCheckmarks({
  menus = [],
  setFieldValue,
  label,
  fieldName,
  checkedValues = [],
}: Props) {
  const [itemName, setItemName] = React.useState<string[]>(checkedValues);

  const handleChange = (event: SelectChangeEvent<typeof itemName>) => {
    const {
      target: { value },
    } = event;
    setItemName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  React.useEffect(() => {
    setFieldValue(fieldName, itemName);
  }, [itemName, fieldName, setFieldValue]);

  return (
    <div>
      <FormControl sx={{ width: "100%" }} id='ad-details-form'>
        <InputLabel
          id='demo-multiple-checkbox-label'
          sx={{ top: "16px !important" }}
        >
          {label}
        </InputLabel>
        <Select
          inputProps={{
            sx: {
              color: "#2a2a2a",
            },
          }}
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          value={itemName}
          onChange={handleChange}
          input={<OutlinedInput label='Tag' />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {menus.map((name: string) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={itemName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
